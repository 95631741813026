import React, { useState, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Rating } from "react-simple-star-rating";

import { DEFAULT_ACTIONS, ReviewAction } from '../../redux/actions';
import { RatingSchema } from '../../schemas';

const EditReview = (props) => {
    const dispatch = useDispatch();
    const { editReview, reviewDetails } = useSelector((state) => state.booking);
    const [initialValues, setInitialValues] = useState({ ratings: 0, experience: '' });

    const closeModal = () => dispatch({ type: DEFAULT_ACTIONS.SET_UPDATE_REVIEW_MODAL, status: false });

    useEffect(() => {
        if(reviewDetails.ratings && reviewDetails.review) {
            setInitialValues({ ratings: reviewDetails.ratings, experience: reviewDetails.review });
        }
    }, [reviewDetails])

    return (
        <div className={`modal-new ${!editReview ? 'd-none' : ''}`}>
            <div className="modal-dialog review-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Edit Reviews
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={closeModal}
                        />
                    </div>
                    <div className="modal-body">
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={RatingSchema}
                            onSubmit={async (values) => {
                                const payload = {
                                    reviewId: reviewDetails._id,
                                    ratings: values.ratings,
                                    review: values.experience,
                                };

                                props.updateReview(payload);
                            }}
                        >
                            {({ errors, touched, handleSubmit, values, handleChange }) => (
                                <Form onSubmit={handleSubmit}>
                                    <div className="from-group">

                                        <Rating
                                            onClick={(rate) => handleChange('ratings', rate)}
                                            initialValue={values.ratings}
                                            fillColorArray={["#0095DB"]}
                                        />

                                        {errors.ratings && touched.ratings ? (
                                            <div className="error">{errors.ratings}</div>
                                        ) : null}
                                    </div>
                                    <div className="from-group textraea-field">
                                        <label className="mt-4">
                                            Tell Us About Your Experience
                                        </label>
                                        <Field name="experience" type="textarea" />
                                    </div>
                                    <button type="submit">Update</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    updateReview: (payload) => dispatch(ReviewAction.update(payload)),
});

export default connect(null, mapDispatchToProps)(EditReview);