import React from 'react'
import tourone from "../assetss/images/tourone.png"
import tourtwo from "../assetss/images/tourtwo.png"
import tourthree from "../assetss/images/tourthree.png"

function Tours({setViewPopup}) {
  return (
    <div className='tours-main'>
        <div className='container'>
            <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Luxury Tours</h2>
            <div className='row'>
                <div className='col-md-4'>
                    <div className='tour-iner'>
                        <img src={tourone} alt="" />
                        <div className='tour-overlay'>
                        <h4>Helicoptor Tours</h4>
                        <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='tour-iner'>
                        <img src={tourtwo} alt="" />
                        <div className='tour-overlay'>
                        <h4>Yacht Tours</h4>
                        <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                        </div>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='tour-iner'>
                        <img src={tourthree} alt="" />
                        <div className='tour-overlay'>
                        <h4>Safari Tours</h4>
                        <p>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p>
                        </div>
                    </div>
                </div>
            </div>
            <button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>

        </div>
    </div>
    )
}

export default Tours