export const ApiUrl = {
  BASE_URL: "https://api-cbrent.cb-premiumapps.com/api/v1/",
  LOGIN: "user/login",
  VERIFY_OTP: "user/verify/otp",
  REGISTER: "user/register",
  PROPERTY_DETAIL: "property/details",
  PROPERTY_BOOKING: "booking/property/booking",
  CAR_BOOKING: "booking/car/booking",
  TOUR_BOOKING: "tour/booking",
  MY_BOOKINGS: "booking",
  MY_PROFILE: "user",
  EDIT_PROFILE: "user",
  CHECK_PROPERTY_AVAILABILITY: "property/check/availiablity",
  CHECK_CAR_AVAILABILITY: "car/check/availiablity",
  VENDOR_LIST: "booking/vendorsList",
  CREATE_ROOM: "socket/create/room",
  GET_MESSAGES: "socket/get/messages",
  UPLOAD: "files/upload",
  ADD_RATING: "review",
  USER_RATING: "review/list",
  SOCKET_ROOM_BOOKING: "socket/room/booking",
  MY_REVIEW:"review/user",
  NOTIFICATION:"notification/listing",
  ADD_ID:"notification",
  MARKETING_FORM:"user/generateMarketLead"
};
// export const ApiUrl = {
//   BASE_URL: "https://socket.rentvip.com/api/v1/",
//   LOGIN: "user/login",
//   VERIFY_OTP: "user/verify/otp",
//   REGISTER: "user/register",
//   PROPERTY_DETAIL: "property/details",
//   PROPERTY_BOOKING: "booking/property/booking",
//   CAR_BOOKING: "booking/car/booking",
//   TOUR_BOOKING: "tour/booking",
//   MY_BOOKINGS: "booking",
//   MY_PROFILE: "user",
//   EDIT_PROFILE: "user",
//   CHECK_PROPERTY_AVAILABILITY: "property/check/availiablity",
//   CHECK_CAR_AVAILABILITY: "car/check/availiablity",
//   VENDOR_LIST: "booking/vendorsList",
//   CREATE_ROOM: "socket/create/room",
//   GET_MESSAGES: "socket/get/messages",
//   UPLOAD: "files/upload",
//   ADD_RATING: "review",
//   USER_RATING: "review/list",
//   SOCKET_ROOM_BOOKING: "socket/room/booking",
//   MY_REVIEW:"review/user",
//   NOTIFICATION:"notification/listing",
//   ADD_ID:"notification",
//   MARKETING_FORM:"user/generateMarketLead"
// };
