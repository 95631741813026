import React from "react";
import styles from './Long.module.css';

export const Long = ({
                       vertical = false,
                       size = 0.5,
                       onSize = '100%',
                       color = 'hsla(0, 0%, 47%, 0.55)',
                       radius = true,
                       margin = 0,
                       Component = null,
                     }) => {
  return (
    <div
      className={styles.container}
      style={{
        height: !vertical ? size : onSize,
        minWidth: !vertical ? onSize : size,
        width: !vertical ? onSize : size,
        background: color,
        borderRadius: radius ? '50%' : 0,
        margin: vertical ? `0 ${margin}px` : `${margin}px 0`,
      }}
    >
      {Component &&
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          transform: 'scale(0.85)',
          transformOrigin: 'center bottom'
        }}>{Component}</div>
      }
    </div>
  );
};