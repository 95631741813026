import React, {useEffect, useState} from "react";
import styles from './List.module.css';
import {Breadcrumbs} from "../../components/Breadcrumbs/Breadcrumbs";
import {useQuery} from "@tanstack/react-query";
import {apiAxiosInstance} from "../../config";
import {CONSTANTS} from "../../utils";
import {useLocation} from "react-router-dom";
import {SwiperList} from "./SwiperList/SwiperList";
import {InfoList} from "./InfoList/InfoList";
import {LoaderRelative} from "../../components/CardsRenderBlock/CardsRenderBlock";
import ProductPrice from "../../components/ProductPrice";
import {useDispatch, useSelector} from "react-redux";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import {setData, setIsModalShow} from "../../redux/reducers/footerBarListData";
import {Footer} from "../../components/Footer/Footer";
import {FooterBarList} from "../../components/FooterBarList/FooterBarList";
import Header from "../../components/Header";
import {modifyUrlParameters} from "../../components/CardsRenderBlock/CardHome/CarSpecificationsBlock/resizeImages";
import {YouLikeContainer} from "./YouLikeContainer/YouLikeContainer";
import {InfoListTour} from "./InfoListTour/InfoListTour";

export const List = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const slug = searchParams.get('slug') || '';
  const type = searchParams.get('type') || 'CAR';
  const windowWidth = useWindowWidth();

  const [activeSubCategories, setActiveSubCategories] = useState(0);

  const breadcrumbsData = [
    { label: 'Home', url: '/' },
    { label: `${type}s listing`, url: `/listings?type=${type}` },
    { label: `${type} Details` },
  ];

  const {currency} = useSelector(state => state.auth);
  const { pathname } = useLocation();

  const fetchUserListings = (type, slug) => {
    if(type.toUpperCase() === CONSTANTS.RENTAL_TYPE.TOUR) {
      return apiAxiosInstance.get(
        `${CONSTANTS.API_VERSION.V1}/tour/${slug}/USD`
      );
    } else {
      return apiAxiosInstance.get(
        `${CONSTANTS.API_VERSION.V1}/${type.toLowerCase()}/details?slug=${slug}&currency=USD`
      );
    }
  }

  const { data, isLoading, error } = useQuery(
    ['list', currency, slug, type],
    () => fetchUserListings(type, slug),
    {
      cacheTime: 0, // caching time in milliseconds (in this case, 60 seconds)
    }
  );

  useEffect(() => {
    dispatch(setIsModalShow({isModalShow: isLoading}));
  }, [isLoading]);

  useEffect(() => {
    if(data && data.data.data.subCategories && data.data.data.subCategories.length !== 0 && type.toUpperCase() === CONSTANTS.RENTAL_TYPE.TOUR) {
      dispatch(setData({
        link: 'undefined',
        price: data.data.data.subCategories[activeSubCategories].price,
        currency: data.data.data.currencySymbol
      }))
    } else
    if (data && type.toUpperCase() !== CONSTANTS.RENTAL_TYPE.TOUR) {
      dispatch(setData({
        link: data.data.data.name,
        price: data.data.data.price,
        currency: data.data.data.currencySymbol
      }))
    }
  }, [data, activeSubCategories]);

  const [isShowMobilBooking, setIsShowMobilBooking] = useState(false);

  useEffect(() => {
    windowWidth > 767 && setIsShowMobilBooking(false)
  }, [windowWidth]);

  return  (
    <div
      style={{left: isShowMobilBooking ? `-30%` : `0px`}}
      className={styles.container}
    >
      <Header pathname={pathname} />
      {isLoading ? <LoaderRelative /> :
        <div className={styles.wrapper}>
          <div className={styles.breadcrumbs}>
            <Breadcrumbs breadcrumbsData={breadcrumbsData}/>
          </div>
          <div className={styles.swiper}>
            <SwiperList data={data && data.data.data.images}/>
          </div>
          <div className={styles.main}>
            {type.toUpperCase() !== CONSTANTS.RENTAL_TYPE.TOUR &&
              <InfoList
                state={data && data.data.data}
                type={type.toUpperCase()}
              />
            }
            {type.toUpperCase() === CONSTANTS.RENTAL_TYPE.TOUR &&
              <InfoListTour
                activeSubCategories={activeSubCategories}
                setActiveSubCategories={setActiveSubCategories}
                state={data && data.data.data}
                type={type.toUpperCase()}
              />
            }
            {data && type.toUpperCase() !== CONSTANTS.RENTAL_TYPE.TOUR && windowWidth > 767 &&
              <ProductPrice
                currency={currency}
                currencySymbol={data && data.data.data.currencySymbol}
                state={data.data.data}
                data={type}
                _id={data.data.data._id}
              />
            }
            {data && data.data.data.subCategories &&
              data.data.data.subCategories.length !== 0 &&
              type.toUpperCase() === CONSTANTS.RENTAL_TYPE.TOUR
              && windowWidth > 767 &&
              <ProductPrice
                state={{...data.data.data, ...data.data.data.subCategories[activeSubCategories]}}
                data={type}
                subCategoryId={data && data.data.data.subCategories[activeSubCategories]._id}
                disabledDates={data && data.data.data.subCategories[activeSubCategories].bookingClosedDates}
                currencySymbol={data && data.data.data.currencySymbol}
              />
            }
          </div>
          <div className={styles.similar}>
            <h6>You may also like</h6>
            <YouLikeContainer
              type={type}
            />
          </div>
          {!isLoading && <FooterBarList callback={() => setIsShowMobilBooking(true)}/>}
        </div>
      }
      <Footer homePart={false}/>
      {!isLoading && windowWidth < 767 &&
        <div
          style={{transform: isShowMobilBooking ? 'translateX(0%)' : 'translateX(100%)', left: 0}}
          className={styles['ProductPrice-mobil-container']}
        >
          <div style={{display: "flex", alignItems: 'center'}}>
            <button
              style={{fontSize: '200%'}}
              onClick={() => setIsShowMobilBooking(false)}
            >‹</button>
            <span> back</span>
            <span style={{ fontSize: '110%' ,position: 'absolute', left: '50%', transform: 'translateX(-50%)', opacity: 0.5}}>Review Booking</span>
          </div>
          <div className={styles.bookingBlock}>
            <div className={styles.bookingText}>
              <h2>{data.data.data.name}</h2>
              <span>{data.data.data.location}</span>
            </div>
            <div className={styles.bookingImage}>
              <img src={modifyUrlParameters(data.data.data.images[0], 60, 100)} alt={'sc'}/>
            </div>
          </div>
          {data && type.toUpperCase() !== CONSTANTS.RENTAL_TYPE.TOUR && windowWidth < 767 &&
            <ProductPrice
              currency={currency}
              currencySymbol={data && data.data.data.currencySymbol}
              state={data.data.data}
              data={type}
              _id={data.data.data._id}
            />
          }
          {data.data.data.subCategories &&
            data.data.data.subCategories.length !== 0 &&
            type.toUpperCase() === CONSTANTS.RENTAL_TYPE.TOUR && windowWidth < 767 &&
            <ProductPrice
              state={{...data.data.data, ...data.data.data.subCategories[activeSubCategories]}}
              data={type}
              subCategoryId={data && data.data.data.subCategories[activeSubCategories]._id}
              disabledDates={data && data.data.data.subCategories[activeSubCategories].bookingClosedDates}
              currencySymbol={data && data.data.data.currencySymbol}
            />
          }
        </div>
      }
    </div>
  )
};