import { API_ACTIONS } from "../actions";

const initialState = {
  reviews: [],
  count: 0,
  bookingReviews: [],
};

const reviewReducer = (state = initialState, action) => {
  if (action.type === API_ACTIONS.LIST_RENTAL_TYPE_REVIEWS_SUCCESS) {
    return {
      ...state,
      reviews: action.reviews,
      count: action.count,
    };
  }
  else if(action.type === API_ACTIONS.BOOKING_REVIEWS_SUCCESS) {
    return {
      ...state,
      bookingReviews: action.bookingReviews,
    };
  }

  return state;
};

export default reviewReducer;