import { DEFAULT_ACTIONS } from "../actions";

const initialState = {
  authenticated: false,
  loginModal: false,
  signupModal: false,
  phoneNumber: '',
  currency: '',
};

const authReducer = (state = initialState, action) => {
  if(action.type === DEFAULT_ACTIONS.SET_AUTHENTICATED_USER) {
    return {
      ...state,
      authenticated: action.authenticated,
    };
  }
  else if(action.type === DEFAULT_ACTIONS.SET_LOGIN_MODAL) {
    return {
      ...state,
      loginModal: action.status,
      authenticated: typeof(action.authenticated) === 'boolean' ? action.authenticated: state.authenticated,
    }
  }
  else if(action.type === DEFAULT_ACTIONS.SET_SIGNUP_MODAL) {
    return {
      ...state,
      loginModal: false,
      signupModal: action.status,
      phoneNumber: action.phoneNumber ? action.phoneNumber : state.phoneNumber,
      authenticated: typeof(action.authenticated) === 'boolean' ? action.authenticated: state.authenticated,
    }
  }
  else if(action.type === DEFAULT_ACTIONS.SET_CURRENCY) {
    return {
      ...state,
      currency: action.currency,
    };
  }

  return state;
};

export default authReducer;