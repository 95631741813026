import React from 'react'
import "./Rentalcar.css";

import { IMAGES } from '../../utils';

const Rentalcar = () => {
    const rentaldata = [
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_TWO, ints: 'Interested', name: 'Arethusa al Tavolo', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_THREE, ints: 'Interested', name: 'Charleston Grills', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_ONE, ints: 'Interested', name: 'Cafe’ Provence', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_THREE, ints: 'Interested', name: 'Arethusa al Tavolo', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_TWO, ints: 'Interested', name: 'Charleston Grills', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
        { img: IMAGES.RESTAURANT_PLACE.IMAGE_THREE, ints: 'Interested', name: 'Cafe’ Provence', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' }
    ];

    return (
        <div className='rental-cat'>
            <div className='container'>
                <div className='row'>
                    {rentaldata.map((rentaldatamap, index) =>
                        <div className='col-md-4' key={index}>
                            <div className='iner-rental'>
                                <div className='rent-img'>
                                    <img src={rentaldatamap.img} alt="" />
                                    <span><i className={rentaldatamap.thumbs} aria-hidden="true"></i>{rentaldatamap.ints}</span>
                                </div>
                                <h4>{rentaldatamap.name}</h4>
                                <div className='inter-btn'>
                                    <p>{rentaldatamap.text}</p>
                                    <button className='common-btn'><span>{rentaldatamap.btn} <i className={rentaldatamap.angle} aria-hidden="true"></i></span></button>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <div className='explore-more'>
                    <button className='common-btn'><span>Explore More <i className="fa fa-angle-right" aria-hidden="true"></i></span></button>
                </div>
            </div>
        </div>
    )
}

export default Rentalcar