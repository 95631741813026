import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {FreeMode, Navigation, Thumbs, Zoom} from "swiper";
import Loader from "react-js-loader";
import styles from "../SwiperList.module.css";
import { modifyUrlParameters } from "../../../../components/CardsRenderBlock/CardHome/CarSpecificationsBlock/resizeImages";
import { useWindowWidth } from "../../../../hooks/useWindowWidth";
import "./zoom.css";
import {useSwipeable} from 'react-swipeable';

export const ZoomBlock = ({
                            photos,
                            initialFullSlide,
                            fullscreenIsActive,
                            setFullscreenIsActive,
                          }) => {

  const swiperRef = useRef(null);
  const windowWidth = useWindowWidth();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useEffect(() => {
    swiperRef.current.slideTo(initialFullSlide);
  }, [initialFullSlide]);

  const handleImageClick = (index) => swiperRef.current.slideTo(index);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      windowWidth < 767 && eventData.dir === 'Up' && setFullscreenIsActive(false);
    },
  });

  return (
    <div
      {...handlers}
      className={`${styles.fullscreenContainer} ${
        !fullscreenIsActive && styles.fullscreenContainerOff
      }`}
    >
      <div onClick={() => setFullscreenIsActive(false)} className={styles.closeFon}></div>
      <button
        onClick={() => setFullscreenIsActive(false)}
        className={styles.fullscreenContainerClose}
      >
        ╳
      </button>
      <Swiper
        zoom={true}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        loop={true}
        spaceBetween={0}
        navigation={windowWidth > 767}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[Zoom, FreeMode, Navigation, Thumbs]}
        className={styles.swiperFull}
      >
        {photos.map((el, index) => (
          <SwiperSlide className={styles.swiperFullMainSlide} key={index}>
            <div className={styles.loaderMainSwiper}>
              <Loader />
            </div>
            <div className="swiper-zoom-container">
              <img
                src={modifyUrlParameters(el, 100, 900)}
                alt={el}
                onClick={() => handleImageClick(index)}
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      {windowWidth > 767 && (
        <Swiper
          onSwiper={setThumbsSwiper}
          loop={true}
          spaceBetween={10}
          slidesPerView={photos.length > 5 ? 5 : photos.length}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className={styles.swiperFullBottomBlock}
          style={{
            width: photos.length > 5 ? '540px' : `${(photos.length * 100) + (photos.length - 1)}px`,
          }}
        >
          {photos.map((el, index) => (
            <SwiperSlide
              key={index}
              className={`${styles.swiperFullBottomBlockSlide} ${styles.slide}`}
            >
              <img src={modifyUrlParameters(el, 70, 300)} alt={el} />
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
