export const IMAGES = {
    PROPERTY_ICON: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693820243873/pr.png',
    CAR_RENTAL_ICON: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693820499527/cr.png',
    TOUR_RENTAL_ICON: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693820598678/tr.png',
    FILTER_ICON: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693822034258/filterimg.png',
    USER_PROFILE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823640935/profile.jpeg',
    PLAY_STORE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693824184940/play.png',
    APPLE_STORE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693824226631/appn.png',
    BANNER: {
        PROPERTY: 'https://royoapps-demo.s3.us-west-2.amazonaws.com/cbl_rental_Website/Website/1693820726195/banner.png',
        CAR: 'https://royoapps-demo.s3.us-west-2.amazonaws.com/cbl_rental_Website/Website/1693820771811/carbanner.png',
        TOUR: 'https://royoapps-demo.s3.us-west-2.amazonaws.com/cbl_rental_Website/Website/1693820801646/tourbanner.png',
    },
    FOOTER: {
        IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821223035/ftone.png',
        IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821273474/fttwo.png',
        IMAGE_THREE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821298100/ftthree.png',
        IMAGE_FOUR: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821326358/ftfour.png',
        TIK_TOK_ICON: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821541609/tik.png',
        FRAME: {
            IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693824319898/frameone.png',
            IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693824350130/frametwo.png'
        }
    },
    BLOGS: {
        IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821742426/blogone.png',
        IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821772753/blogtwo.png',
        IMAGE_THREE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693821793015/blogthree.png'
    },
    EXPERIENCE_MOBILE: {
        IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823006268/exone.png',
        IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823038253/extwo.png',
    },
    MOBILE_SERVICES: {
        IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823177304/serone.png',
        IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823208184/sertwo.png',
        IMAGE_THREE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823235574/serthree.png'
    },
    RESTAURANT_PLACE: {
        IMAGE_ONE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823835069/icrestaurant1.png',
        IMAGE_TWO: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823913000/icrestaurant2.png',
        IMAGE_THREE: 'https://rentvipbucket.s3.us-west-2.amazonaws.com/Website/1693823934137/icrestaurant3.png',
    }
};