import { API_ACTIONS } from "../actions";

const initialState = {
  carsList: [],
  carLoader: false,
  car: {},
};

const carReducer = (state = initialState, action) => {
  if(action.type === API_ACTIONS.REQUEST_ALL_CARS) {
    return {
      ...state,
      carLoader: true,
    };
  }
  else if(action.type === API_ACTIONS.LIST_ALL_CARS_SUCCESS) {
    return {
      ...state,
      carsList: action.data,
      carLoader: false,
    };
  }
  else if(action.type === API_ACTIONS.REQUEST_SPECIFIC_CAR) {
    return {
      ...state,
      carLoader: true,
    };
  }
  else if(action.type === API_ACTIONS.REQUEST_SPECIFIC_CAR_SUCCESS) {
    return {
      ...state,
      carLoader: false,
      car: action.data,
    };
  }

  return state;
};

export default carReducer;