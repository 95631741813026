import React, { Fragment } from 'react'

import slideLast from "../assetss/images/slideLast.png"
import logoRent from "../assetss/images/logoRent.png"
import Smodal from './Smodal'

const Banner = ({setViewPopup}) => {
    return (
        <Fragment>
            <div className="mina-slider">
                <div>
                    <div className='main-banner'>
                        <div className="bnr-lg">
                            <div className='container'>
                                <img src={logoRent} alt="" />
                            </div>
                        </div>
                        <img className="bnr-img" src={slideLast} alt="" />
                        <div className='container'>
                            <div className='overlay-text '>
                                <div className='row d-flex align-items-center'>
                                    <div className='col-md-6'>
                                        <div className='inner-baner-data' >
                                            {/* <img src={star} alt="" /> */}
                                            {/* <h3>The ultimate luxury experience</h3> */}
                                            {/* <h2 className="wow fadeInDown animate fadeInUpp" style={{animationDuration: "1s"}}>Enjoy the best moments of life</h2> */}
                                            <div className="caption">
                                                <div className="text-box">
                                                    <div><h2>Luxury Stays</h2></div>
                                                    <div><h2>Ultimate comfort</h2></div>
                                                    <div><h2>Opulent Views</h2></div>
                                                    <div><h2>VIP experience</h2></div>
                                                    <div><h2>Luxury Stays</h2></div>
                                                </div>
                                            </div>
                                            <h4>Coming Soon...</h4>
                                            <button onClick={() => setViewPopup(true)}>Book Now</button>
                                        </div>

                                    </div>
                                    <div className='col-md-6'>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                {/* <div>
                    <div className='main-banner'>
                        <div className="bnr-lg">
                            <div className='container'>
                                <img src={logoRent} alt="" />
                            </div>
                        </div>
                        <img className="bnr-img" src={bannertwo} alt="" />
                        <div className='container'>
                            <div className='overlay-text'>
                                <div className='row d-flex align-items-center'>
                                    <div className='col-md-6'>
                                        <div className='inner-baner-data '>
                                            <img src={star} alt="" />
                                            <h3>Unique places to enjoy and relax</h3>
                                            <h2 className="fadeInUpp"> Enjoy A Luxury Experience</h2>
                                            <div className="caption">
                                                <div className="text-box">
                                                    <div><h2>Enjoy A Luxury Experience</h2></div>

                                                </div>
                                            </div>
                                            <button>Book Now</button>
                                        </div>

                                    </div>
                                    <div className='col-md-6'>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div> */}
                    {/* <div className='main-banner'>
                    <div className="bnr-lg">
                        <div className='container'>
                            <img src={logoRent} alt="" />
                        </div>
                    </div>
                    <img className="bnr-img" src={bannerthree} alt="" />
                    <div className='container'>
                        <div className='overlay-text'>
                            <div className='row d-flex align-items-center'>
                                <div className='col-md-6'>
                                    <div className='inner-baner-data '>
                                        <img src={star} alt="" />
                                        <h3>Luxury hotels and best Resorts</h3>
                                        <h2 className='fadeInUpp'>The Perfect Base For you</h2>
                                        <div className="caption">
                                            <div className="text-box">
                                                <div><h2>The Perfect Base For you</h2></div>

                                            </div>
                                        </div>
                                        <button>Book Now</button>
                                    </div>

                                </div>
                                <div className='col-md-6'>

                                </div>
                            </div>
                        </div>

                    </div>
                </div> */}
                </div>
                <div className='page-btn-mdal'>
   <Smodal/>
   </div>
        </Fragment>
    )
}

export default Banner;