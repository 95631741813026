import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';

import { UserAction } from '../../redux/actions';

const Termscondition = (props) => {
  const [terms, setTerms] = useState({});

  const fetchData = () => {
    const params = {
      type: 'TERM_AND_CONDITION'
    };

    props.cmsDetails(params).then(res => {
      setTerms(res.data)
    });
  }
  useEffect(() => {
    fetchData();
  }, [])

  return (
    <div>
      <section className='terms-sec'>
        <div className='container'>
          <div className='content-about'>
            <h1 className='head1'>{terms.title}</h1>
            <p>{terms.description}</p>
            <p>{terms.description}</p>
          </div>
        </div>
      </section>
    </div>
  )
}

const mapDispatchToProps = {
  cmsDetails: UserAction.cmsDetails,
};

export default connect(null, mapDispatchToProps)(Termscondition);
