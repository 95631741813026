import React from 'react';
import styles from './ServicesOfferedAdvertising.module.css';

export const BackGroundSVG = () => {
  return (
    <div className={styles['svg-wrapper']}>
      <svg width="599" height="715" viewBox="0 0 599 715" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.34">
          <path d="M443 0.900391C412.667 135.134 382.143 366.374 150 433.842C-124 513.475 -169.5 605.708 -201 714.574" stroke="url(#paint0_linear_2876_3360)"/>
          <path d="M474 0.900391C443.667 135.134 413.143 366.374 181 433.842C-93 513.475 -138.5 605.708 -170 714.574" stroke="url(#paint1_linear_2876_3360)"/>
          <path d="M505 0.900391C474.667 135.134 444.143 366.374 212 433.842C-62 513.475 -107.5 605.708 -139 714.574" stroke="url(#paint2_linear_2876_3360)"/>
          <path d="M536 0.900391C505.667 135.134 475.143 366.374 243 433.842C-31 513.475 -76.5 605.708 -108 714.574" stroke="url(#paint3_linear_2876_3360)"/>
          <path d="M567 0.900391C536.667 135.134 506.143 366.374 274 433.842C0 513.475 -45.5 605.708 -77 714.574" stroke="url(#paint4_linear_2876_3360)"/>
          <path d="M598 0.900391C567.667 135.134 537.143 366.374 305 433.842C31 513.475 -14.5 605.708 -46 714.574" stroke="url(#paint5_linear_2876_3360)"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_2876_3360" x1="121" y1="0.900391" x2="121" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
          <linearGradient id="paint1_linear_2876_3360" x1="152" y1="0.900391" x2="152" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
          <linearGradient id="paint2_linear_2876_3360" x1="183" y1="0.900391" x2="183" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
          <linearGradient id="paint3_linear_2876_3360" x1="214" y1="0.900391" x2="214" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
          <linearGradient id="paint4_linear_2876_3360" x1="245" y1="0.900391" x2="245" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
          <linearGradient id="paint5_linear_2876_3360" x1="276" y1="0.900391" x2="276" y2="714.573" gradientUnits="userSpaceOnUse">
            <stop offset="0.302083" stopColor="#4E4539" stopOpacity="0"/>
            <stop offset="1" stopColor="#4E4539"/>
          </linearGradient>
        </defs>
      </svg>

    </div>
  )
}