import {useEffect, useState} from "react";

export const useAnimationOnLoad = (delayTime = 0, isReset) => {

  const [isAnimated, setIsAnimated] = useState(isReset);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsAnimated(true);
    }, isReset ? 0 : delayTime);

    return () => clearTimeout(timeout);
  }, [delayTime, isReset]);

  return isAnimated;
};