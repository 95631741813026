import { API_ACTIONS, DEFAULT_ACTIONS } from "..";
import AlertMessage from "../../../components/Common/AlertMessage";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const userReviewsList = () => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_USER_REVIEWS_LIST, loading: true });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/review/user`);
		dispatch({ type: API_ACTIONS.REQUEST_USER_REVIEWS_LIST, loading: false });

		if(response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.UNAUTHORIZED_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.USER_REVIEWS_LIST_SUCCESS, reviews: response.data.data.reviews, count: response.data.data.count });
		};
	}
	catch(err) {}
};

const specificBookingReview = (bookingId) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/review`, { params: { bookingId } });

		if(response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.UNAUTHORIZED_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.BOOKING_REVIEWS_SUCCESS, bookingReviews: response.data.data });
		};
	}
	catch(err) {}
};

const reviewsByCategoryId = (params) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/review/list`, { params });

		dispatch({ type: API_ACTIONS.LIST_RENTAL_TYPE_REVIEWS_SUCCESS, reviews: response.data.data.reviews, count: response.data.data.count });
	}
	catch (err) {
		dispatch({ type: API_ACTIONS.LIST_RENTAL_TYPE_REVIEWS_ERROR });
	}
};

const remove = (id) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.delete(`${CONSTANTS.API_VERSION.V1}/review`, { params: { reviewId: id } });

		if(response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, 'Review removed successfully');
			dispatch({ type: DEFAULT_ACTIONS.SET_UPDATE_REVIEW_MODAL, status: false });
			dispatch(specificBookingReview(id));
		}
	}
	catch(err) {}
};

const update = (payload) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.patch(`${CONSTANTS.API_VERSION.V1}/review`, payload);

		if(response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, 'Review Updated Successfully');
			dispatch({ type: DEFAULT_ACTIONS.SET_UPDATE_REVIEW_MODAL, status: false });
		}
	}
	catch(err) {}
};

const create = (payload) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/review`, payload);

		if(response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			dispatch({ type: DEFAULT_ACTIONS.SET_CREATE_REVIEW_MODAL, status: false });
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, 'Review created successfully');
		}
	}
	catch (err) {}
};

export {
	userReviewsList,
	reviewsByCategoryId,
	remove,
	update,
	create,
	specificBookingReview
};