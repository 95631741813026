import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Provider, connect, useSelector } from "react-redux";

// CSS
import "./App.css";
import 'animate.css';
import "../src/assets/fonts/Stylesheet.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";

//Components
import Rentalcar from "./components/Rentalcar/Rentalcar";
import {
  Home,
  Profile,
  Notification,
  Reviews,
  Rentalplace,
  PropertyDetails,
  PropertyListing,
  CarListing,
  About,
  CarDetail,
  TermsAndConditions,
  Tours,
  TourDetails,
  ReviewBooking,
  TourMobile,
  BookingDetail,
  EditProfile
} from "./modules";
import Header from "./components/Header";
import ScrollToTop from "../src/ScrollToTop";
import Landingpage from "./components/Landingpage";

//Store
import { store } from "./redux/store";
import { CurrencyAction, UserAction } from "./redux/actions";

// CSS
import "../src/assets/responsive.css";

// Modals
import { LoginModel, SignupModel, AddReviewModel, EditReviewModel } from "./components/Modals";
import {Footer} from "./components/Footer/Footer";
import {FooterBar} from "./components/FooterBar/FooterBar";
import {Listings} from "./modules/Listings/Listings";
import {List} from "./modules/List/List";
import {FooterBarList} from "./components/FooterBarList/FooterBarList";

function App(props) {

  const { pathname } = useLocation();
  const { currency } = useSelector((state) => state.auth);

  useEffect(() => {
    props.listCurrency();

    props.profileDetails();
  }, []);

  return (
    <Provider store={store}>
      <div className="main-page">
        {pathname !== "/rent-a-villa" && pathname !== "/list" && <Header pathname={pathname} />}

        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="rentalplace" element={<Rentalplace />} />
          <Route exact path="tour-list" element={<TourMobile />} />
          <Route exact path="rentalcar" element={<Rentalcar />} />
          <Route exact path="property/:slug" element={<PropertyDetails currency={currency} />} />
          <Route exact path="properties" element={<PropertyListing />} />
          <Route exact path="carlisting" element={<CarListing />} />
          <Route exact path="cardetail/:slug" element={<CarDetail currency={currency} />} />
          <Route exact path="tour-listing/:_id" element={<Tours currency={currency} />} />
          <Route exact path="tour-detail/:slug" element={<TourDetails currency={currency} />} />

          <Route exact path="reviewbooking" element={<ReviewBooking currency={currency} />} />
          <Route exact path="profile" element={<Profile />} />
          <Route exact path="notification" element={<Notification />} />
          <Route exact path="my-reviews" element={<Reviews />} />
          <Route exact path="about-us" element={<About />} />

          <Route exact path="terms-conditions" element={<TermsAndConditions />} />
          <Route exact path="booking-detail/:id/:categoryId" element={<BookingDetail currency={currency} />} />
          <Route exact path="edit-profile" element={<EditProfile />} />
          <Route exact path="rent-a-villa" element={<Landingpage />} />

          <Route exact path={'listings'} element={<Listings />} />
          <Route exact path={'list'} element={<List />} />
        </Routes>
        {pathname !== "/rent-a-villa" && pathname !== "/list" && <Footer homePart={pathname === '/'}/>}
        {pathname === "/" && <FooterBar />}

        <LoginModel />
        <SignupModel />
        <AddReviewModel />
        <EditReviewModel />
      </div>
    </Provider>
  );
}

const mapDispatchToProps = (dispatch) => ({
  listCurrency: () => dispatch(CurrencyAction.list()),
  profileDetails: () => dispatch(UserAction.profileDetails()),
});

export default connect(null, mapDispatchToProps)(App);
