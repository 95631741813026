import { API_ACTIONS, DEFAULT_ACTIONS } from "../actions";

const initialState = {
  createReview: false,
  categoryType: '',
  bookingId: '',
  reviewCategoryId: '',
  editReview: false,
  reviewDetails: {},
  bookingError: false,
  bookings: [],
  booking: {},
  userReviewsLoader: false,
  userReviews: {
    reviews: [],
    count: 0,
  },
  newBooking: {
    bookingId: '',
    status: false
  }
};

const bookingReviewReducer = (state = initialState, action) => {
  if(action.type === DEFAULT_ACTIONS.SET_CREATE_REVIEW_MODAL) {
    return {
      ...state,
      createReview: action.status,
      categoryType: action.categoryType ? action.categoryType : state.categoryType,
      bookingId: action.bookingId ? action.bookingId : state.bookingId,
      reviewCategoryId: action.reviewCategoryId ? action.reviewCategoryId : state.reviewCategoryId,
    };
  }
  else if(action.type === DEFAULT_ACTIONS.SET_UPDATE_REVIEW_MODAL) {
    return {
      ...state,
      editReview: action.status,
      reviewDetails: action.status ? action.review : {},
    };
  }
  else if(action.type === API_ACTIONS.BOOKING_LIST_SUCCESS) {
    return {
      ...state,
      bookings: action.bookings,
      bookingError: false,
    };
  }
  else if(action.type === API_ACTIONS.BOOKING_LIST_ERROR) {
    return {
      ...state,
      bookingError: true,
      bookings: [],
    };
  }
  else if(action.type === API_ACTIONS.SPECIFIC_BOOKING_SUCCESS) {
    return {
      ...state,
      booking: action.booking,
    };
  }
  else if(action.type === API_ACTIONS.REQUEST_USER_REVIEWS_LIST) {
    return {
      ...state,
      userReviewsLoader: action.loading,
    };
  }
  else if(action.type === API_ACTIONS.USER_REVIEWS_LIST_SUCCESS) {
    return {
      ...state,
      userReviews: {
        reviews: action.reviews,
        count: action.count,
      }
    };
  }
  else if(action.type === API_ACTIONS.CREATE_BOOKING_SUCCESS) {
    return {
      ...state,
      newBooking: {
        ...state.newBooking,
        status: action.status,
        bookingId: action.bookingId,
      },
    };
  }
  return state;
};

export default bookingReviewReducer;