import { API_ACTIONS } from "..";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_USER_NOTIFICATION, loader: true });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/notification/listing`, { params });

		dispatch({
			type: API_ACTIONS.USER_NOTIFICATION_SUCCESS,
			notifications: response.data.data.notifications,
			count: response.data.data.count
		});
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.USER_NOTIFICATION_ERROR });
	}
};

const update = (notificationId) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_USER_NOTIFICATION_UPDATE, loader: true });
		await apiAxiosInstance.put(`${CONSTANTS.API_VERSION.V1}/notification/${notificationId}`);
		dispatch({ type: API_ACTIONS.REQUEST_USER_NOTIFICATION_UPDATE, loader: false });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.USER_NOTIFICATION_ERROR });
	}
};

export { list, update };