import React, { useState } from "react";

import Banner from "../Componentss/Banner";
import Experience from "../Componentss/Experience";
import Rooms from "../Componentss/Rooms";
import Services from "../Componentss/Services";
import Tours from "../Componentss/Tours";
import ExporeDubai from "../Componentss/ExporeDubai";
import Cars from "../Componentss/Cars";
import Ourapps from "../Componentss/Ourapps";
import "../assetss/css/style.css";
import Smodal from "../Componentss/Smodal";

function Landingpage(props) {
  const [viewPopup, setViewPopup] = useState(false);
  return (
    <div className="new-land-page">
      <Banner setViewPopup={setViewPopup}/>
      <Experience setViewPopup={setViewPopup}/>
      <Rooms setViewPopup={setViewPopup}/>
      <Services setViewPopup={setViewPopup}/>
      <Tours setViewPopup={setViewPopup} />
      <ExporeDubai setViewPopup={setViewPopup}/>
      <Cars setViewPopup={setViewPopup}/>
      <Ourapps setViewPopup={setViewPopup}/>
      {/* <Footer /> */}
      {viewPopup &&
        <div
          className="modals"
          id="exampleModal"
        >
          <div className="modal-dialog market-modl">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  onClick={() => setViewPopup(false)}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
              </div>
              <div className="modal-body">
                <Smodal setViewPopup={setViewPopup}/>
              </div>
            </div>
          </div>
        </div>
}
    </div>
  );
}

export default Landingpage;
