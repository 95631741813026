import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect, useSelector } from "react-redux";

// CSS
import "./ReviewBooking.css";

// Static Images
import paydata from "../../../assets/images/paydata";

// Components
import { Constants } from "../../../components/Common/Constants";
import { BookingAction } from "../../../redux/actions";
import { CONSTANTS } from "../../../utils";

const ReviewBooking = (props) => {
  const navigate = useNavigate();
  const [checkInData, setCheckInData] = useState(props.state);
  const [amount, setAmount] = useState(0);
  const [guestsList, setGuestsList] = useState([]);
  // const [totalAmount, setTotalAmount] = useState(0);
  // const [subCatData, setSubCatData] = useState([]);
  const [count, setCount] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [paymenttype, setPaymenttype] = useState("CARD");

  const { newBooking } = useSelector((state) => state.booking);


  useEffect(() => {
    setCheckInData(JSON.parse(localStorage.getItem("checkInData")));
    // setSubCatData(JSON.parse(localStorage.getItem("subCat")));
  }, []);

  useEffect(() => {
    if (!!checkInData) {
      if (checkInData.discount) {
        let totalDays = checkInData.days;
        let discountPercentage = 0;
        if (totalDays >= 28 && checkInData.discount.monthly) {
          discountPercentage = checkInData.discount.monthly;
        } else if (totalDays >= 7 && checkInData.discount.weekly) {
          discountPercentage = checkInData.discount.weekly;
        }
        setDiscount(discountPercentage);
      }
      // let price = checkInData.days * checkInData.price;

      // checkInData.guestData.forEach((element) => {
      //   price =
      //     checkInData.data.category_id.type === Constants.categoryType.TOUR
      //       ? subCatData.price * element.count + price
      //       : checkInData.data.price * element.count + price;
      // });
      let numberCount = checkInData.guestData.reduce((acc, cur) => {
        return acc + cur.count;
      }, 0);
      setCount(numberCount);

      setAmount(checkInData.price);
      setGuestsList(checkInData.guestData);
    }
  }, [checkInData]);

  useEffect(() => {
    if (!!amount) {
      // let mainAmount = amount;
      // let discountAmount = 0;
      // if (discount) {
      //   discountAmount = (mainAmount * discount) / 100;
      // }
      
      setVat(checkInData.data.category_id.vat);
      // let vatAmount = (mainAmount * checkInData.data.category_id.vat) / 100;

      // setTotalAmount((mainAmount * checkInData.days) - discountAmount + vatAmount);
    }
  }, [amount]);

  const onConfirmAndPay = async () => {
    let guestData = guestsList.map((guest) => {
      return { name: guest.key, count: guest.count };
    });
    let type = checkInData.data.category_id.type;
    if (type === Constants.categoryType.PROPERTY) {
      const data = {
        property_id: checkInData.data._id,
        checkIn: checkInData.dateTime.checkIn,
        checkOut: checkInData.dateTime.checkOut,
        members: guestData,
        bookingFor: checkInData.bookingFor,
        bookingPrice: checkInData.serviceFee + checkInData.totalPrice,
        bookingPriceVat: vat,
        currency: props.currency,
      };

      if (checkInData.bookingForName)
        data.bookingForName = checkInData.bookingForName;

      if (paymenttype) data.modeOfPayment = CONSTANTS.PAYMENT_MODE[paymenttype]

      props.createPropertyBooking(data);

    } else if (type === Constants.categoryType.CAR) {
      const data = {
        car_id: checkInData.data._id,
        pickupDate: checkInData.dateTime.checkIn,
        dropDate: checkInData.dateTime.checkOut,
        members: guestData,
        bookingFor: checkInData.bookingFor,
        bookingPrice: checkInData.serviceFee + checkInData.totalPrice,
        bookingPriceVat: vat,
        currency: props.currency,
      };

      if (paymenttype) data.modeOfPayment = CONSTANTS.PAYMENT_MODE[paymenttype]

      props.createCarBooking(data);

    } else if (type === Constants.categoryType.TOUR) {
      const data = {
        subCategoryId: checkInData.id,
        tourId: checkInData.data._id,
        date: moment(checkInData.dateTime.checkIn).format("YYYY-MM-DD"),
        members: guestData,
        bookingFor: checkInData.bookingFor,
        bookingPrice: checkInData.serviceFee + checkInData.totalPrice,
        bookingPriceVat: 0,
        currency: props.currency,
      };

      if (paymenttype) data.modeOfPayment = CONSTANTS.PAYMENT_MODE[paymenttype]

      props.createTourBooking(data);
    }
  };

  useEffect(() => {
    if (newBooking.status) {
      let type = checkInData.data.category_id.type;

      if (type === Constants.categoryType.PROPERTY) {
        navigate(`/booking-detail/${newBooking.bookingId}/${checkInData.data.category_id._id}`);
      }
      else if (type === Constants.categoryType.CAR) {
        navigate(
          `/booking-detail/${newBooking.bookingId}/${checkInData.data.category_id._id}`
        );
      }
      else {
        navigate(
          `/booking-detail/${newBooking.bookingId}/${checkInData.data.category_id._id}`
        );
      };
    }
  }, [newBooking]);

  return (
    <>
      <div className="container">
        <div className="pagination">
          <ul className="mb-3">
            <li>
              <Link to={-1}>
                <i className="fas fa-chevron-left"></i>
              </Link>
            </li>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/reviewbooking" className="active">
                Review Booking
              </Link>
            </li>
          </ul>
        </div>
        <div className="row gx-xxl-5 mb-lg-5 reverse-order">
          <div className="col-lg-9 col-xl-8 col-xxl-7">
            <div className="trip-outer">
              <div className="trip-enquiry mb-5 mb-lg-0">
                <div className="packages">
                  <h6>Pay with</h6>
                  <div className="payment-method">
                    <ul>
                      <li>
                        <label onClick={() => setPaymenttype("CARD")} className="rad">Payment by Credit Card
                          <input type="radio" defaultChecked={`${paymenttype === "CARD" ? "checked" : ""}`} name="radio" />
                          <span className="checkmark"></span>
                        </label>

                      </li>
                      <li><label onClick={() => setPaymenttype("CASH")} className="rad">Payment by Cash
                        <input type="radio" defaultChecked={`${paymenttype === "CASH" ? "checked" : ""}`} name="radio" />
                        <span className="checkmark"></span>
                      </label></li>
                      <li><label onClick={() => setPaymenttype("CRYPTO")} className="rad">Payment by Crypto
                        <input type="radio" defaultChecked={`${paymenttype === "CRYPTO" ? "checked" : ""}`} name="radio" />
                        <span className="checkmark"></span>
                      </label></li>
                    </ul>
                    {paymenttype === "CARD" && <div className="pay-image">
                      <img src={paydata} alt="" />
                    </div>}
                  </div>
                </div>
              </div>
              <div className="form-group mt-5">
                <button onClick={() => onConfirmAndPay()}>Confirm & Pay</button>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-xl-4 col-xxl-5">
            <div className="tour">
              <div className="desc">
                {checkInData && checkInData.data && (
                  <div className="row">
                    <div className="col-md-8 col-lg-12 col-xl-7 col-xxl-8">
                      <article>
                        <h5>{checkInData.data.name}</h5>
                        <h6>{checkInData.data.location}</h6>
                      </article>
                    </div>
                    {checkInData.data.images &&
                      checkInData.data.images.length > 0 && (
                        <div className="col-md-4 col-lg-12 col-xl-5 col-xxl-4">
                          <figure>
                            <img src={checkInData.data.images[0]} alt="" />
                          </figure>
                        </div>
                      )}
                  </div>
                )}
              </div>
              <ol className="list-group">
                {checkInData && checkInData.days && (
                  <li className="list-item">
                    <div className="day">
                      <div className="fw-bold mb-xl-3">{`For ${checkInData.data.category_id.type ===
                        Constants.categoryType.TOUR
                        ? count + " person(s)"
                        : checkInData.days + " day(s)"
                        }`}</div>
                    </div>
                    {amount && (
                      <span className="num fw-bold">{`${checkInData.data.currencySymbol} ${(checkInData.avgPrice *
                        (checkInData.data.category_id.type ===
                          Constants.categoryType.TOUR
                          ? count
                          : checkInData.days)).toFixed(2)
                        }`}</span>
                    )}
                  </li>
                )}
                {checkInData &&
                  checkInData.discount &&
                  checkInData.discount !== 0 && (
                    <li className="list-item highlight">
                      <div className="day">Total Discount</div>
                      <span className="num">{`- ${discount}%`}</span>
                    </li>
                  )}
                <li className="list-item">
                  <div className="day">VAT</div>
                  <span className="num">{`+ ${vat}%`}</span>
                </li>
                <li className="list-item">
                  <div className="day fw-bold">Total Payable Amount</div>
                  {checkInData && checkInData.days ? (
                    <span className="num fw-bold">{`${checkInData.data.currencySymbol} ${(checkInData.serviceFee + checkInData.totalPrice).toFixed(2)}`}</span>
                  ) : (
                    <span className="num fw-bold">{`${checkInData && checkInData.currencySymbol} ${checkInData && checkInData.price}`}</span>
                  )}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  createPropertyBooking: (payload) => dispatch(BookingAction.createPropertyBooking(payload)),
  createCarBooking: (payload) => dispatch(BookingAction.createCarBooking(payload)),
  createTourBooking: (payload) => dispatch(BookingAction.createTourBooking(payload)),
});

export default connect(null, mapDispatchToProps)(ReviewBooking);
