import { API_ACTIONS } from "..";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async(dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_CAR });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/car/get/all`, { params });

		dispatch({ type: API_ACTIONS.LIST_ALL_CARS_SUCCESS, data: response.data.data });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_ALL_CARS_ERROR });
	}
};

const specific = (params) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_CAR });

		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/car/details`, { params });

		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_CAR_SUCCESS, data: response.data.data });
	}
	catch (err) {
		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_CAR_ERROR });
	}
};


export { list, specific };