import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// Static Imagess
import product_img from '../../assets/images/igeight.webp';

// Components
import { UserAction } from '../../redux/actions';

const About = (props) => {
  const [about, setAbout] = useState([]);

  const fetchData = () => {

    const params = {
      type: 'ABOUT_US'
    };

    props.cmsDetails(params).then(res => {
      setAbout(res.data)
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <section className='about-sec'>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-md-5'>
              <div className='about-img-size'>
                <img className="img-fluid" src={product_img} alt="product" />
              </div>
            </div>
            <div className='col-md-7'>
              <div className='content-about'>
                <h1 className='head1'>{about.title}</h1>
                <p>{about.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

const mapDispatchToProps = {
  cmsDetails: UserAction.cmsDetails,
};

export default connect(null, mapDispatchToProps)(About);
