import React, { useEffect } from 'react';
import { Field, FormikProvider, useFormik } from "formik";
import { connect, useDispatch, useSelector } from 'react-redux';
import PhoneInput, { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";

import ModalPopup from "../Common/Fields/ModalPopup/ModalPopup";
import { DEFAULT_ACTIONS, UserAction } from '../../redux/actions';

const LoginModel = (props) => {
	const dispatch = useDispatch();

	const { loginModal } = useSelector((state) => state.auth);
	const { login } = useSelector((state) => state.user);

	const closeModal = () => {
		dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_MODAL, status: false });
		loginFormik.resetForm();
	}

	const getNewOTP = async () => {
		const values = loginFormik.values;

		if (values.phone) {

			let valid = isValidPhoneNumber(values.phone);

			if (!valid) loginFormik.setFieldError('phone', 'Invalid phone number');
			else {
				const phoneNumber = parsePhoneNumber(values.phone);
				const payload = { userName: phoneNumber.nationalNumber };

				props.login(payload);
			}
		}
	};

	const loginFormik = useFormik({
		initialValues: {
			phone: '',
			otp: '',
		},
		onSubmit: async (values) => {
			// values.type = 1;
			// values.userName = values.phone;
			// delete values.phone;
			
			const phoneNumber = parsePhoneNumber(values.phone);
			const loginPayload = { type: 1, userName: phoneNumber.nationalNumber, otp: values.otp };
			props.verifyOTP(loginPayload);
		}
	});
	
	useEffect(() => {
		if(login && login.otp) {
			loginFormik.setFieldValue('otp', login.otp);
		}
	}, [login])

	return (
		<ModalPopup
			show={loginModal}
			onHide={closeModal}
			dialogClassName="agent-modal sm-modal"
			title="Enter Phone Number"
			component={
				<FormikProvider value={loginFormik}>
					<div className="page-modal">
						<form
							className="col-md-12"
							onSubmit={(e) => {
								e.preventDefault();

								if (login.otpButton) getNewOTP();
								else loginFormik.handleSubmit(e);
							}}>
							<div className="from-group w-100">
								<label>Phone Number</label>
								<PhoneInput
									value={loginFormik.values.phone}
									international
									name="phone"
									onChange={(value) => value && loginFormik.setFieldValue('phone', value)}
									defaultCountry="AE"
									id="phone-input"
								/>
								{loginFormik.errors.phone ? (
									<div className="error">{loginFormik.errors.phone}</div>
								) : null}
							</div>
							{!login.otpButton && (
								<div className="from-group w-100">
									<label>Enter OTP</label>
									<Field
										name="otp"
										type="number"
										id="field-name"
										maxLength="6"
										minLength="6"
										min="0"
										value={loginFormik.values.otp}
									/>
									{loginFormik.errors.otp && loginFormik.touched.otp ? (
										<div className="error">{loginFormik.errors.otp}</div>
									) : null}
								</div>
							)}
							{
								login.otpButton ? (
									<button type="button" onClick={getNewOTP}>
										Get OTP
									</button>
								)
								:
									<button type="submit">Submit</button>
							}

						</form>
					</div>
				</FormikProvider>
			}
		/>
	);
};

const mapDispatchToProps = (dispatch) => ({
	login: (payload) => dispatch(UserAction.login(payload)),
	verifyOTP: (payload) => dispatch(UserAction.verifyOTP(payload))
});

export default connect(null, mapDispatchToProps)(LoginModel);