import React, { useEffect, useState } from 'react';
import { Field, Formik, Form } from 'formik';
import { connect, useDispatch, useSelector } from 'react-redux';

import { DEFAULT_ACTIONS, ReviewAction } from '../../redux/actions';
import { CONSTANTS } from '../../utils';
import ratingSchema from '../../schemas/ratingSchema';


const AddReview = (props) => {
    const dispatch = useDispatch();

    const { createReview, categoryType, bookingId, reviewCategoryId } = useSelector((state) => state.booking);

    const [initialValues, setInitialValues] = useState({
        ratings: "",
        review: "",
        comfortRating: "",
        exteriorStylingRating: "",
        interiorDesignRating: "",
        performanceRating: "",
        reliabilityRating: "",
        valueMoneyRating: ""
    });

    const closeModal = () => dispatch({ type: DEFAULT_ACTIONS.SET_CREATE_REVIEW_MODAL, status: false });

    const createNewReview = (values) => {
        const payload = {
            ...values,
            categoryType: categoryType,
            reviewCategoryId: reviewCategoryId,
            bookingId: bookingId,
        };

        props.addReview(payload);
    };

    useEffect(() => {
        let initialValues = {};

        if (categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY) {
            initialValues = {
                ratings: "",
                review: "",
                cleanlinessRating: "",
                communicationRating: "",
                checkInRating: "",
                accuracyRating: "",
                locationRating: "",
                valueRating: "",
            };
        }
        else {
            initialValues = {
                ratings: "",
                review: "",
                comfortRating: "",
                exteriorStylingRating: "",
                interiorDesignRating: "",
                performanceRating: "",
                reliabilityRating: "",
                valueMoneyRating: ""
            };
        };

        setInitialValues(initialValues);
    }, [categoryType]);

    return (
        <div className={`modal-new ${!createReview ? 'd-none' : ''}`}>
            <div className="modal-dialog review-modal">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                            Add Reviews
                        </h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={() => closeModal(false)}
                        />
                    </div>
                    <div className="modal-body">
                        <Formik
                            enableReinitialize
                            initialValues={initialValues}
                            validationSchema={ratingSchema}
                            onSubmit={async (values) => {
                                createNewReview(values);
                            }}
                        >
                            {({ errors, touched }) => (
                                <Form>
                                    <div className="from-group">
                                        <label>How was your overall Experience</label>

                                        <div className="rating">
                                            <Field type="radio" id="star5" name="ratings" value="5"
                                            />
                                            <label className="star" htmlFor="star5" title="Awesome" aria-hidden="true" />
                                            <Field type="radio" id="star4" name="ratings" value="4" />
                                            <label className="star" htmlFor="star4" title="Great" aria-hidden="true" />
                                            <Field type="radio" id="star3" name="ratings" value="3" />
                                            <label className="star" htmlFor="star3" title="Very good" aria-hidden="true"
                                            />
                                            <Field type="radio" id="star2" name="ratings" value="2" />
                                            <label className="star" htmlFor="star2" title="Good" aria-hidden="true" />
                                            <Field type="radio" id="star1" name="ratings" value="1" />
                                            <label className="star" htmlFor="star1" title="Bad" aria-hidden="true" />
                                        </div>
                                        {errors.ratings && touched.ratings ? (
                                            <div className="error">{errors.ratings}</div>
                                        ) : null}
                                    </div>
                                    {categoryType === "PROPERTY" ? <div className="rating-cat">
                                        <div className="from-group">
                                            <label>Cleaniliness Rating</label>

                                            <div className="rating">
                                                <Field type="radio" id="cl5" name="cleanlinessRating" value="5"
                                                />
                                                <label className="star" htmlFor="cl5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="cl4" name="cleanlinessRating" value="4" />
                                                <label className="star" htmlFor="cl4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="cl3" name="cleanlinessRating" value="3" />
                                                <label className="star" htmlFor="cl3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="cl2" name="cleanlinessRating" value="2" />
                                                <label className="star" htmlFor="cl2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="cl1" name="cleanlinessRating" value="1" />
                                                <label className="star" htmlFor="cl1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Communication Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="cm5" name="communicationRating" value="5"
                                                />
                                                <label className="star" htmlFor="cm5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="cm4" name="communicationRating" value="4" />
                                                <label className="star" htmlFor="cm4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="cm3" name="communicationRating" value="3" />
                                                <label className="star" htmlFor="cm3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="cm2" name="communicationRating" value="2" />
                                                <label className="star" htmlFor="cm2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="cm1" name="communicationRating" value="1" />
                                                <label className="star" htmlFor="cm1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Check-in Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="ck5" name="checkInRating" value="5"
                                                />
                                                <label className="star" htmlFor="ck5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="ck4" name="checkInRating" value="4" />
                                                <label className="star" htmlFor="ck4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="ck3" name="checkInRating" value="3" />
                                                <label className="star" htmlFor="ck3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="ck2" name="checkInRating" value="2" />
                                                <label className="star" htmlFor="ck2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="ck1" name="checkInRating" value="1" />
                                                <label className="star" htmlFor="ck1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Accuracy Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="ar5" name="accuracyRating" value="5"
                                                />
                                                <label className="star" htmlFor="ar5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="ar4" name="accuracyRating" value="4" />
                                                <label className="star" htmlFor="ar4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="ar3" name="accuracyRating" value="3" />
                                                <label className="star" htmlFor="ar3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="ar2" name="accuracyRating" value="2" />
                                                <label className="star" htmlFor="ar2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="ar1" name="accuracyRating" value="1" />
                                                <label className="star" htmlFor="ar1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Location Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="lc5" name="locationRating" value="5"
                                                />
                                                <label className="star" htmlFor="lc5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="lc4" name="locationRating" value="4" />
                                                <label className="star" htmlFor="lc4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="lc3" name="locationRating" value="3" />
                                                <label className="star" htmlFor="lc3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="lc2" name="locationRating" value="2" />
                                                <label className="star" htmlFor="lc2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="lc1" name="locationRating" value="1" />
                                                <label className="star" htmlFor="lc1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Value Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="rte5" name="valueRating" value="5"
                                                />
                                                <label className="star" htmlFor="rte5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="rte4" name="valueRating" value="4" />
                                                <label className="star" htmlFor="rte4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="rte3" name="valueRating" value="3" />
                                                <label className="star" htmlFor="rte3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="rte2" name="valueRating" value="2" />
                                                <label className="star" htmlFor="rte2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="rte1" name="valueRating" value="1" />
                                                <label className="star" htmlFor="rte1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div> : false}

                                    {/* type-car*/}

                                    {categoryType === "CAR" ? <div className="rating-cat">
                                        <div className="from-group">
                                            <label>Comfort Rating</label>

                                            <div className="rating">
                                                <Field type="radio" id="cl5" name="comfortRating" value="5"
                                                />
                                                <label className="star" htmlFor="cl5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="cl4" name="comfortRating" value="4" />
                                                <label className="star" htmlFor="cl4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="cl3" name="comfortRating" value="3" />
                                                <label className="star" htmlFor="cl3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="cl2" name="comfortRating" value="2" />
                                                <label className="star" htmlFor="cl2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="cl1" name="comfortRating" value="1" />
                                                <label className="star" htmlFor="cl1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Interior Design Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="cm5" name="exteriorStylingRating" value="5"
                                                />
                                                <label className="star" htmlFor="cm5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="cm4" name="exteriorStylingRating" value="4" />
                                                <label className="star" htmlFor="cm4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="cm3" name="exteriorStylingRating" value="3" />
                                                <label className="star" htmlFor="cm3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="cm2" name="exteriorStylingRating" value="2" />
                                                <label className="star" htmlFor="cm2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="cm1" name="exteriorStylingRating" value="1" />
                                                <label className="star" htmlFor="cm1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Reliability Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="ck5" name="interiorDesignRating" value="5"
                                                />
                                                <label className="star" htmlFor="ck5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="ck4" name="interiorDesignRating" value="4" />
                                                <label className="star" htmlFor="ck4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="ck3" name="interiorDesignRating" value="3" />
                                                <label className="star" htmlFor="ck3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="ck2" name="interiorDesignRating" value="2" />
                                                <label className="star" htmlFor="ck2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="ck1" name="interiorDesignRating" value="1" />
                                                <label className="star" htmlFor="ck1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Exterior Styling Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="ar5" name="performanceRating" value="5"
                                                />
                                                <label className="star" htmlFor="ar5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="ar4" name="performanceRating" value="4" />
                                                <label className="star" htmlFor="ar4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="ar3" name="performanceRating" value="3" />
                                                <label className="star" htmlFor="ar3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="ar2" name="performanceRating" value="2" />
                                                <label className="star" htmlFor="ar2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="ar1" name="performanceRating" value="1" />
                                                <label className="star" htmlFor="ar1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Performance Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="lc5" name="reliabilityRating" value="5"
                                                />
                                                <label className="star" htmlFor="lc5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="lc4" name="reliabilityRating" value="4" />
                                                <label className="star" htmlFor="lc4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="lc3" name="reliabilityRating" value="3" />
                                                <label className="star" htmlFor="lc3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="lc2" name="reliabilityRating" value="2" />
                                                <label className="star" htmlFor="lc2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="lc1" name="reliabilityRating" value="1" />
                                                <label className="star" htmlFor="lc1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                        <div className="from-group">
                                            <label>Value Money Rating</label>
                                            <div className="rating">
                                                <Field type="radio" id="rte5" name="valueMoneyRating" value="5"
                                                />
                                                <label className="star" htmlFor="rte5" title="Awesome" aria-hidden="true" />
                                                <Field type="radio" id="rte4" name="valueMoneyRating" value="4" />
                                                <label className="star" htmlFor="rte4" title="Great" aria-hidden="true" />
                                                <Field type="radio" id="rte3" name="valueMoneyRating" value="3" />
                                                <label className="star" htmlFor="rte3" title="Very good" aria-hidden="true"
                                                />
                                                <Field type="radio" id="rte2" name="valueMoneyRating" value="2" />
                                                <label className="star" htmlFor="rte2" title="Good" aria-hidden="true" />
                                                <Field type="radio" id="rte1" name="valueMoneyRating" value="1" />
                                                <label className="star" htmlFor="rte1" title="Bad" aria-hidden="true" />
                                            </div>
                                        </div>
                                    </div> : false}
                                    <div className="from-group textraea-field">
                                        <label className="mt-4">
                                            Tell Us About Your Experience
                                        </label>
                                        <Field name="review" type="textarea" />
                                        {/* {errors.area && touched.area
                                            ? <div className="error">
                                                {errors.area}
                                            </div>
                                            : null} */}
                                    </div>

                                    <button type="submit">Submit</button>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    addReview: (payload) => dispatch(ReviewAction.create(payload))
});

export default connect(null, mapDispatchToProps)(AddReview);