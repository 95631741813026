import React from 'react';

import { IMAGES } from '../../utils';

const Rentalplace = () => {
	const rentaldata = [
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_ONE, ints: 'Interested', name: 'Arethusa al Tavolo', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_TWO, ints: 'Interested', name: 'Charleston Grills', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_THREE, ints: 'Interested', name: 'Cafe’ Provence', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_ONE, ints: 'Interested', name: 'Arethusa al Tavolo', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_TWO, ints: 'Interested', name: 'Charleston Grills', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' },
		{ img: IMAGES.RESTAURANT_PLACE.IMAGE_THREE, ints: 'Interested', name: 'Cafe’ Provence', text: 'Bantam, 456 Contemporary American', btn: 'Explore More', thumbs: 'fa fa-thumbs-up', angle: 'fa fa-angle-right' }
	];

	return (
		<div className='rental-cat'>
			<div className='container'>
				<div className='row'>
					{rentaldata.map((item, index) =>
						<div className='col-md-4' key={index}>
							<div className='iner-rental wow bounceIn ' data-wow-delay="0.5s">
								<div className='rent-img'>
									<img src={item.img} alt="" />
									<span><i className={item.thumbs} aria-hidden="true"></i>{item.ints}</span>
								</div>
								<h4>{item.name}</h4>
								<div className='inter-btn'>
									<p>{item.text}</p>
									<button className='common-btn'><span>{item.btn} <i className={item.angle} aria-hidden="true"></i></span></button>
								</div>
							</div>
						</div>
					)}
				</div>
				<div className='explore-more'>
					<button type='button' className='common-btn'><span>Explore More <i className="fa fa-angle-right" aria-hidden="true"></i></span></button>
				</div>
			</div>
		</div>
	)
}

export default Rentalplace;
