import React from 'react'
import roomone from "../assetss/images/roomone.png"
import roomtwo from "../assetss/images/roomtwo.png"
import roomthree from "../assetss/images/roomthree.png"
import gym from "../assetss/images/gym.png"
import roomfive from "../assetss/images/roomfive.png"
import icone from "../assetss/images/icone.png"
import ictwo from "../assetss/images/ictwo.png"
import icthree from "../assetss/images/icthree.png"
import icfour from "../assetss/images/icfour.png"

function Rooms({setViewPopup}) {
  return (
    <div className="rooms">
        <div className='container'>
            <h2 className='wow fadeInUp animate'>Rooms & Suites</h2>
            <div className='row'>
            <div className='col-md-4'>
            <div className='rom-inner'>
                <img src={roomone} alt="" />
                <div className='ovr-lay-rom'>
                    {/* <h5>$150 / Night</h5> */}
                    <h4>Fully Equipped Kitchen</h4>
                    <span></span>
                    <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={icone} alt="" /></li>
                                <li><img src={ictwo} alt="" /></li>
                                <li><img src={icthree} alt="" /></li>
                                <li><img src={icfour} alt="" /></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>

                </div>
            </div>
            </div>
            <div className='col-md-4'>
            <div className='rom-inner'>
                <img src={roomtwo} alt="" />
                <div className='ovr-lay-rom'>
                    {/* <h5>$200 / Night</h5> */}
                    <h4>Complete Bathroom Essentials</h4>
                    <span></span>
                    <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={icone} alt="" /></li>
                                <li><img src={ictwo} alt="" /></li>
                                <li><img src={icthree} alt="" /></li>
                                <li><img src={icfour} alt="" /></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
                </div>
            </div>
            </div>
            <div className='col-md-4'>
            <div className='rom-inner'>
                <img src={roomthree} alt="" />
                <div className='ovr-lay-rom'>
                    {/* <h5>$250 / Night</h5> */}
                    <h4>Professional Cleaning</h4>
                    <span></span>
                    <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={icone} alt="" /></li>
                                <li><img src={ictwo} alt="" /></li>
                                <li><img src={icthree} alt="" /></li>
                                <li><img src={icfour} alt="" /></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
                </div>
            </div>
            </div>
        </div>  
        <div className='row mt-5'>
        <div className='col-md-6'>
            <div className='rom-inner'>
                <img src={gym} alt="" />
                <div className='ovr-lay-rom'>
                    {/* <h5>$250 / Night</h5> */}
                    <h4>Gym and Pool Access</h4>
                    <span></span>
                    <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={icone} alt="" /></li>
                                <li><img src={ictwo} alt="" /></li>
                                <li><img src={icthree} alt="" /></li>
                                <li><img src={icfour} alt="" /></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
                </div>
            </div>
            </div>
            <div className='col-md-6'>
            <div className='rom-inner'>
                <img src={roomfive} alt="" />
                <div className='ovr-lay-rom'>
                    {/* <h5>$250 / Night</h5> */}
                    <h4>Best City Views With High-Speed Wi-Fi</h4>
                    <span></span>
                    <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={icone} alt="" /></li>
                                <li><img src={ictwo} alt="" /></li>
                                <li><img src={icthree} alt="" /></li>
                                <li><img src={icfour} alt="" /></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
                </div>
            </div>
            </div>
        </div>
        <button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>

        </div>
       
    </div>
  )
}

export default Rooms