import React, {useEffect, useState} from "react";
import {connect, useSelector} from "react-redux";
import "./Home.css";
import { UserAction } from "../../redux/actions";
import {Banner} from "./Banner/Banner";
import {Main} from "./Main/Main";
import {MobileAppAdvertising} from "../../components/MobileAppAdvertising/MobileAppAdvertising";
import {ServicesOfferedAdvertising} from "../../components/ServicesOfferedAdvertising/ServicesOfferedAdvertising";
import {FooterBarList} from "../../components/FooterBarList/FooterBarList";

const Home = (props) => {

  const {startDate, endDate, latitude, longitude} = useSelector(state => state.homePage.dataHomeDashboard);

  const [testCount, setTestCount] = useState(true);
  const refetch = () => setTestCount(!testCount);

  useEffect(() => {
    props.profileDetails();
  }, [])

  return (
    <div className="page-full-data">
      <Banner onSubmit={() => refetch()}/>
      <Main testCount={testCount}/>
      <ServicesOfferedAdvertising />
      {/* <MobileAppAdvertising /> */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  profileDetails: () => dispatch(UserAction.profileDetails()),
});

export default connect(null, mapDispatchToProps)(Home);
