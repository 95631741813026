import React from 'react';
import styles from './Breadcrumbs.module.css';

import {NavLink} from "react-router-dom";

export const Breadcrumbs = ({breadcrumbsData}) => {
  return (
    <nav className={styles.container}>
      <div className={styles.wrapper}>
        {breadcrumbsData.map((breadcrumb, index) => (
          <li key={index}>
            {breadcrumb.url ? (
              <>
                <NavLink to={breadcrumb.url}>{breadcrumb.label}</NavLink>
                <span>/</span>
              </>
            ) : (
              breadcrumb.label
            )}
          </li>
        ))}
      </div>
    </nav>
  )
};