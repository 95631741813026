import React, {useState} from 'react';
import styles from './MainFooter.module.css';
import {NavLink} from "react-router-dom"
import apple_icon from '../../../assets/icons/footer/apple.png';
import facebook_icon from '../../../assets/icons/footer/facebook.png';
import gmail_icon from '../../../assets/icons/footer/gmail.png';
import {Long} from "../../Long/Long";
import {useWindowWidth} from "../../../hooks/useWindowWidth";

export const MainFooter = () => {

  const windowWidth = useWindowWidth();

  const [isShow, setIsShow] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles['top-part-wrapper']}>
        <div className={styles['center-blocks']}>
          <NavLink
            to={'/'}
            style={{
              fontSize: '250%',
              color: 'white',
              marginBottom: '25px',
              textDecoration: 'none'
            }}
          >{'CBRent'}</NavLink>
          <p>Address: Jumeriah beach residence - AI Fatta Marine Tower Office P05</p>
          <p>Managed by: 4RENT PORTAL SERVICES CO.L.L.C</p>
          </div>
        {windowWidth > 767 &&
          <>
            <div className={styles['center-blocks']}>
              <h5>Services</h5>
              <p>Rental Property</p>
              <p>Rental Cars</p>
              <p>Tours</p>
            </div>
            <div className={styles['center-blocks']}>
              <h5>Company</h5>
              <p>Lorem</p>
              <p>Facalty</p>
              <p>Options</p>
            </div>
            <div className={styles['center-blocks']}>
              <h5>Contact Us</h5>
              <p><a href="tel:+971562275555">+971-56-227-5555</a></p>
              <p><a href="mailto:agent@cbrent.com">agent@cbrent.com</a></p>
            </div>
          </>
        }
        {windowWidth < 767 &&
          <div className={styles['center-blocks-wrapper-mobil']}>
            <div className={styles['center-blocks']}>
              <h5 onClick={() => setIsShow(!isShow)}>Services ↓</h5>
              <div className={`${styles['center-block-items']}`} style={{height: isShow ? 'auto' : 0}}>
                <p>Rental Property</p>
                <p>Rental Cars</p>
                <p>Tours</p>
              </div>
            </div>
            <div className={styles['center-blocks']}>
              <h5 onClick={() => setIsShow(!isShow)}>Company ↓</h5>
              <div className={`${styles['center-block-items']}`} style={{height: isShow ? 'auto' : 0}}>
                <p>Lorem</p>
                <p>Facalty</p>
                <p>Options</p>
              </div>
            </div>
            <div className={styles['center-blocks']} style={{marginLeft: 'auto', marginRight: 0, textAlign: "end"}}>
              <h5 onClick={() => setIsShow(!isShow)} style={{marginLeft: 'auto', marginRight: 0}}>Contact Us ↓</h5>
              <div className={`${styles['center-block-items']}`} style={{height: isShow ? 'auto' : 0}}>
                <p><a href="tel:+971562275555">+971-56-227-5555</a></p>
                <p><a href="mailto:agent@cbrent.com">agent@cbrent.com</a></p>
              </div>
            </div>
          </div>
        }
        <div className={styles['network-block']}>
          <a href="https://www.facebook.com/"><img src={facebook_icon} alt={facebook_icon}/></a>
          <a href="https://www.instagram.com//"><img src={gmail_icon} alt={gmail_icon}/></a>
        </div>
      </div>
      <Long radius={true} color={'rgba(255, 255, 255, 0.59)'} />
      <div className={styles['bottom-part-wrapper']}>
        <NavLink to={'/terms-conditions'}>Terms & Condition</NavLink>
        <NavLink to={'/about-us'}>About Us</NavLink>
        <p style={{marginLeft: 'auto', marginRight: '0'}}>© CB-Rent 2023</p>
      </div>
    </div>
  );
};