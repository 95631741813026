import { combineReducers } from '@reduxjs/toolkit';

import authReducer from './auth';
import bookingReviewReducer from './bookingReview';
import propertyReducer from './property';
import carReducer from './cars';
import reviewReducer from './reviews';
import tourReducer from './tours';
import categoryReducer from './categories';
import userReducer from './user';
import homePage from "./homePage";
import footerBarListData from "./footerBarListData";

const rootReducer = combineReducers({
  footerBarListData: footerBarListData,
  auth: authReducer,
  property: propertyReducer,
  booking: bookingReviewReducer,
  car: carReducer,
  reviews: reviewReducer,
  tour: tourReducer,
  category: categoryReducer,
  user: userReducer,
  homePage: homePage,
});
export default rootReducer;