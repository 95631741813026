import { DEFAULT_ACTIONS } from "..";
import { currencyAxiosInstance } from "../../../config";


const list = () => async(dispatch) => {
	try {
		const response = await currencyAxiosInstance.get('');

		dispatch({ type: DEFAULT_ACTIONS.SET_CURRENCY, currency: response.data });
	}
	catch(err) {}
};

export { list };