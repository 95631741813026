import React from 'react'
import  crstar from "../assetss/images/crstar.png"
import Slider from "react-slick";
import slideone from "../assetss/images/slideone.png"

import slidetwo from "../assetss/images/slidetwo.png"
import slidethree from "../assetss/images/slidethree.png"

function Services({setViewPopup}) {
    var carSlide = {
        dots: true,
        infinite: true,
        speed: 500,
        focusOnSelect: false,
        pauseOnHover:false,
        autoplaySpeed: 3000,
        loop:true,
        cssEase: 'linear',
        fade:true,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1
      };
  return (
    <div className='about-main'>
    <div className='container'>
    <Slider {...carSlide}>
        <div className='row d-flex align-items-center'>
            <div className='col-md-6'>
                <div className='left-col-about'>
                    <img src={crstar} alt="" />
                    <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Services <br/>that we offer?</h2>
                    <h6 className='wow fadeInUp animate' style={{animationDuration: "1.5s"}}>Rental Property</h6>
                    <p className='wow fadeInUp animate' style={{animationDuration: "1.7s"}}>It is a long established fact a reader will be some
a random distracted by the readability content
just to add another line to the additional of a
page when looking.</p>
<button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>

                </div>
            </div>
            <div className='col-md-6'>
                <div className='right-col-about'>
              
      <div className="carsli">
        <img src={slideone} alt="" />
      </div>
      
      

                </div>
            </div>
        </div>
        <div className='row d-flex align-items-center'>
            <div className='col-md-6'>
                <div className='left-col-about'>
                    <img src={crstar} alt="" />
                    <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Services <br/>that we offer?</h2>
                    <h6 className='wow fadeInUp animate' style={{animationDuration: "1.5s"}}>Rental Car</h6>
                    <p className='wow fadeInUp animate' style={{animationDuration: "1.7s"}}>It is a long established fact a reader will be some
a random distracted by the readability content
just to add another line to the additional of a
page when looking.</p>   
           <button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>


                </div>
            </div>
            <div className='col-md-6'>
                <div className='right-col-about'>
    
      <div className="carsli">
        <img src={slidetwo} alt="" />
      </div>
                </div>
            </div>
        </div>

        <div className='row d-flex align-items-center'>
            <div className='col-md-6'>
                <div className='left-col-about'>
                    <img src={crstar} alt="" />
                    <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Services <br/>that we offer?</h2>
                    <h6 className='wow fadeInUp animate' style={{animationDuration: "1.5s"}}>Touring</h6>
                    <p className='wow fadeInUp animate' style={{animationDuration: "1.7s"}}>It is a long established fact a reader will be some
a random distracted by the readability content
just to add another line to the additional of a
page when looking.</p>
<button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>

                </div>
            </div>
            <div className='col-md-6'>
                <div className='right-col-about'>
              
      <div className="carsli">
        <img src={slidethree} alt="" />
      </div>
      

                </div>
            </div>
        </div>
        </Slider>
    </div>
   </div>
  )
}

export default Services