export const parseDate = (data) => {
  if(data) {
    const dateString = String(data);
    const dateObject = new Date(dateString);
    const day = dateObject.getDate();
    const month = dateObject.toLocaleString('default', { month: 'short' });
    const year = dateObject.getFullYear();
    return `${day} ${month} ${year}`;
  }
  return null;
}