export const CONSTANTS = {
    API_VERSION: {
        V1: 'v1',
    },
    RENTAL_TYPE: {
        PROPERTY: 'PROPERTY',
        CAR: 'CAR',
        TOUR: 'TOUR',
    },
    PAYMENT_MODE: {
        CASH: 1,
        CARD: 2,
        CRYPTO: 3
    },
    ACCESS_TOKEN: 'accessToken',
    LOGIN_DETAILS: 'loginDetails',
    CHECK_IN_DATA: 'checkInData',
    STATUS_CODE: {
        SUCCESS: 200,
        UNAUTHORIZED: 211,
    },
    ALERTS: {
        SUCCESS_ALERT_VISIBLE: 'SUCCESS_ALERT_VISIBLE',
        ERROR_ALERT_VISIBLE: "ERROR_ALERT_VISIBLE",
        DELETE_ALERT_VISIBLE: "DELETE_ALERT_VISIBLE",
    },
    BOOKING_TYPE: {
        TOUR: 'tour'
    },
    FILE_UPLOAD_TYPE: {
        MARKET_LEAD: 1,
        WEBSITE_IMAGES: 2,
        PROPERTY: 3,
        CAR: 4,
        TOUR: 5,
        CITY: 6,
        USERS_PROFILE: 7,
        USERS_DOCUMENTS: 8
    },
    PROFILE_BOOKING_FILTERS: {
        ACTIVE: 'active',
        PROPERTY: 'property',
        UPCOMING: 'upcoming',
        PAST: 'past',
        CAR: 'car',
        TOUR: 'tour',
    }
};