import styles from './CarSpecificationsBlock.module.css';
import React from 'react';
import {Long} from "../../../Long/Long";

export const CarSpecificationsBlock = ({specifications}) => {

  const {
    mileage,
    transmission,
    engine,
  } = specifications;

  return (
    <div className={styles.container}>
      <Long color={'#C9C9C9'} margin={0}/>
      <div className={styles.wrapper}>
        <div className={styles.block}>
          <span>Mileage (upto)</span>
          <h6>{mileage} km</h6>
        </div>
        <Long
          onSize={'30%'}
          color={'#C9C9C9'}
          vertical={true}
        />
        <div className={styles.block}>
          <span>Transmission</span>
          <h6>{transmission}</h6>
        </div>
        <Long
          onSize={'30%'}
          color={'#C9C9C9'}
          vertical={true}
        />
        <div className={styles.block}>
          <span>Engine</span>
          <h6>{engine} cc</h6>
        </div>
      </div>
    </div>
  );
};