import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import InfiniteScroll from 'react-infinite-scroll-component';

// Static Images
import userNoti from "../../assets/images/userNoti.png";

// Components
import Loading from "../../components/Loader";
import withAuth from "../../hoc/withAuth";
import { NotificationAction } from "../../redux/actions";

const Notification = (props) => {
  const navigate = useNavigate();

  const { notifications, notificationLoader, notificationParams, hasMoreNotification, notificationCount } = useSelector((state) => state.user);

  const fetchNotifications = async () => props.list(notificationParams);

  const updateNotification = async(notificationId) => {

    props.update(notificationId);
  };

  useEffect(() => {
    fetchNotifications()
  }, []);

  return (
    <div>
      {notificationLoader && <Loading />}

      <section className="about-sec notification-page">
        <div className="container">
          <div className="back-bn">
            <div onClick={() => navigate(-1)}>
              <i className="fas fa-chevron-left" />Back
            </div>
          </div>
          <h1 className="head1">Notifications</h1>
          <ul className="nav d-block notif-nav">
            <InfiniteScroll      
              dataLength={notificationCount}
              next={fetchNotifications}
              hasMore={hasMoreNotification}
            >
            {notifications &&
              notifications.map((notifiData, key) =>
                <li key={notifiData._id}>
                  <Link
                    onClick={() => updateNotification(notifiData._id)}
                    to={`/booking-detail/${notifiData.bookingId}/${notifiData.categoryId}`}
                  >
                    <div
                      className="outer-notif"
                      style={{
                        background: `${notifiData &&
                          notifiData.isSeen === false
                          ? "#ececec"
                          : "#fff"}`
                      }}
                    >
                      <div className="img-notif">
                        <img alt="notification-icon" src={userNoti} />
                      </div>
                      <div className="content-notif">
                        <h6>
                          {notifiData.body}
                        </h6>
                        <p>
                          {new Date(notifiData.createdAt).toDateString()}{" "}
                        </p>
                      </div>
                    </div>
                  </Link>
                </li>
              )}
            </InfiniteScroll>
          </ul>
        </div>
      </section>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  list: (query) => dispatch(NotificationAction.list(query)),
  update: (notificationId) => dispatch(NotificationAction.update(notificationId)),
});

export default withAuth(connect(null, mapDispatchToProps)(Notification));
