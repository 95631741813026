import { API_ACTIONS } from "../actions";

const initialState = {
  categories: [],
  subCategories: [],
  loader: false,
};

const categoryReducer = (state = initialState, action) => {
  if(action.type === API_ACTIONS.LIST_SUB_CATEGORIES_SUCCESS) {
    return {
      ...state,
      subCategories: action.data.sub_categories,
    };
  }
  else if(action.type === API_ACTIONS.LIST_CATEGORIES_SUCCESS) {
    return {
      ...state,
      categories: action.data,
    };
  }
  else if(action.type === API_ACTIONS.REQUEST_CATEGORIES) {
    return {
      ...state,
      loader: action.loader,
    };
  }
  return state;
};

export default categoryReducer;