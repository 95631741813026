import { Constants } from "./Constants";
import Swal from "sweetalert2";

const error = (msg) => {
  Swal.fire({
    icon: "error",
    title: "Oops...",
    text: msg,
    timer: 2500,
  });
};

const success = (msg) => {
  Swal.fire({
    title: "Success",
    text: msg,
    icon: "success",
    timer: 2000,
  });
};

const onDelete = () => {
  Swal.fire({
    title: "Deleted!",
    text: "Item has been deleted.",
    icon: "success",
    timer: 1000,
  });
};

export default function AlertMessage(type, message) {
  switch (type) {
    case Constants.SUCCESS_ALERT_VISIBLE:
      return {
        alert: success(message),
      };
    case Constants.ERROR_ALERT_VISIBLE:
      return {
        alert: error(message),
      };
    case Constants.DELETE_ALERT_VISIBLE:
      return {
        alert: onDelete(message),
      };
    default:
      return "";
  }
}
