import React, {Fragment, useEffect, useState} from 'react';
import styles from '../InfoList/InfoList.module.css';
import geo_icon from '../../../assets/icons/geo.png';
import {Long} from "../../../components/Long/Long";
import GoogleMap from "../../../components/Map";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {CONSTANTS} from "../../../utils";
import {LoaderRelative} from "../../../components/CardsRenderBlock/CardsRenderBlock";
import time_icon from '../../../assets/icons/time.png';

const descriptionDesktopMax = 1000;
const descriptionDeMobilMax = 200;

export const InfoListTour = ({state, type, activeSubCategories, setActiveSubCategories}) => {

  const windowWidth = useWindowWidth();
  const [isDescription, setIsDescription] = useState(false);
  const isCAR = type === CONSTANTS.RENTAL_TYPE.CAR;

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 200);
  }, [activeSubCategories])

  const checkIsShowReadMore = () => {
    if(windowWidth > 767 && state.description !== undefined) return state.description.length > descriptionDesktopMax;
    if(windowWidth < 767 && state.description !== undefined) return state.description.length > descriptionDeMobilMax;
  }

  const getParsingDescription = () => {
    return (
      state.subCategories[activeSubCategories].description.split('*').map((paragraph, index) => (
        <React.Fragment key={index}>
          {index > 0 && '-'}
          {paragraph}
          {'\n'}
        </React.Fragment>
      ))
    )
  }

  return !state ? <span>error</span> : (
    <div className={styles.container}>
      <h5 className={styles.name}>{state.name}</h5>
      <div className={styles.location}>
        <img src={geo_icon} alt={geo_icon}/>
        <h4>{state.location}</h4>
      </div>
      <div className={styles.time}>
        <img src={time_icon} alt={time_icon}/>
        <p>{state.averageTime} minutes (approx.)</p>
      </div>
      <div className={styles['subCategories-buttons-block']}>
        {state.subCategories.map((item, index) => (
          <button
            className={`${styles['subCategories-button']} ${index !== activeSubCategories && styles['subCategories-button-off']}`}
            onClick={() => setActiveSubCategories(index)}
          >
            <h5>{item.name}</h5>
            <h6>{state.currencySymbol}<span>{item.price}</span></h6>
          </button>
        ))}
      </div>
      <Long margin={windowWidth > 767 ? 20 : 10} color={'#0095DB'} onSize={'100%'} radius={0}/>
      {isLoading ? <LoaderRelative /> :
        <Fragment>
          <div className={styles['description-block']}>
            {state.subCategories.length !== 0 &&
              state.subCategories[activeSubCategories].description !== undefined &&
              <h6>Description</h6>
            }
              <pre>
                {state.subCategories.length !== 0 &&
                  state.subCategories[activeSubCategories].description !== undefined &&
                  getParsingDescription()
                }
                {/*{state.subCategories[activeSubCategories].description !== undefined && getParsingDescription().slice(0, windowWidth > 767 ? descriptionDesktopMax : descriptionDeMobilMax)}*/}
                {checkIsShowReadMore() &&
                  <button
                    className={styles['description-block-read-more']}
                    onClick={() => setIsDescription(!isDescription)}
                  >
                    {isDescription ? '↑ Close' : '...Read More'}
                  </button>
                }
              </pre>
          </div>
          <div>
            <h6>Meeting & Pickup</h6>
            <p>{state.subCategories.length !== 0 && state.subCategories[activeSubCategories].meetingAndPickup}</p>
          </div>
          <div>
            <h6>End Point</h6>
            <p>{state.subCategories.length !== 0 && state.subCategories[activeSubCategories].endPoint}</p>
          </div>
          <div>
            <h6>What to expect</h6>
            <div className={styles['what-expect-container']}>
              <div className={styles['vertical-long']}></div>
              {state.subCategories.length !== 0 && state.subCategories[activeSubCategories].whatToExpect.map((item, index) =>
                index < (state.subCategories[activeSubCategories].whatToExpect.length - 1)  && (
                  <div className={styles['what-expect-item']}>
                    <p>{item}</p>
                    {/*<span>{item}</span>*/}
                  </div>
              ))}
              <div className={styles['what-expect-item']}><p></p></div>
            </div>
          </div>
          <div className={`${styles['what-expect-item-end']} ${styles['what-expect-item']}`}>
            <p>{state.subCategories.length !== 0 && state.subCategories[activeSubCategories].whatToExpect[state.subCategories[activeSubCategories].whatToExpect.length - 1]}</p>
            {/*<span>{state.subCategories[activeSubCategories].whatToExpect[state.subCategories[activeSubCategories].whatToExpect.length - 1]}</span>*/}
          </div>
          <div className={styles.map}>
            <h6>View on map</h6>
            <div className={styles.mapWrapper}>
              <GoogleMap  positionalLat={state.latitude} positionalLong={state.longitude} />
            </div>
            <div className={styles.location}>
              <img src={geo_icon} alt={geo_icon}/>
              <h4>{state.location}</h4>
            </div>
          </div>
        </Fragment>
      }
      <div className={styles.bottomLong}> <Long color={'#0095DB'} radius={0}/></div>
    </div>
  )
}