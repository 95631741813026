import React, { useEffect, useRef, useState } from "react";
import vnd from "../../assets/images/vnd.jpeg";
import axios from "axios";
import { ApiUrl } from "../../ApiUrl";
import AlertMessage from "../Common/AlertMessage";
import { Constants } from "../Common/Constants";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import crs from "../../assets/images/crs.png"
import cht from "../../assets/images/cht.png"

import io from "socket.io-client";
const Socket = io("https://api-cbrent.cb-premiumapps.com");
// const Socket = io("https://socket.rentvip.com");
// const Socket = io("http://localhost:3000");

const ChatSchema = Yup.object().shape({
  message: Yup.string().required("Required"),
});

function Chat(props) {
  const [open, setOpen] = useState(false);
  const [chatInbox, setChatInbox] = useState(false);
  const [initialValues] = useState({ message: "", createdAt: "" });

  const messageEl = useRef(null);

  const createRoom = async () => {
    try {
      const roomBooking = await axios.post(
        `${ApiUrl.BASE_URL}${ApiUrl.SOCKET_ROOM_BOOKING}`,
        {
          receiver_id: props.receiverId,
          categoryId: props.categoryId,
          bookingId: props.bookingId,
          categoryType: props.categoryType,
          userType: "User",
          receiverType: "Vendor",
        },
        {
          headers: {
            authorization: `${localStorage.getItem("accessToken")}`,
          },
        }
      );

      if (roomBooking.data.statusCode === 200) {
        const messages = await axios.get(
          `${ApiUrl.BASE_URL}${ApiUrl.GET_MESSAGES}`,
          {
            headers: {
              authorization: `${localStorage.getItem("accessToken")}`,
            },
            params: {
              room_id: roomBooking.data.data.room_id,
            },
          }
        );

        if (messages.data.statusCode === 200) {
          Socket.emit("join_room", {
            room_id: roomBooking.data.data.room_id,
          });
          setChatInbox({
            room_id: roomBooking.data.data.room_id,
            receiver_id: props.receiverId,
            bookingId: props.bookingId,
            categoryType: props.categoryType,
            sender_id: JSON.parse(localStorage.getItem("loginDetail"))._id,
            name: `${props.vendor.firstName} ${props.vendor.lastName}`,
            messages: messages.data.data,
            messageType: 1,
          });
        }
      } else {
        // AlertMessage(Constants.ERROR_ALERT_VISIBLE, roomBooking.data.message);
      }
    } catch (error) {
      console.error("API ERROR", error);
    }
  };

  const sendMessage = ({ message }) => {
    const chat = {
      room_id: chatInbox.room_id,
      sender_id: chatInbox.sender_id,
      bookingId: chatInbox.bookingId,
      categoryType: chatInbox.categoryType,
      receiver_id: chatInbox.receiver_id,
      senderType: "User",
      receiverType: "Vendor",
      message: message,
      messageType: 1,
      createdAt: new Date().toString(),
    };
    Socket.emit("message", chat);
    setChatInbox((prev) => {
      return { ...prev, messages: [...prev.messages, chat] };
    });
  };

  useEffect(() => {
    createRoom();
  }, []);

  useEffect(() => {
    // if (Socket.connected) {
    Socket.on("message", (msg) => {
      if (msg.sender_id !== JSON.parse(localStorage.getItem("loginDetail"))._id)
        setChatInbox((prev) => {
          return { ...prev, messages: [...prev.messages, msg] };
        });
    });
    // }

    return () => {
      Socket.off("message");
    };
  }, []);

  // useEffect(() => {
  //   if (messageEl)
  //     messageEl.current.addEventListener("DOMNodeInserted", (event) => {
  //       const { currentTarget: target } = event;
  //       target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  //     });
  // }, [chatInbox]);

  return (
    <div className="chat-fixed">
      <div
        className="open-chat"
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <img src={cht} alt="" />
      </div>
      {open && (
        <div className="chat-message">
          <div className="header-chat">
            <img src={vnd} alt="" />
            <span>{chatInbox.name}</span>
            <label style={{ cursor: "pointer" }} onClick={() => setOpen(false)}>
              <img src={crs} alt="" />
            </label>
          </div>
          <div className="chat-body" ref={messageEl}>
            <div className="innerr-chat-body">
              <ul>
                {chatInbox
                  ? chatInbox.messages &&
                  chatInbox.messages.map((val, index) => (
                    <li
                      key={index}
                      className={
                        val.sender_id ===
                          JSON.parse(localStorage.getItem("loginDetail"))._id
                          ? "vendor-chat"
                          : "user-chat"
                      }
                    >
                      {val.message}
                    </li>
                  ))
                  : null}
              </ul>
              <div className="sent-message">
                <Formik
                  initialValues={initialValues}
                  validationSchema={ChatSchema}
                  onSubmit={(values, { resetForm }) => {
                    sendMessage(values);
                    resetForm({ values: "" });
                  }}
                >
                  {({ errors, touched }) => (
                    <Form>
                      <div className="from-group">
                        <Field
                          type="text"
                          name="message"
                          placeholder="Type here..."
                        />
                        {errors.message && touched.message ? (
                          <div className="error">{errors.message}</div>
                        ) : null}
                      </div>

                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Chat;
