import { API_ACTIONS } from "..";
import AlertMessage from "../../../components/Common/AlertMessage";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async(dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_USER_PROFILE, loader: true });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/booking`, { params });

		if(response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.BOOKING_LIST_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.BOOKING_LIST_SUCCESS, loader: false, bookings: response.data.data });
			dispatch({ type: API_ACTIONS.UPDATE_USER_PROFILE_SUCCESS, redirectToProfile: false });
		};
		dispatch({ type: API_ACTIONS.REQUEST_USER_PROFILE, loader: false });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.BOOKING_LIST_ERROR });
	}
};

const createPropertyBooking = (payload) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: false });
		const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/booking/property/booking`, payload);

		if(response.data && response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, "Booking Successful");
			dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: true, bookingId: response.data.data._id });
		}
		else {
			AlertMessage(CONSTANTS.ALERTS.ERROR_ALERT_VISIBLE, response.data.data.message);
		};
	}
	catch(err) {}
};

const createCarBooking = (payload) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: false });
		const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/booking/car/booking`, payload);

		if(response.data && response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, "Booking Successful");
			dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: true, bookingId: response.data.data._id });
		}
		else {
			AlertMessage(CONSTANTS.ALERTS.ERROR_ALERT_VISIBLE, response.data.data.message);
		};
	}
	catch(err) {}
};

const createTourBooking = (payload) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: false });
		const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/tour/booking`, payload);

		if(response.data && response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, "Booking Successful");
			dispatch({ type: API_ACTIONS.CREATE_BOOKING_SUCCESS, status: true, bookingId: response.data.data._id });
		}
		else {
			AlertMessage(CONSTANTS.ALERTS.ERROR_ALERT_VISIBLE, response.data.data.message);
		};
	}
	catch(err) {}
};

const specific = (bookingId, categoryId) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/booking/${bookingId}/${categoryId}`);

		if(response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.UNAUTHORIZED_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.SPECIFIC_BOOKING_SUCCESS, booking: response.data.data });
		};
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.UNAUTHORIZED_ERROR });
	}
};

export {
	list,
	createPropertyBooking,
	createCarBooking,
	createTourBooking,
	specific
};