import React from 'react'
// import play from '../../assetss/images/play.png'
// import appn from '../../assetss/images/appn.png'
import frametwo from '../assetss/images/frametwo.png'
import frameone from '../assetss/images/frameone.png'

function Ourapps() {
  return (
    <div className='our-app show-mob'>
    <div className='container'>
        <div className='inner-play'>
        <h2 className='wow fadeInUp animate' style={{animationDuration: "1s"}}>Our mobile app</h2>
        <h3 className='wow fadeInUp animate' style={{animationDuration: "1.5s"}}>coming soon.</h3>
        <p className='wow fadeInUp animate' style={{animationDuration: "2s"}}>With best-in-class properties and services that maximizes value we are putting  best-in-class properties.</p>
        {/* <div className='playstore wow fadeInUp' data-wow-delay="0.2s">
            <img src={play} alt="" />
            <img src={appn} alt="" />
        </div> */}
       
        <img className='frame-one wow fadeInLeft' src={frameone} alt="" data-wow-delay="0.3s" />
        <img className='frame-two wow fadeInRight' src={frametwo} alt="" data-wow-delay="0.4s" />
        </div>
    </div>
</div>
  )
}

export default Ourapps