import { API_ACTIONS, DEFAULT_ACTIONS } from "..";
import AlertMessage from "../../../components/Common/AlertMessage";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";
import { Toaster } from "../../../components/Toaster";
import { Constants } from "../../../components/Common/Constants";

const profileDetails = () => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_USER_PROFILE, loader: true });

		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/user`);

		if (response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.USER_PROFILE_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.USER_PROFILE_SUCCESS, profile: response.data.data });
		}
	}
	catch (err) {
		dispatch({ type: API_ACTIONS.USER_PROFILE_ERROR });
	}
};

const cmsDetails = (params) => (_dispatch) => {
	return apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/cms`, { params })
		.then(res => res.data)
		.catch(err => err.response);
};

const upload = (payload) => async (dispatch) => {
	const headers = {
		'Content-Type': 'multipart/form-data'
	};
	const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/files/upload`, payload, { headers });

	return response;
};

const updateProfile = (payload) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_UPDATE_USER_PROFILE, loader: true });

		const response = await apiAxiosInstance.patch(`${CONSTANTS.API_VERSION.V1}/user`, payload);

		if (response.status === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.USER_PROFILE_ERROR });
		}
		else {
			dispatch({ type: API_ACTIONS.UPDATE_USER_PROFILE_SUCCESS });
		}
	}
	catch (err) {
		if (err.response && err.response.statusCode === CONSTANTS.STATUS_CODE.UNAUTHORIZED) {
			dispatch({ type: API_ACTIONS.USER_PROFILE_ERROR });
		}
	}
};

const login = (payload) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/user/login`, payload);

		if (response.data && response.data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_OTP, otp: response.data.data.otp, otpButton: false });
		}
		else {
			AlertMessage(CONSTANTS.ALERTS.ERROR_ALERT_VISIBLE, response.data.message);
		};
	}
	catch (err) { }
};

const signup = (payload) => async (dispatch) => {
	try {
		const { data } = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/user/register`, payload);

		if (data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
			// localStorage.setItem(CONSTANTS.LOGIN_DETAILS, data.data);
			// localStorage.setItem(CONSTANTS.ACCESS_TOKEN, data.data.accessToken);
			dispatch({ type: DEFAULT_ACTIONS.SET_SIGNUP_MODAL, status: false, authenticated: false });
			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, 'Signup successful');

		}
		return data;
	}
	catch (err) {
		Toaster(Constants.TOAST_TYPE.ERROR, err.response.data.message);
		return err;
	}
};

const verifyOTP = (payload) => async (dispatch) => {

	const { data } = await apiAxiosInstance.post(`${CONSTANTS.API_VERSION.V1}/user/verify/otp`, payload);

	if (data.statusCode === CONSTANTS.STATUS_CODE.SUCCESS) {
		if (!!data.data.user.accessToken) {
			localStorage.setItem(CONSTANTS.ACCESS_TOKEN, data.data.user.accessToken);
			localStorage.setItem(CONSTANTS.LOGIN_DETAILS, JSON.stringify(data.user));


			AlertMessage(CONSTANTS.ALERTS.SUCCESS_ALERT_VISIBLE, 'Login successful');
			dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_MODAL, status: false, authenticated: true });
			dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_SUCCESS });

		}
		else {
			dispatch({ type: DEFAULT_ACTIONS.SET_SIGNUP_MODAL, status: true, phoneNumber: payload.phoneNumber });
		};
	}
};

export {
	profileDetails,
	cmsDetails,
	upload,
	updateProfile,
	login,
	signup,
	verifyOTP
};