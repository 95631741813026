import { API_ACTIONS } from "../actions";

const initialState = {
  toursList: [],
  tourLoader: false,
  popularTours: [],
  tourSubCategories: [],
  tourCategories: [],
  tour: {}
};

const tourReducer = (state = initialState, action) => {
  if(action.type === API_ACTIONS.REQUEST_ALL_TOURS) {
    return {
      ...state,
      tourLoader: action.loader,
    };
  }
  else if(action.type === API_ACTIONS.LIST_ALL_TOURS_SUCCESS) {
    return {
      ...state,
      toursList: action.data,
      tourLoader: false,
    };
  }
  else if(action.type === API_ACTIONS.LIST_POPULAR_TOURS_SUCCESS) {
    return {
      ...state,
      popularTours: action.data,
    };
  }
  else if(action.type === API_ACTIONS.SPECIFIC_TOUR_SUCCESS) {
    return {
      ...state,
      tour: action.data,
      tourLoader: false,
    };
  }
  return state;
};

export default tourReducer;