import React, {useEffect} from "react";
import styles from './YouLikeContainer.module.css';
import {useQuery} from "@tanstack/react-query";
import {apiAxiosInstance} from "../../../config";
import {CONSTANTS} from "../../../utils";
import {Swiper, SwiperSlide} from "swiper/react";
import {CardHome} from "../../../components/CardsRenderBlock/CardHome/CardHome";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {FreeMode} from "swiper";

export const YouLikeContainer = ({type}) => {

  const limitCardHome = 8;

  const { data, isLoading, error } = useQuery(
    ['list'],
    () =>
      apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/user/home?type=${type.toUpperCase()}&limit=${limitCardHome}&page=1`),
    {
      cacheTime: 0, // caching time in milliseconds (in this case, 60 seconds)
    }
  );

  const renderDescription = (item) => {
    if (type === CONSTANTS.RENTAL_TYPE.CAR) return item.description;
    if (type === CONSTANTS.RENTAL_TYPE.PROPERTY) return item.location;
    if (type === CONSTANTS.RENTAL_TYPE.TOUR) return item.description;
  };

  const windowWidth = useWindowWidth();

  return (
    <div className={styles.container}>
      <Swiper
        slidesPerView={windowWidth > 767 ? 4.2 : 1.3}
        spaceBetween={windowWidth > 767 ? 20 : 15}
        className={styles['swiper-container']}
        freeMode={true}
        modules={[FreeMode]}
      >
        {data && data.data.data.data.map((item, index) => (
          <SwiperSlide key={index}>
            <CardHome
              image={item.images && item.images.length > 0 && item.images[0]}
              title={item.name}
              link={`/list?type=${type.toLowerCase()}&slug=${item.slug}`}
              description={renderDescription(item)}
              contextType={'ALSO'}
              indexAnimate={index}
              carSpecifications={{
                mileage: item.features ? item.features.engine : '',
                transmission: item.fuel ? item.fuel : '',
                engine: item.features ? item.features.mileage : '',
              }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}