import { API_ACTIONS } from "..";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async(dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_CATEGORIES, loader: true });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/category`, { params });

		dispatch({ type: API_ACTIONS.REQUEST_CATEGORIES, loader: false });
		dispatch({ type: API_ACTIONS.LIST_CATEGORIES_SUCCESS, data: response.data.data.categories });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_CATEGORIES_ERROR });
	}
};

const specific = (id) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/category/${id}`);

		dispatch({ type: API_ACTIONS.LIST_SUB_CATEGORIES_SUCCESS, data: response.data.data });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_TOUR_SUB_CATEGORIES_ERROR });
	}
};

export { list, specific };