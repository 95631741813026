import { API_ACTIONS, DEFAULT_ACTIONS } from "../actions";

const initialState = {
  notifications: [],
  notificationLoader: false,
  hasMoreNotification:  true,
  notificationParams: {
    offset: 0,
    limit: 7,
  },
  notificationCount: 0,
  profile: {},
  profileLoader: false,
  profileUnauthorizedError: false,
  redirectToProfile: false,
  login: {
    otp: 0,
    otpButton: true,
  }
};

const userReducer = (state = initialState, action) => {
  if (action.type === API_ACTIONS.USER_NOTIFICATION_SUCCESS) {
    const tempNotifications = [...state.notifications, ...action.notifications];

    return {
      ...state,
      notifications: tempNotifications,
      notificationLoader: false,
      hasMoreNotification: action.notifications.length === 0 ? false: true,
      notificationParams: {
        ...state.notificationParams,
        offset: state.notificationParams.offset + state.notificationParams.limit,
      },
      notificationCount: action.count,
    };
  }
  else if([API_ACTIONS.REQUEST_USER_NOTIFICATION, API_ACTIONS.REQUEST_USER_NOTIFICATION_UPDATE].includes(action.type)) {
    return {
      ...state,
      notificationLoader: action.loader,
    };
  }
  else if(action.type === API_ACTIONS.REQUEST_USER_PROFILE) {
    return {
      ...state,
      profileLoader: typeof action.loader === 'boolean' ? action.loader: true,
    }
  }
  else if(action.type === API_ACTIONS.USER_PROFILE_SUCCESS) {
    return {
      ...state,
      profile: action.profile,
      profileLoader: false,
      profileUnauthorizedError: false
    };
  }
  else if(action.type === API_ACTIONS.USER_PROFILE_ERROR) {
    return {
      ...state,
      profileUnauthorizedError: true
    };
  }
  else if(action.type === API_ACTIONS.UPDATE_USER_PROFILE_SUCCESS) {
    return {
      ...state,
      redirectToProfile: typeof action.redirectToProfile === 'boolean' ? action.redirectToProfile : true,
    };
  }
  else if(action.type === DEFAULT_ACTIONS.SET_LOGIN_OTP) {
    return {
      ...state,
      login: {
        ...state.login,
        otp: action.otp,
        otpButton: action.otpButton,
      }
    };
  }
  else if(action.type === DEFAULT_ACTIONS.SET_LOGIN_SUCCESS) {
    return {
      ...state,
      login: {
        ...state.login,
        otpButton: true,
      }
    };
  }
  return state;
};

export default userReducer;