import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { connect, useSelector } from "react-redux";

// Components
import { UserAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";
import withAuth from "../../../hoc/withAuth";
import { CONSTANTS, IMAGES } from "../../../utils";

const styles = {
  preview: {
    display: "flex",
    flexDirection: "column",
  },
  image: { maxWidth: "100%" },
  delete: {
    cursor: "pointer",
    color: "white",
    border: "none",
  },
};

const Editprofile = (props) => {
  const navigate = useNavigate();

  const { profile, profileUnauthorizedError, redirectToProfile } = useSelector((state) => state.user);

  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    image: "",
  });

  const uploadImage = async (event) => {
    const { files } = event.target;
    setLoading(true);

    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("type", CONSTANTS.FILE_UPLOAD_TYPE.USERS_PROFILE);

    const response = await props.uploadFile(formData);

    setLoading(false);
    return response.data.data.fileUrl;
  };

  const fetchProfileEdit = async (values) => {
    const payload = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phoneNumber: values.phoneNumber,
      image: values.image,
    };

    props.updateProfile(payload);
  };

  useEffect(() => {
    props.profileDetails();
  }, []);

  useEffect(() => {
    if (profile) {
      setInitialValues({
        firstName: profile.firstName,
        lastName: profile.lastName,
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        image: profile.image && profile.image.length ? profile.image : '',
      });
    }
  }, [profile]);

  useEffect(() => {
    if (profileUnauthorizedError) {
      props.logOut();
    }
  }, [profileUnauthorizedError]);

  useEffect(() => {
    if (redirectToProfile) {
      navigate(`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`);
    }
  }, [redirectToProfile])

  return (
    <>
      {loading && <Loading />}
      <div className="back-bn mt-3">
        <div className="container">
          <div onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left" />Back
          </div>
        </div>
      </div>
      <div className="edit-profile-main">
        <div className="container">
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values) => {
              fetchProfileEdit(values);
            }}
          >
            {({ errors, touched, handleChange }) => (
              <Form>
                <div className="form-group add-image new-add-gallery">
                  <label>Add Image</label>
                  <div style={styles.container}>
                    {errors.image && touched.image ? (
                      <div className="error">{errors.image}</div>
                    ) : null}
                    <span>
                      <i className="fas fa-images" />
                    </span>

                    <div className="main-img-priew" style={styles.preview}>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        onChange={async (event) => {
                          const image = await uploadImage(event);
                          setInitialValues((prev => ({ ...prev, image })))
                        }}
                      />

                      {initialValues.image ? <img
                        name="image"
                        src={initialValues.image}
                        style={styles.image}
                        alt="Thumb"
                      /> : <img src={IMAGES.USER_PROFILE} alt="default-user-profile" />}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>First Name</label>
                  <Field
                    name="firstName"
                    placeholder="Enter First Name"
                    type="text"
                    onChange={handleChange}
                  />
                  {errors.firstName && touched.firstName ? (
                    <div className="error">{errors.firstName}</div>
                  ) : null}
                </div>

                <div className="form-group">
                  <label>Last Name</label>
                  <Field
                    name="lastName"
                    placeholder="Enter Last Name"
                    type="text"
                    onChange={handleChange}
                  />
                  {errors.lastName && touched.lastName ? (
                    <div className="error">{errors.lastName}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <Field
                    name="email"
                    placeholder="Enter Email"
                    type="text"
                    onChange={handleChange}
                    disabled="disabled"
                  />
                  {errors.email && touched.email ? (
                    <div className="error">{errors.email}</div>
                  ) : null}
                </div>
                <div className="form-group">
                  <label>Phone Number</label>
                  <Field
                    name="phoneNumber"
                    placeholder="Enter Phone Number"
                    type="text"
                    onChange={handleChange}
                    disabled="disabled"
                  />
                  {errors.phoneNumber && touched.phoneNumber ? (
                    <div className="error">{errors.phoneNumber}</div>
                  ) : null}
                </div>

                <button type="submit">Update</button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  profileDetails: () => dispatch(UserAction.profileDetails()),
  updateProfile: (payload) => dispatch(UserAction.updateProfile(payload)),
  uploadFile: (payload) => dispatch(UserAction.upload(payload)),
});

export default withAuth(connect(null, mapDispatchToProps)(Editprofile));
