import axios from 'axios';

import { ENV_CONFIG } from '../../utils/env';

const currencyAxiosInstance = axios.create({
	baseURL: ENV_CONFIG.CURRENCY_URL,
});

currencyAxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token && config.headers) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

const apiAxiosInstance = axios.create({
	baseURL: ENV_CONFIG.API_BASE_URL,
	headers: {
		Accepted: 'application/json',
		'Content-Type': 'application/json',
	},
});

apiAxiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken');
    if (token && config.headers) {
      config.headers.authorization = token;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

export { currencyAxiosInstance, apiAxiosInstance };