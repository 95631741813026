import React from 'react';
import styles from './SelectProperty.module.css';
import PropertyIcon from './../../assets/icons/Property.png';
import CarRentalIcon from './../../assets/icons/CarRental.png';
import ToursIcon from './../../assets/icons/Tours.png';
import {CustomButton} from "../Common/Buttons/Dashboard/SelectButton/SelectButton";
import {CONSTANTS} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {setActiveCategoryHomePage} from "../../redux/reducers/homePage";

export const SelectProperty = () => {

  const activeCategoryHomePage = useSelector(state => state.homePage.activeCategoryHomePage);
  const dispatch = useDispatch();

  const buttons = [
    {
      title: 'Stay',
      icon: PropertyIcon,
      RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.PROPERTY,
    },
    {
      title: 'Car Rental',
      icon: CarRentalIcon,
      RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.CAR
    },
    {
      title: 'Tours',
      icon: ToursIcon,
      RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.TOUR
    }
  ];

  return (
    <div className={styles.container}>
      <div className={styles.fon}></div>
      {buttons.map((button, index) => (
        <CustomButton
          key={index}
          title={button.title}
          icon={button.icon}
          callback={() => dispatch(setActiveCategoryHomePage({activeCategoryHomePage: button.RENTAL_TYPE}))}
          active={button.RENTAL_TYPE === activeCategoryHomePage}
        />
      ))}
    </div>
  );
};