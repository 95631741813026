import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { connect, useSelector } from 'react-redux';

// Static Images
import lc from "../../../assets/images/lc.png";

// Components
import ProductPrice from "../../../components/ProductPrice";
import Chat from "../../../components/Support/Chat";
import { ReviewAction, TourAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";
import GoogleMap from "../../../components/Map";
import { Fragment } from "react";

const Tourdetails = ({ currency, reviewsByCategoryId, specificTour }) => {
  const { slug } = useParams();

  const { reviews } = useSelector((state) => state.reviews);
  const { tour, tourLoader } = useSelector((state) => state.tour);

  const [subCategoryId, setSubCategoryId] = useState("");
  const [pricelist, setPricelist] = useState([]);
  const [disabledDates, setDisabledDates] = useState([]);
  const [priceTable, showPriceTable] = useState(false);
  const [selectTour, setSelectTour] = useState({});
  const [selectSubCat, setSelectSubCat] = useState({});
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const onSubCatHandle = (item) => {
    setSelectSubCat(item);
    setSelectTour(item);

    localStorage.setItem("subCat", JSON.stringify(item));

    showPriceTable(true);
    setSubCategoryId(item._id);

    if (item && item.bookingClosedDates) setDisabledDates(item.bookingClosedDates);

    setPricelist((prev) =>
      prev.map((val) => {
        val.active = val.price === item.price;
        return val;
      })
    );
  };

  useEffect(() => {
    if(currency && currency.length) {
      specificTour(slug, currency);
    }
  }, [currency]);

  useEffect(() => {
    if (Object.keys(tour).length) {
      if (tour.subCategories && tour.subCategories.length) {
        setSelectSubCat(tour.subCategories[0]);
        setPricelist(tour.subCategories);
      }

      const query = { reviewCategoryId: tour._id };

      reviewsByCategoryId(query);
    }
  }, [tour]);

  return (
    <Fragment>
      {localStorage.getItem("accessToken") && tour ? (
        <div className="chat-page">
          <Chat
            categoryId={tour._id}
            categoryType="Tour"
            receiverId={tour.vendorId}
            vendor={{
              firstName: tour.vendorFirstName,
              lastName: tour.vendorLastName,
            }}
          />
        </div>
      ) : null}
      {tourLoader && <Loading />}
      <div className="product-detail-page">
        <div className="container">
          <div className="pagination">
            <ul className="mb-3">
              <li>
                <Link to="/properties">
                  <i className="fas fa-chevron-left"></i>
                </Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              {/* <li>
              <Link to="/tour-listing">Tours Listing</Link>
            </li> */}
              <li>
                <Link to="/cardetail" className="active">
                  Tours Detail
                </Link>
              </li>
            </ul>
          </div>
          <div className="product-slider-main">
            <div className="main-sider">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                dots={true}
              >
                {tour &&
                  tour.images &&
                  tour.images.length > 0 &&
                  tour.images.map((tousSlide, index) => (
                    <div key={index}>
                      <img src={tousSlide} alt="nain-tour" />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="side-sider">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div>
                  <img
                    src={
                      tour &&
                      tour.images &&
                      tour.images.length > 0 &&
                      tour.images[0]
                    }
                    alt="sliderImage1"
                  />
                </div>
                <div>
                  <img
                    src={
                      tour &&
                      tour.images &&
                      tour.images.length > 0 &&
                      tour.images[1]
                    }
                    alt="sliderImage2"
                  />
                </div>
                <div>
                  <img
                    src={
                      tour &&
                      tour.images &&
                      tour.images.length > 0 &&
                      tour.images[2]
                    }
                    alt="sliderImage3"
                  />
                </div>
              </Slider>
              <div className="more-hotos">
                <p data-bs-toggle="modal" data-bs-target="#gallerymodal">
                  {tour.imagesCount} Photos
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="slider-desc">
                <h4>
                  {tour && tour.name} hosted by
                  {tour.vendorFirstName} {tour.vendorLastName}
                  {/* <label>
                  130<span>/day</span>
                </label> */}
                </h4>
                <h5 className="amp-loc">
                  <img src={lc} alt="" />
                  {tour && tour.location}
                </h5>
                <h6>Sub category</h6>
                <ul className="sub-cat">
                  {tour &&
                    pricelist.length > 0 &&
                    pricelist.map((subCat) => (
                      <li
                        className={selectSubCat._id === subCat._id ? "active" : "incative"}
                        key={subCat._id}
                        style={{ cursor: "pointer" }}
                        onClick={() => onSubCatHandle(subCat)}
                      >
                        <h5>{subCat.name}</h5>
                        <h6>
                          {tour.currencySymbol} {subCat.price}
                        </h6>
                      </li>
                    ))}
                </ul>
                <hr></hr>
                <h6>Description</h6>
                <p>{selectSubCat && selectSubCat.description}</p>
                <h6>Meeting & Pickup</h6>
                <p>{selectSubCat && selectSubCat.meetingAndPickup}</p>
                <h6>End Point</h6>

                <p> {selectSubCat && selectSubCat.endPoint}</p>
                <h6>What to expect</h6>
                <ul>
                  {selectSubCat &&
                    selectSubCat.whatToExpect &&
                    selectSubCat.whatToExpect.length > 0 &&
                    selectSubCat.whatToExpect.map((expectList, index) => (
                      <li key={index}>
                        <span>{expectList}</span>
                      </li>
                    ))}
                </ul>

                <h6>View on map</h6>
                <div id="map-show" className="map mb-4">
                  <GoogleMap positionalLat={tour.latitude} positionalLong={tour.longitude} />
                </div>
                <h5 className="amp-loc">
                  <img src={lc} alt="" />
                  {tour && tour.location}
                </h5>

                <div className="reviewlist">
                  {tour.ratingQuantity && tour.ratingQuantity ? (
                    <h4>
                      <i className="fas fa-star"></i> {tour.ratingAverage} .{" "}
                      {tour.ratingQuantity} reviews
                    </h4>
                  ) : (
                    <h4>No Review Yet</h4>
                  )}

                  <ul>
                    {reviews.map((listData, index) => (
                      <li key={index}>
                        <h5>
                          {listData.firstName} {listData.lastName}
                        </h5>
                        <p>{listData.review}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            {priceTable && subCategoryId && (
              <div className="col-md-3">
                <ProductPrice
                  state={{ ...tour, ...selectTour }}
                  data="tour"
                  subCategoryId={subCategoryId}
                  disabledDates={disabledDates}
                  currencySymbol={tour.currencySymbol}
                />
              </div>
            )}
          </div>
        </div>
        <div className="my-gallary">
          <div
            className="modal fade"
            id="gallerymodal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modal-images">
                    {tour &&
                      tour.images &&
                      tour.images.length > 0 &&
                      tour.images.map((tousSlide, index) => (
                        <div key={index}>
                          <img src={tousSlide} alt="nain-tour" />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reviewsByCategoryId: (params) => dispatch(ReviewAction.reviewsByCategoryId(params)),
  specificTour: (tourId, currency) => dispatch(TourAction.specific(tourId, currency)),
});

export default connect(null, mapDispatchToProps)(Tourdetails);
