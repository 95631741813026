export const Constants = {
  authorization: {
    headers: {
      authorization: `${localStorage.getItem("accessToken")}`,
    },
  },
  guestOptions: [
    {
      name: "ADULTS",
      key: 1,
      age: "Age 13+",
      min: 1,
      max: 12,
      count: 1,
    },
    {
      name: "INFANTS",
      key: 2,
      age: "Under 2",
      min: 0,
      max: 5,
      count: 0,
    },
    {
      name: "CHILDREN",
      key: 3,
      age: "Ages 2–12",
      min: 0,
      max: 5,
      count: 0,
    },
  ],
  categoryType: {
    PROPERTY: "PROPERTY",
    CAR: "CAR",
    TOUR: "TOUR",
  },
  TOAST_TYPE : {
    INFO: "info",
    SUCCESS: "success",
    WARN: "warn",
    ERROR: "error",
  },
  SUCCESS_ALERT_VISIBLE: "SUCCESS_ALERT_VISIBLE",
  ERROR_ALERT_VISIBLE: "ERROR_ALERT_VISIBLE",
  DELETE_ALERT_VISIBLE: "DELETE_ALERT_VISIBLE",
};
