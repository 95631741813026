import React from 'react';
import {NavLink} from "react-router-dom";
import styles from './CardHome.module.css';
import buildingsSize from "./../../../assets/sizeCards/buildingsSize.png";
import carsSize from "./../../../assets/sizeCards/carsSize.png";
import toursSize from "./../../../assets/sizeCards/toursSize.png";
import also_icon from './../../../assets/sizeCards/also.png';
import {useSequentialOutput} from "../../../hooks/useSequentialOutput";
import {CarSpecificationsBlock} from "./CarSpecificationsBlock/CarSpecificationsBlock";
import {CONSTANTS} from "../../../utils";
import {modifyUrlParameters} from "./CarSpecificationsBlock/resizeImages";
import {useWindowWidth} from "../../../hooks/useWindowWidth";


export const CardHome = ({
  image = 'https://podarini.ru/wa-data/public/shop/themes/topmarket/img/no-foto.gif',
  link = '/',
  title = 'title',
  description = 'description',
  contextType = 'CAR',
  carSpecifications = {},
  indexAnimate = 0,
}) => {

  const isShow = useSequentialOutput(indexAnimate * 200);

  const getBackgroundPhoto = () => {
    if(contextType === 'CAR') return carsSize;
    if(contextType === 'PROPERTY') return buildingsSize;
    if(contextType === 'TOUR') return toursSize;
    if(contextType === 'ALSO') return also_icon;
    return carsSize;
  };

  const windowWidth = useWindowWidth();
  const quality =  windowWidth > 767 ? 90 : 70;
  const width =  windowWidth > 767 ? 600 : 500;

  const getDescription = () => description && description.slice(0, 150);

  return (
    <NavLink
      to={link}
      className={`${styles.container} ${isShow && styles['container-initial']}`}
    >
      <div className={styles['photo-block']}>
        <img
          className={styles['back-photo']}
          src={getBackgroundPhoto()}
          alt={getBackgroundPhoto()}
        />
        <div className={styles['glare-wrapper']}>
          <div className={styles['glare-item']}></div>
        </div>
        <div
          className={styles['main-photo']}
          style={{backgroundImage: `url(${modifyUrlParameters(image, quality, width)})`,}}
        ></div>
        <div className={styles['book-now']}>
          <div className={styles['book-now-fon']}></div>
          <p className={styles['book-now-content']}>Book Now</p>
        </div>
      </div>
      <h5>{title}</h5>
      <p className={styles['content-location']}>{getDescription()}...</p>
      {contextType === CONSTANTS.RENTAL_TYPE.CAR &&
        <CarSpecificationsBlock
          specifications={carSpecifications}
        />
      }
    </NavLink>
  );
};