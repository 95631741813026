import { useQuery } from '@tanstack/react-query';

const fetchImage = async (url) => {
  return String(url);
};

export const useImageQuery = (key, url, time = 600000) => {
  return useQuery([key, url], () => fetchImage(url), {
    // Caching options
    staleTime: time, // Time in milliseconds after which the data is considered stale
  });
};