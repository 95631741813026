import React from 'react';
import styles from './Footer.module.css';
import {HomePartFooter} from "./HomePartFooter/HomePartFooter";
import {MainFooter} from "./MainFooter/MainFooter";
import {Long} from "../Long/Long";
import {GoogleTagManager} from "../GoogleTagManager/GoogleTagManager";


export const Footer = ({
  homePart = false,
}) => {

  return (
    <div className={styles.container}>
      <GoogleTagManager gtmId={'GTM-59QDNX5'}/>
      <div className={styles.wrapper}>
        {homePart && (
          <>
            <HomePartFooter />
            <Long radius={true} color={'rgba(255, 255, 255, 0.59)'} />
          </>
        )}
        <MainFooter />
      </div>
    </div>
  );
};