import React, { useEffect, Fragment } from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";

// Components
import { ReviewAction } from "../../redux/actions";
import Loading from "../../components/Loader";
import withAuth from "../../hoc/withAuth";
import { CONSTANTS } from "../../utils";

const Myreview = (props) => {

  const { userReviews, userReviewsLoader } = useSelector((state) => state.booking);

  const fetchReviewsList = async () => {
    props.userReviews()
  };

  useEffect(() => {
    fetchReviewsList();
  }, []);

  return (
    <Fragment>
      {userReviewsLoader && <Loading />}
      <div className="my-review-page">
        <div className="container">
          <div className='back-bn'>
            <Link to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`}>
              <i className="fas fa-chevron-left"></i>Back
            </Link>
          </div>
          <div className="reviewlist">
            <ul>
              {userReviews.reviews &&
                userReviews.reviews.map((listData, index) => (
                  <li key={index}>
                    <div className="review-image">
                      <img src={listData.reviewCategoryId.images[0]} alt="" />
                      <div className="date-create">
                        <h5>{listData.categoryType}</h5>

                        <h6>{new Date(listData.createdAt).toDateString()}</h6>
                      </div>
                    </div>
                    <p>{listData.review}</p>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

const mapDispatchToProps = (dispatch) => ({
  userReviews: () => dispatch(ReviewAction.userReviewsList()),
});

export default withAuth(connect(null, mapDispatchToProps)(Myreview));