import React, {useEffect, useState} from 'react';
import styles from './Main.module.css';
import {apiAxiosInstance} from "../../../config";
import {CONSTANTS} from "../../../utils";
import {useQuery} from "@tanstack/react-query";
import {CardsRenderBlock, LoaderRelative} from "../../../components/CardsRenderBlock/CardsRenderBlock";
import {useSelector} from "react-redux";
import {useWindowWidth} from "../../../hooks/useWindowWidth";

export const Main = ({testCount}) => {

  const windowWidth = useWindowWidth();
  const activeCategoryHomePage = useSelector(state => state.homePage.activeCategoryHomePage);

  const getCategoryList = () => {
    let array = [];
    array.push(activeCategoryHomePage);
    if(activeCategoryHomePage === CONSTANTS.RENTAL_TYPE.PROPERTY) {
      array.push(CONSTANTS.RENTAL_TYPE.CAR);
      array.push(CONSTANTS.RENTAL_TYPE.TOUR);
    } else
    if(activeCategoryHomePage === CONSTANTS.RENTAL_TYPE.CAR) {
      array.push(CONSTANTS.RENTAL_TYPE.PROPERTY);
      array.push(CONSTANTS.RENTAL_TYPE.TOUR);
    } else
    if(activeCategoryHomePage === CONSTANTS.RENTAL_TYPE.TOUR) {
      array.push(CONSTANTS.RENTAL_TYPE.PROPERTY);
      array.push(CONSTANTS.RENTAL_TYPE.CAR);
    }
    return array;
  };

  const [limitCardHome, setLimitCardHome] = useState(3);

  useEffect(() => {
    setLimitCardHome(windowWidth > 767 ? 3 : 8);
  }, [windowWidth]);

  const fetchData = async (activeCategoryHomePage, limitCardHome) => {
    const checkLimitCardHome = activeCategoryHomePage === CONSTANTS.RENTAL_TYPE.PROPERTY ? 6 : limitCardHome;
    const response = await
      apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/user/home?type=${activeCategoryHomePage}&limit=${checkLimitCardHome}&page=0`);
    return response.data;
  };

  const queryResults = [];

  getCategoryList().forEach((category) => {
    const queryKey = ['todos', category, limitCardHome, testCount];
    const queryResult = useQuery(queryKey, () => fetchData(category, limitCardHome), {
      cacheTime: 0, // 6000000 caching time in milliseconds (in this case 60 seconds)
    });
    queryResults.push({state: queryResult, type: category});
  });

  const isLoading = queryResults.some((result) => result.state.isLoading);

  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    if (hasMounted) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'auto',
      });
    } else {
      setHasMounted(true);
    }
  }, [activeCategoryHomePage]);

  return (
    <div className={styles.container}>
      {isLoading ?
        <LoaderRelative />
        :
        queryResults.map((res) => (
          <CardsRenderBlock
            typeCards={res.type}
            cards={res ? res.state.data.data.data : []}
            isLoader={false}
            titleBlock={res.type}
          />
        ))
      }
    </div>
  );
};