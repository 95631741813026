import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { connect, useSelector } from 'react-redux';

// Static Images
import lc from "../../../assets/images/lc.png";
import Mileage from "../../../assets/images/Mileage.png";
import Bootspace from "../../../assets/images/Bootspace.png";
import BHP from "../../../assets/images/BHP.png";
import Engine from "../../../assets/images/Engine.png";
import Seats from "../../../assets/images/Seats.png";
import Transmission from "../../../assets/images/Transmission.png";
import user from "../../../assets/images/user.jpeg"

// Components
import ProductPrice from "../../../components/ProductPrice";
import Chat from "../../../components/Support/Chat";
import { CarAction, ReviewAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";
import GoogleMap from "../../../components/Map";

const Cardetail = ({ currency, reviewsByCategoryId, specificCar, listCars }) => {
  const { slug } = useParams();
  const location = useLocation();

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const { reviews } = useSelector((state) => state.reviews);
  const { car, carLoader, carsList } = useSelector((state) => state.car);

  const settings = {
    dots: false,
    arrow: true,
    speed: 500,
    slidesToScroll: 2,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
          arrow: false,
          slidesToShow: 1.07,
        },
      },
    ],
  };

  const state = location.state;

  const fetchCars = () => {
    const params = {
      slug, currency
    };

    specificCar(params);
  };

  const fetchData = () => {
    const params = {
      latitude: 25.0805,
      longitude: 55.1403
    };

    listCars(params);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchCars();
  }, [state]);


  useEffect(() => {
    if (car && car._id) {
      const params = { reviewCategoryId: car._id };
      reviewsByCategoryId(params);
    }

  }, [car]);

  return (
    <>
      {localStorage.getItem("accessToken") && car ? (
        <div className="chat-page">
          <Chat
            categoryId={car._id}
            categoryType="Car"
            receiverId={car && car.vendor_id && car.vendor_id._id}
            vendor={car.vendor_id}
          />
        </div>
      ) : null}
      {carLoader && <Loading />}
      <div className="product-detail-page">
        <div className="container">
          <div className="pagination">
            <ul className="mb-3">
              <li>
                <Link to="/properties">
                  <i className="fas fa-chevron-left"></i>
                </Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/carlisting">Car Listing</Link>
              </li>
              <li>
                <Link to="/cardetail" className="active">
                  Car Detail
                </Link>
              </li>
            </ul>
          </div>
          <div className="product-slider-main">
            <div className="main-sider">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                dots={true}
              >
                {car.images &&
                  car.images.map((slideimg, i) => (
                    <div key={i}>
                      <img src={slideimg} alt="" />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="side-sider">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div>
                  {car.images && car.images[0] ? <img src={car.images && car.images[0]} alt="" /> : false}
                </div>
                <div>
                  {car.images && car.images[1] ? <img src={car.images && car.images[1]} alt="" /> : false}
                </div>
                <div>
                  {car.images && car.images[2] ? <img src={car.images && car.images[2]} alt="" /> : false}
                </div>
              </Slider>

              <div className={car.images && car.images.length === 1 ? "more-hotos more-up" : "more-hotos"}>
                <p data-bs-toggle="modal" data-bs-target="#gallerymodal">
                  {car.imagesCount} Photos
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="slider-desc">
                <h4>
                  {car && car.name} hosted by{" "}
                  {car.vendor_id && car.vendor_id.firstName}{" "}
                  {car.vendor_id && car.vendor_id.lastName}
                  <label>
                    <label>{car && car.currencySymbol}</label>{" "}
                    {car && car.price}
                    <span>/day</span>
                  </label>
                </h4>
                <h5 className="amp-loc">
                  <img src={lc} alt="" />
                  {car && car.location}
                </h5>

                <div className="modals">
                  <ul>
                    <li>
                      <span>{car && car.model}</span>
                      <b>Modal</b>
                    </li>
                    <li>
                      <span>{car && car.fuel}</span>
                    </li>
                    <li>
                      <span>{car && car.category}</span>
                    </li>
                  </ul>
                </div>
                <h6>Description</h6>

                <p>{car && car.description}</p>
                <h6>Car category</h6>
                <ul className="list-gyr ">
                  <li className="list-s-none">
                    {car.category_id && car.category_id.name}
                  </li>
                  <ul>
                    {car.subCategory_ids &&
                      car.subCategory_ids.map((subCat, index) => (
                        <li key={index}>- {subCat.name}</li>
                      ))}
                  </ul>
                </ul>
                <h6>Ammenities</h6>
                <ul className="amnites aminity-wh-img">
                  <li>
                    <img src={Mileage} alt="" />

                    {car && car.features && car.features.mileage}
                  </li>
                  <li>
                    <img src={Transmission} alt="" />
                    {car && car.features && car.features.transmission}
                  </li>
                  <li>
                    <img src={Seats} alt="" />
                    {car && car.features && car.features.seats}
                  </li>
                  <li>
                    <img src={Engine} alt="" />
                    {car && car.features && car.features.engine}
                  </li>
                  <li>
                    <img src={BHP} alt="" />
                    {car && car.features && car.features.bhp}
                  </li>
                  <li>
                    <img src={Bootspace} alt="" />
                    {car && car.features && car.features.boot_space}
                  </li>
                </ul>
                <h6>View on map</h6>
                <div id="map-show" className="map">
                  <GoogleMap positionalLat={car.latitude} positionalLong={car.longitude} />
                </div>
                <h5 className="amp-loc">
                  <img src={lc} alt="" />
                  {car && car.location}
                </h5>


              </div>
            </div>
            <div className="col-md-3">
              {car && car.features && car.features.seats && (
                <ProductPrice state={car} data="car" seats={car.features.seats} />
              )}
            </div>
          </div>
        </div>


        <div className="reviewlist">
          <div className="container">
            {car.ratingQuantity && car.ratingQuantity ? (
              <h4>
                <i className="fas fa-star"></i> {car.ratingAverage} .{" "}
                {car.ratingQuantity} reviews
              </h4>
            ) : (
              <h4>No Review Yet</h4>
            )}
            <div className="review-lising">
              <div className="row">
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Comfort Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.comfortRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.comfortRatingAverage ? car.comfortRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Exterior Styling Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.exteriorStylingRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.exteriorStylingRatingAverage ? car.exteriorStylingRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Interior Design Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.interiorDesignRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.interiorDesignRatingAverage ? car.interiorDesignRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Performance Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.performanceRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.performanceRatingAverage ? car.performanceRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Reliability Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.reliabilityRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.reliabilityRatingAverage ? car.reliabilityRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="review-lit-iner">
                    <h5>Value Money Rating</h5>
                    <div className="ranger-review">
                      <span><label style={{
                        width: `${car.valueMoneyRatingAverage * 20}%`,
                      }}></label></span>
                      <h6>{car.valueMoneyRatingAverage ? car.valueMoneyRatingAverage : 0}</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ul>
              {reviews && reviews.slice(0, 4).map((listData, index) => (
                <li key={index}>
                  <div className="review-image">
                    {listData.image ? <img src={listData.image} alt="" /> :
                      <img src={user} alt="" />}
                    <div className="date-create">
                      <h5>
                        {listData.firstName} {listData.lastName}
                      </h5>
                      <h6>{new Date(listData.createdAt).toDateString()}</h6>

                    </div>

                  </div>

                  <p>{listData.review}</p>
                </li>
              ))}
              {reviews && reviews.length > 4 && <div className="show-more">
                <span data-bs-toggle="modal" data-bs-target="#exampleModal">Show all {reviews.length} Reviews</span>
              </div>}
            </ul>
          </div>
        </div>

        <div className="you-like-slider">
          <div className="container">
            <h3>You may also like</h3>
            <div className="inn-th-slide">
              <Slider {...settings}>
                {carsList.map((cardata, i) => (
                  <div
                    className="col-md-4 wow bounceIn"
                    data-wow-delay="0.2s"
                    key={i}
                  >
                    <Link state={cardata} to={`/cardetail/${cardata.slug}`}>
                      <div className="inner-propery">
                        <div className="sale-btn">
                          <span>
                            Book Now
                          </span>
                          <img src={cardata.images[0]} alt="carone" />
                        </div>
                        <h5>{cardata.name}</h5>
                        <div className="modals">
                          <ul>
                            <li>
                              <span>{cardata.model}</span>
                              <b>Modal</b>
                            </li>
                            <li>
                              <span>{cardata.fuel}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="car-details">
                          <div>
                            <span>Mileage (upto)</span>
                            <h6>{cardata.features.mileage}Km</h6>
                          </div>
                          <div>
                            <span>Transmission</span>
                            <h6>{cardata.fuel}</h6>
                          </div>
                          <div>
                            <span>Engine</span>
                            <h6>{cardata.features.engine} cc</h6>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="my-gallary">
          <div
            className="modal fade"
            id="gallerymodal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modal-images">
                    {car.images &&
                      car.images.map((slideimg, i) => (
                        <div key={i}>
                          <img src={slideimg} alt="" />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  reviewsByCategoryId: (params) => dispatch(ReviewAction.reviewsByCategoryId(params)),
  specificCar: (params) => dispatch(CarAction.specific(params)),
  listCars: (params) => dispatch(CarAction.list(params)),
});

export default connect(null, mapDispatchToProps)(Cardetail);
