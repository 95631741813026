import { API_ACTIONS } from "..";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_ALL_PROPERTIES });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/user/home`, { params });

		if (params.type === CONSTANTS.RENTAL_TYPE.CAR) {
			dispatch({ type: API_ACTIONS.LIST_ALL_CARS_SUCCESS, data: response.data.data.data });
		}
		else {
			let isFilteredData = !!params.categoryId;

			if(isFilteredData) dispatch({ type: API_ACTIONS.SET_CATEGORY_FILTERED_LOADER, loader: true });

			dispatch({
				type: API_ACTIONS.LIST_ALL_RENTAL_TYPE_SUCCESS,
				properties: response.data.data.data,
				count: response.data.data.count,
				offset: params.page,
				isFilteredData
			});
		}
	}
	catch (err) {
		if (params.type === CONSTANTS.RENTAL_TYPE.CAR) {
			dispatch({ type: API_ACTIONS.LIST_ALL_CARS_ERROR })
		}
		else {
			dispatch({ type: API_ACTIONS.LIST_ALL_RENTAL_TYPE_ERROR });
		}
	}
};

const details = (params) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_PROPERTY });

		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/property/details`, { params });

		dispatch({ type: API_ACTIONS.SPECIFIC_PROPERTY_SUCCESS, data: response.data.data });
	}
	catch (err) {
		dispatch({ type: API_ACTIONS.REQUEST_SPECIFIC_PROPERTY_ERROR });
	}
};

const allProperties = (params) => async(dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/property/all/properties`, { params });

		dispatch({ type: API_ACTIONS.LIST_ALL_PROPERTIES_SUCCESS, data: response.data.data.nearByProperties });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_ALL_PROPERTIES_ERROR });
	}
};

export { list, details, allProperties };