import React, {useEffect} from 'react';
import styles from './CardsRenderBlock.module.css';
import Loader from "react-js-loader";
import { CardHome } from "./CardHome/CardHome";
import { CONSTANTS } from "../../utils";
import { useWindowWidth } from "../../hooks/useWindowWidth";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import {NavLink, useNavigate} from "react-router-dom";
import {MainButton} from "../Common/Buttons/MainButton/MainButton";

export const CardsRenderBlock = ({
                                   typeCards,
                                   cards = [],
                                   isLoader = true,
                                   titleBlock = 'titleBlock',
                                 }) => {

  const windowWidth = useWindowWidth();
  const navigate = useNavigate();

  const renderDescription = (item) => {
    if (typeCards === CONSTANTS.RENTAL_TYPE.CAR) return item.description;
    if (typeCards === CONSTANTS.RENTAL_TYPE.PROPERTY) return item.location;
    if (typeCards === CONSTANTS.RENTAL_TYPE.TOUR) return item.description;
  };

  const pathClickMainButton = `/listings?type=${(typeCards.toLowerCase())}`;

  const getTitleBlock = (titleBlock) => {
    if (titleBlock === CONSTANTS.RENTAL_TYPE.CAR) return 'Sports & Luxury Car Rentals';
    if (titleBlock === CONSTANTS.RENTAL_TYPE.PROPERTY) return 'Rent Luxury Homes Across Dubai';
    if (titleBlock === CONSTANTS.RENTAL_TYPE.TOUR) return 'Best Tours & Attractions In Dubai';
  }

  return isLoader ? <LoaderRelative /> : (
    <div className={styles.container}>
      <h4>{getTitleBlock(titleBlock)}</h4>
      {windowWidth > 767 ?
        <div className={styles.wrapper}>
          <div className={styles.block}>
            {cards.map((item, index) => (
              <CardHome
                key={index}
                image={item.images && item.images.length > 0 && item.images[0]}
                title={item.name}
                link={`/list?type=${typeCards.toLowerCase()}&slug=${item.slug}`}
                description={renderDescription(item)}
                contextType={typeCards}
                indexAnimate={index}
                carSpecifications={{
                  mileage: item.features ? item.features.engine : '',
                  transmission: item.fuel ? item.fuel : '',
                  engine: item.features ? item.features.mileage : '',
                }}
              />
            ))}
          </div>
          <MainButton
            title={'View All'}
            callback={() => navigate(pathClickMainButton)}
            hoverSize={windowWidth > 767}
            styleContainer={{
              width: 'max-content',
              margin: '0 auto',
              marginTop: '50px'
            }}
            style={{
              padding: '10px 60px'
            }}
            background={windowWidth > 767 ? "#0095DB" : 'white'}
            styleTitle={{
              whiteSpace: 'nowrap',
              color: 'white',
              fontSize: '70%'
            }}
            animated={false}
          />
        </div>
        :
        <div className={styles['wrapper-mobil']}>
          <NavLink
            to={pathClickMainButton}
            className={styles['arrow-mobil']}
          >
            <p>View All</p>
            <span>ᐳ</span>
          </NavLink>
          <Swiper
            slidesPerView={1.2}
            spaceBetween={'5%'}
            className={styles['swiper-container-mobil-home']}
          >
            {cards.map((item, index) => (
              <SwiperSlide key={index}>
                <CardHome
                  image={item.images && item.images.length > 0 && item.images[0]}
                  title={item.name}
                  link={`/list?type=${typeCards.toLowerCase()}&slug=${item.slug}`}
                  description={renderDescription(item)}
                  contextType={typeCards}
                  indexAnimate={index}
                  carSpecifications={{
                    mileage: item.features ? item.features.engine : '',
                    transmission: item.fuel ? item.fuel : '',
                    engine: item.features ? item.features.mileage : '',
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      }
    </div>
  );
};

export const LoaderRelative = () => {
  return (
    <div className={styles['loader-container']}>
      <Loader type="loop" bgColor="#0095DB" color="#0095DB" size={40} />
    </div>
  );
};
