import { useState, useEffect } from 'react';
import { modifyUrlParameters } from "../components/CardsRenderBlock/CardHome/CarSpecificationsBlock/resizeImages";

export const useProgressiveImageLoader = (imageUrl, qualities, screenWidth) => {
  const [imageCache, setImageCache] = useState({});
  const [currentImageSrc, setCurrentImageSrc] = useState(null);

  useEffect(() => {
    const loadImages = async () => {
      for (let i = 0; i < qualities.length; i++) {
        const currentQualities = qualities[i];
        const newImageUrl = modifyUrlParameters(imageUrl, currentQualities, screenWidth);

        if (imageCache[newImageUrl]) {
          setCurrentImageSrc(newImageUrl); // Загружаем из кэша, если уже загружено
        } else {
          const img = new Image();
          img.src = newImageUrl;

          await img.decode();

          imageCache[newImageUrl] = true; // Кэшируем изображение
          setImageCache({ ...imageCache });

          if (i === qualities.length - 1) {
            setCurrentImageSrc(newImageUrl);
          }
        }
      }
    };

    if (imageUrl) {
      setCurrentImageSrc(null); // Устанавливаем null при смене фотографии
      loadImages();
    }
  }, [imageUrl, qualities, screenWidth]);

  return currentImageSrc;
};
