import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// CSS
import "./Header.css";

import { DEFAULT_ACTIONS } from "../../redux/actions";
import { CONSTANTS, IMAGES } from "../../utils";
import {useWindowWidth} from "../../hooks/useWindowWidth";

const Header = () => {
  const dispatch = useDispatch();

  const { authenticated } = useSelector((state) => state.auth);
  const { profile } = useSelector((state) => state.user);

  const logoutHandler = () => {
    localStorage.clear();
    setLogin(false);
  };

  const setLogin = (status) => {
    dispatch({
      type: DEFAULT_ACTIONS.SET_AUTHENTICATED_USER,
      authenticated: status,
    });
  };

  const color = useLocation().pathname === "/" ? "#fff" : "#0095DB";
  const windowWidth = useWindowWidth();

  const style = {
    anchor_text: {
      color: color,
      fontWeight: windowWidth > 767 ? 400 : 900,
      fontSize: windowWidth > 767 ? '130%' : '140%',
      // fontFamily: 'Rollgates, sans-serif',
      letterSpacing: '2px',
      transform: windowWidth > 767 ? '' : 'translateY(3px)'
    },
  };

  const background = useLocation().pathname === "/" ? "transparent" : "white";

  const handleLoginModal = () => dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_MODAL, status: true });

  const handleSignUpModal = () => dispatch({ type: DEFAULT_ACTIONS.SET_SIGNUP_MODAL, status: true });

  useEffect(() => {
    if (localStorage.getItem(CONSTANTS.ACCESS_TOKEN)) {
      dispatch({
        type: DEFAULT_ACTIONS.SET_AUTHENTICATED_USER,
        authenticated: true,
      });
    }
  }, []);


  return (
    <div className="headerpage" style={{ background: background }}>
      <div className="container container-additionally">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="container-fluid">
            <a style={style.anchor_text} className="navbar-brand" href="/">
            CBRent
            </a>
            {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0" style={style.anchor_text}>
                <li><Link style={style.anchor_text} to="/">Home</Link></li>
                <li><Link style={style.anchor_text} to="properties">Apartments</Link></li>
                <li><Link style={style.anchor_text} to="properties">Property Listing</Link></li>
                <li><Link style={style.anchor_text} to="/">Tours</Link></li>
              </ul>
            </div> */}
            <div
              className="log-btn"
            >
              {!authenticated && (
                <span>
                  <Link
                    style={{
                      background: "#0095DB",
                      padding: useLocation().pathname === "/" ? "13px 20px 10px" : "0",
                      color: "white",
                      borderRadius: useLocation().pathname === "/" ? "10px 10px 10px 10px" : "0",
                    }}
                    to="/" onClick={handleLoginModal}
                  >
                    Sign In
                  </Link>
                </span>
              )}
              {!authenticated && (
                <span>
                  <Link
                    style={{
                      background: "#0095DB",
                      margin: useLocation().pathname === "/" ? "2px" : "0",
                      padding: useLocation().pathname === "/" ? "13px 18px 10px" : "0",
                      color: "white",
                      borderRadius: useLocation().pathname === "/" ? "10px 10px 10px 10px" : "0",
                    }}
                    to="/" onClick={handleSignUpModal}
                  >
                    Sign Up
                  </Link>
                </span>
              )}
              {authenticated && (
                <>
                  {/* <span onClick={openProfile}>
                    <img src={profile} alt="" />
                  </span> */}
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img src={profile && profile.image ? profile.image : IMAGES.USER_PROFILE} alt="" />
                    </button>

                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <Link to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`}>My Profile</Link>
                      </li>
                      <li>
                        <Link to="/" onClick={logoutHandler}>
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </div>
          </div>
        </nav>
      </div>
      {/* {statevalue && (
        <ProductPrice data="login" setLogin={setLogin} home={true} />
      )} */}
      <div className={"row"}>
        <div className={"item"}>

        </div>
      </div>
    </div>
  );
}


export default Header;
