import { createSlice } from '@reduxjs/toolkit';
import {CONSTANTS} from "../../utils";

const initialState = {
  activeCategoryHomePage: CONSTANTS.RENTAL_TYPE.PROPERTY,
  isRenderProperty: false,
  dataHomeDashboard: {
    location: '',
    startDate: null,
    endDate: null,
    latitude: null,
    longitude: null,
  }
};

const homePage = createSlice({
  name: 'homePage',
  initialState,
  reducers: {
    setActiveCategoryHomePage (state, action) {
      const {activeCategoryHomePage} = action.payload;
      state.activeCategoryHomePage = activeCategoryHomePage;
    },
    setIsRenderProperty (state, action) {
      const {isRenderProperty} = action.payload;
      state.isRenderProperty = isRenderProperty;
    },
    setDataHomeDashboardLocation (state, action) {
      const {location} = action.payload;
      state.dataHomeDashboard.location = location;
    },
    setDataHomeDashboardStartDate (state, action) {
      const {startDate} = action.payload;
      state.dataHomeDashboard.startDate = startDate;
    },
    setDataHomeDashboardEndDate (state, action) {
      const {endDate} = action.payload;
      state.dataHomeDashboard.endDate = endDate;
    },
  },
});

export const {
  setActiveCategoryHomePage,
  setIsRenderProperty,
  setDataHomeDashboardLocation,
  setDataHomeDashboardStartDate,
  setDataHomeDashboardEndDate
} = homePage.actions;

export default homePage.reducer;
