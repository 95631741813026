import { Modal } from "react-bootstrap";
import React from "react";
import "./ModalPopup.css";

function ModalPopup(props) {
  return (
    <Modal
      show={props.show}
      className={props.dialogClassName}
      onHide={props.onHide}
    >
      <Modal.Header className="modal-header" closeButton>
        <h5 className="modal-title fw-bold" id="expeditLabel">
          {props.title}
        </h5>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <div className="deadline-form">{props.component}</div>
      </Modal.Body>
    </Modal>
  );
}

export default ModalPopup;
