import React from 'react';
import styles from './SwiperList.module.css';
import { EffectCreative, Pagination, Autoplay} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {useState} from "react";
import './SwiperCssList.css';
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import swiperListMainCard_icon from '../../../assets/sizeCards/swiperListMainCard.png';
import swiperListLittleCard_icon from '../../../assets/sizeCards/swiperListLittle.png';
import {modifyUrlParameters} from "../../../components/CardsRenderBlock/CardHome/CarSpecificationsBlock/resizeImages";
import './paginationStyle.css';
import {ZoomBlock} from "./ZoomBlock/ZoomBlock";


export const SwiperList = ({data}) => {
  const photos = data;
  const windowWidth = useWindowWidth();

  const quality =  windowWidth > 767 ? 90 : 60;
  const width =  windowWidth > 767 ? 800 : 500;

  const [fullscreenIsActive, setFullscreenIsActive] = useState(false);
  const [initialFullSlide, setInitialFullSlide] = useState(0);
  const handleSlideClick = (index) => setInitialFullSlide(index);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.swiperMain}>
          <Swiper
            loop={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: true,
            }}
            pagination={{
              clickable: true,
            }}
            effect={'creative'}
            creativeEffect={{
              prev: {
                shadow: true,
                translate: [0, 0, -100],
              },
              next: {
                translate: ['100%', 0, 0],
              },
            }}
            modules={[EffectCreative, Pagination, Autoplay]}
            className='main-swiper-list_pag'
          >
            {
              photos.map((el, index) => (
                <SwiperSlide
                  onClick={() => {
                    handleSlideClick(index)
                    setTimeout(() => setFullscreenIsActive(true), 0)
                  }}
                  className={styles.slickMain}
                  key={index}
                >
                  <img
                    className={styles.slickMainBack}
                    src={swiperListMainCard_icon}
                    alt={swiperListMainCard_icon}
                  />
                  <div className={styles['glare-wrapper']}>
                    <div className={styles['glare-item']}></div>
                  </div>
                  <img
                    className={styles.slickMainFrontBlur}
                    src={modifyUrlParameters(el, quality, width)}
                    alt={el}
                  />
                  <img
                    className={styles.slickMainFront}
                    src={modifyUrlParameters(el, quality, width)}
                    alt={el}
                  />
                </SwiperSlide>
              ))
            }
          </Swiper>
        </div>
        <div className={styles.swiperLittle}>
          {photos.map((el, index) => index < 3 && (
            <button onClick={() => {
              handleSlideClick(index === 2 ? 0 : index);
              setFullscreenIsActive(true)
            }}>
              <img
                className={styles.swiperListLittleCard}
                src={swiperListLittleCard_icon}
                alt={swiperListLittleCard_icon}
              />
              <div className={styles['glare-wrapper']}>
                <div className={styles['glare-item']}></div>
              </div>
              <img
                className={styles.swiperListLittleCardFrontBlur}
                src={modifyUrlParameters(el, 90, 400)}
                alt={el}
              />
              <img
                className={styles.swiperListLittleCardFront}
                src={modifyUrlParameters(el, 90, 400)}
                alt={el}
              />
              {index === 2 &&
                <div className={styles.moreBlock}>
                  <p>Show all ({photos.length})</p>
                </div>
              }
            </button>
          ))}
        </div>
      </div>
      <ZoomBlock
        photos={photos}
        initialFullSlide={initialFullSlide}
        fullscreenIsActive={fullscreenIsActive}
        setFullscreenIsActive={setFullscreenIsActive}
      />
    </div>
  );
};