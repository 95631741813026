import React, { useState, useEffect } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import Slider from "react-slick";
import { connect, useSelector } from 'react-redux';
import GoogleMap from '../../../components/Map';

// CSS
import "./Productdetail.css";

// Static Images
import lc from "../../../assets/images/lc.png";
import user from "../../../assets/images/user.jpeg";

// Components
import ProductPrice from "../../../components/ProductPrice";
import Chat from "../../../components/Support/Chat";
import { PropertyAction, ReviewAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";
import { CONSTANTS } from "../../../utils";

const Productdetail = ({ listAll, reviewsByCategoryId, specificProperty }) => {
  const location = useLocation();
  const { slug } = useParams();

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  const { property: { property, properties, propertyLoader }, auth: { currency } } = useSelector((state) => state);
  const { reviews } = useSelector((state) => state.reviews);

  const fetchAllProperties = async () => {
    const params = { limit: 8, page: 0 };
    listAll(params);
  };

  const fetchProperty = () => {
    const params = { slug, currency };
    specificProperty(params)
  };

  const settings = {
    dots: false,
    arrow: true,
    speed: 500,
    slidesToScroll: 2,
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          dots: false,
          arrow: false,
          slidesToShow: 1.07,
        },
      },
    ],
  };

  useEffect(() => {
    fetchAllProperties();
  }, []);

  useEffect(() => {
    if(location.state && currency) {
      fetchProperty();
    }
  }, [location.state, currency]);

  useEffect(() => {
    if (property && property._id) {
      const params = { reviewCategoryId: property._id };
      reviewsByCategoryId(params);
    }
  }, [property]);

  return (
    <>
      {propertyLoader && <Loading />}
      {localStorage.getItem(CONSTANTS.ACCESS_TOKEN) && property ? (
        <div className="chat-page">
          <Chat
            categoryId={property._id}
            categoryType="Property"
            receiverId={property && property.vendor_id && property.vendor_id._id}
            vendor={property.vendor_id}
          />
        </div>
      ) : null}
      <div className="product-detail-page main-pro-detial">
        <div className="container">
          <div className="pagination">
            <ul className="mb-3">
              <li>
                <Link to="/properties">
                  <i className="fas fa-chevron-left"></i>
                </Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/properties">Property Listing</Link>
              </li>
              <li>
                <Link to={`/property/${slug}`} className="active">
                  Property Detail
                </Link>
              </li>
            </ul>
          </div>
          <div className="product-slider-main">
            <div className="main-sider">
              <Slider
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
                dots={true}
              >
                {property.images &&
                  property.images.map((slideimg, i) => (
                    <div key={i}>
                      <img src={slideimg} alt="" />
                    </div>
                  ))}
              </Slider>
            </div>
            <div className="side-sider">
              <Slider
                asNavFor={nav1}
                ref={(slider2) => setNav2(slider2)}
                slidesToShow={3}
                swipeToSlide={true}
                focusOnSelect={true}
              >
                <div>
                  {property.images && property.images[0] ? <img src={property.images && property.images[0]} alt="" /> : false}
                </div>
                <div>
                  {property.images && property.images[1] ? <img src={property.images && property.images[1]} alt="" /> : false}
                </div>
                <div>
                  {property.images && property.images[2] ? <img src={property.images && property.images[2]} alt="" /> : false}
                </div>
              </Slider>

              <div className={property.images && property.images.length === 1 ? "more-hotos more-up cursor-pointer" : "more-hotos cursor-pointer"} >
                <p data-bs-toggle="modal" data-bs-target="#gallerymodal">
                  {property.imagesCount} Photos
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-9">
              <div className="slider-desc">
                <h4>
                  {property && property.name} hosted by{" "}
                  {property.vendor_id && property.vendor_id.firstName}{" "}
                  {property.vendor_id && property.vendor_id.lastName}
                </h4>
              </div>
              <h5 className="amp-loc">
                <img src={lc} alt="" />
                {property && property.buildingId && property.buildingId.name},  {property && property.areaId && property.areaId.name}, {property && property.city_id && property.city_id.name}
              </h5>
              <div className="aminities-sec">
                <ul className="amnites">
                  <li>
                    {property.personCapacity && property.personCapacity} Guest{" "}
                  </li>
                  <li>{property.bedrooms && property.bedrooms} Bedroom</li>
                  <li>{property.bathrooms && property.bathrooms} Bathroom</li>
                  <li>{property.beds && property.beds} Beds</li>
                </ul>
                <span data-bs-toggle="modal" data-bs-target="#moreData"><i className="fas fa-info-circle"></i></span>
              </div>
              <hr></hr>
              <h6 className="pt-2">Description</h6>

              <p>{property && property.description}</p>
              <h6>Property category</h6>
              <ul className="list-gyr list-dot">
                <li className="list-s-none">
                  {property.category_id && property.category_id.name}
                </li>
                <ul>
                  {property.subCategory_ids &&
                    property.subCategory_ids.map((subCat, index) => (
                      <li key={index}>- {subCat.name}</li>
                    ))}
                </ul>
              </ul>
              <h6>Ammenities</h6>
              <ul className="amnites aminity-wh-img">
                {property.amenities &&
                  property.amenities.map((aminit, key) => (
                    <li key={key}>
                      <img src={aminit.icon} alt="" />
                      {aminit.title}
                    </li>
                  ))}
              </ul>
              <h6>View on map</h6>
              <div id="map-show" className="map">
                <GoogleMap positionalLat={property.latitude} positionalLong={property.longitude} />
              </div>
              <h5 className="amp-loc">
                <img src={lc} alt="" />
                {property && property.location}
              </h5>

            </div>
            <div className="col-md-3">
              {property && property._id && (
                <ProductPrice
                  currency={currency}
                  currencySymbol={property && property.currencySymbol}
                  state={property}
                  data="property"
                  _id={property._id}
                />
              )}
            </div>
          </div>
          <div className="reviewlist">

            <div className="reviewlist">
              {property.ratingQuantity && property.ratingQuantity ? (
                <h4>
                  <i className="fas fa-star"></i> {property.ratingAverage} {" "}
                  reviews
                </h4>
              ) : (
                <h4>No Review Yet</h4>
              )}
              <div className="review-lising">
                <div className="row">
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Cleaniliness</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.cleanlinessRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.cleanlinessRatingAverage ? property.cleanlinessRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Accuracy</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.accuracyRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.accuracyRatingAverage ? property.accuracyRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Communication</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.communicationRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.communicationRatingAverage ? property.communicationRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Location</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.locationRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.locationRatingAverage ? property.locationRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Check-in</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.checkInRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.checkInRatingAverage ? property.checkInRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="review-lit-iner">
                      <h5>Value</h5>
                      <div className="ranger-review">
                        <span><label style={{
                          width: `${property.valueRatingAverage * 20}%`,
                        }}></label></span>
                        <h6>{property.valueRatingAverage ? property.valueRatingAverage : 0}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ul>
                {reviews &&
                  reviews.slice(0, 4).map((listData, key) => (
                    <li key={key}>
                      <div className="review-image">
                        {listData.image ? <img src={listData.image} alt="" /> :
                          <img src={user} alt="" />}
                        <div className="date-create">
                          <h5>
                            {listData.firstName} {listData.lastName}
                          </h5>
                          <h6>{new Date(listData.createdAt).toDateString()}</h6>

                        </div>

                      </div>

                      <p>{listData.review}</p>
                    </li>
                  ))}
                {reviews && reviews.length > 4 && <div className="show-more">
                  <span data-bs-toggle="modal" data-bs-target="#exampleModal">Show all {reviews.length} Reviews</span>
                </div>}
              </ul>
            </div>
          </div>
        </div>
        <div className="you-like-slider">
          <div className="container">
            <h3>You may also like</h3>
            <div className="inn-th-slide">
              <Slider {...settings}>
                {properties.map((property, i) => (
                  <div className="col-md-4" datawow-delay="0.2s" key={i}>
                    <Link
                      to={`/property/${property.slug}`}
                      state={property}
                    >
                      <div className="inner-propery">
                        <div className="sale-btn">
                          <span>Book Now</span>
                          <img src={property.images[0]} alt="propertyone" />
                        </div>
                        <h5>{property.name}</h5>
                        <p>{property.location}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
        <div className="my-gallary">
          <div
            className="modal fade"
            id="gallerymodal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  <div className="modal-images">
                    {property.images &&
                      property.images.map((slideimg, i) => (
                        <div key={i}>
                          <img src={slideimg} alt="" />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">All Reviews</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="reviewlist">
                  <ul>
                    {reviews &&
                      reviews.map((listData, index) => (
                        <li key={index}>
                          <div className="review-image">
                            {listData.image ? <img src={listData.image} alt="" /> :
                              <img src={user} alt="" />}
                            <div className="date-create">
                              <h5>
                                {listData.firstName} {listData.lastName}
                              </h5>
                              <h6>{new Date(listData.createdAt).toDateString()}</h6>

                            </div>

                          </div>

                          <p>{listData.review}</p>
                        </li>
                      ))}

                  </ul>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="moreData" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog bathroom-modal">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Property Detail</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="bathroom-data">
                <h4>Bedroom Detail</h4>
                {property.bedrooms && property.bedRoomData.map((item, index) =>
                  <div className="inner-bath-list" key={index}>
                    <h6>Bedroom {item.bedRoomNumber}</h6>
                    {item.bedData && item.bedData.map((listbath, innerIndex) =>
                      <div className="bad-name" key={innerIndex}>
                        <p><label>Bed Type</label><span>{listbath.bedName}</span></p>
                        <p><label>Number of Beds</label><span>{listbath.numberOfBeds}</span></p>
                        {item.attachedBathroom ? <span className="attach-bath">Bathroom Attach</span> : null}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="bathroom-data">
                <h4>Bathroom Detail</h4>
                {property.bathRooms && property.bathRooms.map((item, index) =>
                  <div className="inner-bath-list" key={index}>
                    <h6>Bathroom {index + 1}</h6>
                    <div className="appartments">
                      {!item.bathRoomPrivate && !item.bathRoomEnsuite && !item.insideAppartment ? <p className="bath_tag open-list">Public</p> : null}
                      {item.bathRoomPrivate ? <p className="bath_tag private">Private</p> : null}
                      {item.bathRoomEnsuite ? <p className="bath_tag ensuit">Ensuite</p> : null}
                      {item.insideAppartment ? <p className="bath_tag inside">Inside Appartment</p> : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  listAll: (params) => dispatch(PropertyAction.allProperties(params)),
  reviewsByCategoryId: (params) => dispatch(ReviewAction.reviewsByCategoryId(params)),
  specificProperty: (params) => dispatch(PropertyAction.details(params)),
});

export default connect(null, mapDispatchToProps)(Productdetail);
