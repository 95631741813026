import React from 'react';
import styles from './ServicesOfferedAdvertising.module.css';
import carRental_image from './../../assets/ServicesOfferedAdvertising/carRental.png';
import mask_group from './../../assets/ServicesOfferedAdvertising/mask-group.png';
import mask_group_as from './../../assets/ServicesOfferedAdvertising/mask-group-as.png';
import mask_group_car from './../../assets/ServicesOfferedAdvertising/mask-group-car.png';
import luxuryRentalImage from './../../assets/ServicesOfferedAdvertising/luxury-rental.png';
import rentalProperty_image from './../../assets/ServicesOfferedAdvertising/rentalProperty.png';
import touring_image from './../../assets/ServicesOfferedAdvertising/touring.png';
import oneRight from '../../assets/icons/HomePartFooter/one.png';
import {BackGroundSVG} from "./BackGroundSVG";
import {useRef} from "react";
import {useAnimationOnInView} from "../../hooks/useAnimationOnInView";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import { CONSTANTS } from "../../utils";
import { connect, useDispatch, useSelector } from 'react-redux';


export const ServicesOfferedAdvertising = () => {

  const CONTAINER = useRef();
  const isInView = useAnimationOnInView(CONTAINER, '-40%');
  const windowWidth = useWindowWidth();
  const activeCategoryHomePage = useSelector(state => state.homePage.activeCategoryHomePage);

  if (CONSTANTS.RENTAL_TYPE.PROPERTY === activeCategoryHomePage) 
  return (
    <div className={styles.container} ref={CONTAINER}>
      <BackGroundSVG />
      <div className={styles.wrapper}>
        <div className={styles['experiencesBlock']}>
          <div className='container'>
            <div className='heading text-center col-md-8 mx-auto mb-5'> 
              <h2 className='mb-4'>Curating Unparalelled Luxury Experiences</h2>
              <p>At CB-Rent, we curate unforgettable experiences for stays, for tours and for riding your favourite car. With just a few clicks you can book luxury properties with exquisite views in Dubai along with best in class services at the property</p>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <div className={styles['image']}>
                  <img className={styles['luxury-rental-image']} src={luxuryRentalImage} alt={luxuryRentalImage}/>
                </div>
              </div>
              <div className='col-md-6'>
                  <div className={styles['text']}>
                    <div className=''>
                        <div className={styles['item']}>
                        <div className={styles['itemHeading']}>
                            <img src={mask_group} />
                            <h3>Luxury Property Rental</h3>
                          </div>
                          <div className={styles['itemDescription']}>
                            <p>Explore top rental apartments in Dubai. Furnished options with all amenities await. Rent furnished apartments in Dubai. Modern design, comfort & convenience await you.</p>
                          </div>
                        </div>

                        <div className={styles['item']}>
                          <div className={styles['itemHeading']}>
                            <img src={mask_group_as} />
                            <h3>Car Rental</h3>
                          </div>
                          <div className={styles['itemDescription']}>
                            <p>Explore top rental apartments in Dubai. Furnished options with all amenities await. Rent furnished apartments in Dubai. Modern design, comfort & convenience await you.</p>
                          </div>
                        </div>

                        <div className={styles['item']}>
                          <div className={styles['itemHeading']}>
                          <img src={mask_group_car} />
                            <h3>Events</h3>
                          </div>
                          <div className={styles['itemDescription']}>
                            <p>Explore top rental apartments in Dubai. Furnished options with all amenities await. Rent furnished apartments in Dubai. Modern design, comfort & convenience await you.</p>
                          </div>
                        </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>


    
  );
};

const rightPartData = [
  {
    img: rentalProperty_image,
    h4: 'Rental Property',
    content: 'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.',
    to: '/',
  },
  {
    img: carRental_image,
    h4: 'Car Rental',
    content: 'Distracted by the readable content of a page when looking at and some of the random text its layout.',
    to: '/',
  },
  {
    img: touring_image,
    h4: 'Touring',
    content: 'It is a long established fact a reader will be some a random distracted by the readablity content just to add another line to the additional of a page when looking.',
    to: '/',
  },
];
