import { API_ACTIONS } from "../actions";

const initialState = {
  properties: [],
  propertyLoader: false,
  hasMoreProperty: true,
  count: 0,
  offset: 0,
  limit: 9,
  property: {},
};

const propertyReducer = (state = initialState, action) => {
  if (action.type === API_ACTIONS.REQUEST_ALL_PROPERTIES) {
    return {
      ...state,
      propertyLoader: true,
    };
  }
  else if (action.type === API_ACTIONS.LIST_ALL_RENTAL_TYPE_SUCCESS) {
    let hasMoreProperty = action.properties.length === 0 ? false : true;
    let properties = [];

    if(typeof action.isFilteredData === 'boolean' && action.isFilteredData) {
      properties = action.properties;
    }
    else {
      properties = action.offset === 0 ? action.properties : [...state.properties, ...action.properties];
    }

    return {
      ...state,
      properties: properties,
      count: action.count,
      propertyLoader: false,
      hasMoreProperty,
      offset: action.offset
    };
  }
  else if (action.type === API_ACTIONS.REQUEST_SPECIFIC_PROPERTY) {
    return {
      ...state,
      propertyLoader: true,
    };
  }
  else if (action.type === API_ACTIONS.SPECIFIC_PROPERTY_SUCCESS) {
    const bedrooms = action.data.bedRoomData;
    let bathrooms = action.data.bathRooms;

    for (const item of bedrooms) {
      const attachedBathroom = bathrooms.find(bathroomItem => bathroomItem.bedRoomNumber === item.bedRoomNumber);

      if (attachedBathroom) item.attachedBathroom = attachedBathroom;
    }

    bathrooms = bathrooms.filter(item => !item.bedRoomNumber);

    return {
      ...state,
      property: { ...action.data, bedRoomData: bedrooms, bathRooms: bathrooms },
      propertyLoader: false,
    };
  }
  else if(action.type === API_ACTIONS.LIST_ALL_PROPERTIES_SUCCESS) {
    return {
      ...state,
      properties: action.data,
    };
  }
  return state;
};

export default propertyReducer;