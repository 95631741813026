import React, { useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";

// Static Images
import lc from "../../../assets/images/lc.png";

// Components
import Chat from "../../../components/Support/Chat";
import { BookingAction, DEFAULT_ACTIONS, ReviewAction } from "../../../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { CONSTANTS } from "../../../utils";
import { Fragment } from "react";
import withAuth from "../../../hoc/withAuth";

const bookingTypes = {
  TOUR: "tourId",
  CAR: "car_id",
  PROPERTY: "property_id",
};

const Bookingdetail = (props) => {
  let { id, categoryId } = useParams();
  const dispatch = useDispatch();


  const { booking } = useSelector((state) => state.booking);

  const { bookingReviews } = useSelector((state) => state.reviews);

  const categoryTypes = {
    PROPERTY: "Property",
    CAR: "Car",
    TOUR: "Tour",
  };

  const deleteReview = async (id) => {
    Swal.fire({
      icon: 'info',
      title: 'Do you want to remove this review',
      showDenyButton: true,
      confirmButtonText: 'Remove',
      denyButtonText: 'Cancel',
    }).then(result => {
      if (result.isConfirmed) {
        props.removeReview(id);
      }
    });
  };

  const BookReviewCard = (cardProps) => {
    const { revlist } = cardProps;
    return (
      <li key={revlist._id}>
        <div className="edit-btns">
          <span onClick={() => dispatch({ type: DEFAULT_ACTIONS.SET_UPDATE_REVIEW_MODAL, status: true, review: revlist })}>
            <i className="fas fa-edit"></i>{" "}
          </span>
          <span onClick={() => deleteReview(revlist._id)}>
            <i className="fas fa-trash-alt"></i>{" "}
          </span>
        </div>
        <div className="box-sh-list">
          <span>
            <div className="filed-star">
              {[...Array(revlist.ratings)].map((star, index) => {
                return (
                  <span className="star" key={index}>
                    <i className="fas fa-star"> </i>
                  </span>
                );
              })}
            </div>
            <div className="empty-star">
              <span className="star">
                <i className="fas fa-star"> </i>
                <i className="fas fa-star"> </i>
                <i className="fas fa-star"> </i>
                <i className="fas fa-star"> </i>
                <i className="fas fa-star"> </i>
              </span>
            </div>

            <label>{new Date(revlist.createdAt).toLocaleDateString()}</label>
          </span>
          <p>{revlist.review}</p>
        </div>
      </li>
    );
  };

  useEffect(() => {
    props.specificBooking(id, categoryId);
    props.bookingReview(id);
  }, []);

  return (
    <>
      {localStorage.getItem(CONSTANTS.ACCESS_TOKEN) && booking && booking.vendor_id ? (
        <div className="chat-page">
          <Chat
            bookingId={booking._id}
            categoryType={categoryTypes[booking.categoryType]}
            receiverId={booking.vendor_id && booking.vendor_id._id}
            vendor={booking.vendor_id}
          />
        </div>
      ) : null}
      <div className="booking-detail my-bboking-main">
        <div className="container">
          <div className="pagination">
            <ul className="mb-3">
              <li>
                <Link to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`}>
                  <i className="fas fa-chevron-left"></i>
                </Link>
              </li>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
              <Link to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`}> My Bookings</Link>
              </li>
              <li>
                <Link className="active"> Past Bookings</Link>
              </li>
            </ul>
          </div>
          <div className="boking-outer">
            <div className="row">
              <div className="col-md-7">
                <div className="booking-det-left">
                  <div className="book-main-img">
                    {booking.property_id && (
                      <Link to={`/property/${booking.property_id.slug}`}>
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                          <img
                            src={
                              booking.property_id &&
                              booking.property_id.images
                            }
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                    {booking.car_id && (
                      <Link to={`/cardetail/${booking.car_id.slug}`}>
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                          <img
                            src={booking.car_id && booking.car_id.images}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                    {booking.tourId && (
                      <Link to={`/tour-detail/${booking.tourId.slug}`}>
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                          <img
                            src={booking.tourId && booking.tourId.images}
                            alt=""
                          />
                        ) : (
                          ""
                        )}
                      </Link>
                    )}
                  </div>
                  <div className="detail-property">
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <h5>{booking.property_id && booking.property_id.name}</h5>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                      <h5>{booking.car_id && booking.car_id.name}</h5>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                      <h5>{booking.tourId && booking.tourId.name}</h5>
                    ) : (
                      ""
                    )}

                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <h6>
                        <img src={lc} alt="" />{" "}
                        {booking.property_id && booking.property_id.location}
                      </h6>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                      <h6>
                        <img src={lc} alt="" />{" "}
                        {booking.car_id && booking.car_id.location}
                      </h6>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                      <h6>
                        <img src={lc} alt="" />{" "}
                        {booking.tourId && booking.tourId.location}
                      </h6>
                    ) : (
                      ""
                    )}

                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <ul className="amnites">
                        <li>
                          {booking.property_id &&
                            booking.property_id.personCapacity}{" "}
                          Guest{" "}
                        </li>
                        {/* <li>
                          {booking.property_id && booking.property_id.bedrooms}{" "}
                          Bedroom
                        </li> */}
                        <li>
                          {booking.property_id && booking.property_id.bathrooms}{" "}
                          Bathroom
                        </li>
                        <li>
                          {booking.property_id && booking.property_id.beds} Beds
                        </li>
                      </ul>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <h4>
                        <label>{booking.currencySymbol}</label>{" "}
                        {booking.amountPerDay / booking.totalDays}{" "}
                        <span>/Day</span>
                      </h4>
                    ) : booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                      <h4>
                        <label>{booking.currencySymbol}</label>{" "}
                        {(booking.amountPerDay / booking.totalDays).toFixed(2)}{" "}
                        <span>/Day</span>
                      </h4>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="booking-det-right">
                  <div className="row border-btnv">
                    <div className="col-md-6">
                      <div className="inner-date">
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                          <span>Check-In</span>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                          <span>Pick-Up</span>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                          <span>Date</span>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                          <h4>{new Date(booking.checkIn).toDateString()}</h4>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                          <h4>{new Date(booking.pickupDate).toDateString()}</h4>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                          <h4>{new Date(booking.date).toDateString()}</h4>
                        ) : (
                          ""
                        )}
                        {/* <h4>24 <span>Aug 2023</span></h4> */}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="inner-date">
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                          <span>Check-Out</span>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                          <span>DROP</span>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                          <h4>{new Date(booking.checkOut).toDateString()}</h4>
                        ) : (
                          ""
                        )}
                        {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                          <h4>{new Date(booking.dropDate).toDateString()}</h4>
                        ) : (
                          ""
                        )}

                        {/* <h4>24 <span>Aug 2023</span></h4> */}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                        <h6>
                          Nights: <span>{booking.totalDays}</span>
                        </h6>
                      ) : (
                        ""
                      )}
                      {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                        <h6>
                          Days: <span>{booking.totalDays}</span>
                        </h6>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="payment-details-table">
              <div className="payment-tab-head">
                <h4>Payment Details</h4>
                {booking.hasDonePayment === true ? (
                  <span className="payemnt-done">
                    <label></label>Payment Done
                  </span>
                ) : (
                  <span className="payemnt-pending">
                    <label></label>Payment Pending
                  </span>
                )}
              </div>
              <div className="bookng-table-cart">
                <table>
                  <tr>
                    <th>Guests</th>
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <th>No. of Nights</th>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? <th>No. of Days</th> : ""}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                      <th>No. of Nights</th>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                      <th>Price/Night</th>
                    ) : (
                      ""
                    )}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? <th>Price/Day</th> : ""}
                    {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                      <th>Price/Night</th>
                    ) : (
                      ""
                    )}
                    <th>Total Price</th>
                  </tr>
                  <tr>
                    <td>
                      {" "}
                      {booking.members &&
                        booking.members.map((member, index) =>
                          member.count === 0 ? null : (
                            <span key={index}>
                              {member.name}: {member.count}
                            </span>
                          )
                        )}
                    </td>
                    <td>{booking.totalDays}</td>
                    <td>
                      {booking.currencySymbol}{" "}
                      {(booking.amountPerDay / booking.totalDays).toFixed(2)}
                    </td>
                    <td>
                      {booking.currencySymbol} {parseFloat(booking.amountPerDay).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="discount-row">
                    <td></td>
                    <td></td>
                    <td>
                      <span>VAT</span>{" "}
                    </td>
                    <td>
                      <label>{booking.currencySymbol} {booking.vat ? booking.vat.toFixed(2) : "-"}</label>
                    </td>
                  </tr>
                  <tr className="discount-row">
                    <td></td>
                    <td></td>
                    <td>
                      <span>Discount</span>{" "}
                    </td>
                    <td>
                      <label>{booking.discount ? booking.discount : "-"}</label>
                    </td>
                  </tr>
                  <tr className="discount-disc">
                    <td></td>
                    <td></td>
                    <td>
                      <span>Grand Total</span>{" "}
                    </td>
                    <td>
                      {" "}
                      <label>
                        {booking.currencySymbol} {parseFloat(booking.overAllAmountToPaid).toFixed(2)}
                      </label>{" "}
                    </td>
                  </tr>
                </table>
                {/* <div className="vat-group">
              <ul>
                <li><span>VAT</span> <label>{booking.currencySymbol}  622.00</label></li>
                <li><span>Discount</span> <label>- {booking.currencySymbol}  622.00</label></li>
              </ul>
              <div className="grand-totla">
                <ul>
                  <li><span>Grand Total</span> <label>{booking.currencySymbol}  {booking.overAllAmountToPaid}</label></li>
                </ul>
              </div>
            </div> */}
              </div>
            </div>

            <div className="booking-descroiptons">
              {booking.categoryType === CONSTANTS.RENTAL_TYPE.PROPERTY ? (
                <>
                  <div className="reviewlist">
                    <ul className="review-listing">
                      <h5>Reviews</h5>
                      {bookingReviews.length === 0 ? (
                        <p>No review </p>
                      ) : (
                        bookingReviews &&
                        bookingReviews.map((revlist) => (
                          <Fragment key={revlist._id}>
                            <BookReviewCard revlist={revlist} index={revlist._id} />
                          </Fragment>
                        ))
                      )}
                    </ul>
                  </div>
                </>
              ) : null}

              {booking.categoryType === CONSTANTS.RENTAL_TYPE.CAR ? (
                <div className="reviewlist">
                  <ul className="review-listing">
                    <h5>Reviews</h5>
                    {bookingReviews.length === 0 ? (
                      <p>No review </p>
                    ) : (
                      bookingReviews &&
                      bookingReviews.map((revlist) => (
                        <Fragment key={revlist._id}>
                          <BookReviewCard revlist={revlist} index={revlist._id} />
                        </Fragment>
                      ))
                    )}
                  </ul>
                </div>
              ) : null}
              {booking.categoryType === CONSTANTS.RENTAL_TYPE.TOUR ? (
                <div className="listing">
                  <ul className="review-list">
                    <h5>Reviews</h5>
                    {bookingReviews.length === 0 ? (
                      <p>No review </p>
                    ) : (
                      bookingReviews &&
                      bookingReviews.map((revlist) => (
                        <Fragment key={revlist._id}>
                          <BookReviewCard revlist={revlist} index={revlist._id} />
                        </Fragment>
                      ))
                    )}
                  </ul>
                </div>
              ) : null}
              {booking.canAddReview ? (
                <div className="add- review">
                  <button
                    onClick={() => {
                      dispatch({
                        type: DEFAULT_ACTIONS.SET_CREATE_REVIEW_MODAL,
                        status: true,
                        categoryType: booking.categoryType,
                        reviewCategoryId: booking[bookingTypes[booking.categoryType]]._id,
                        bookingId: booking._id
                      });
                    }}
                  >
                    Add Reviews
                  </button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  bookingReview: (bookingId) => dispatch(ReviewAction.specificBookingReview(bookingId)),
  specificBooking: (bookingId, categoryId) => dispatch(BookingAction.specific(bookingId, categoryId)),
  removeReview: (reviewId) => dispatch(ReviewAction.remove(reviewId))
});

export default withAuth(connect(null, mapDispatchToProps)(Bookingdetail));
