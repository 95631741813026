import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ScrollToTop = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to top before the route change
    window.scrollTo(0, 0);
  }, [location]);

  // Wrap the navigate function to scroll to top before navigating
  const navigateWithScrollToTop = (to, options) => {
    window.scrollTo(0, 0);
    navigate(to, options);
  };

  return (
    <>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, { navigate: navigateWithScrollToTop })
      )}
    </>
  );
};

export default ScrollToTop;
