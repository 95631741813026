import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';

import { PropertyAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";
import { CONSTANTS } from "../../../utils";

const PropertyListing = (props) => {

  const { properties, propertyLoader, hasMoreProperty, offset, limit } = useSelector((state) => state.property);

  const fetchProperties = async (page = 0) => {
    const params = { type: CONSTANTS.RENTAL_TYPE.PROPERTY, page: page, limit };

    props.listProperties(params);
  };

  const fetchMore = () => {
    let pageOffset;

    if (!offset) pageOffset = limit;
    else {
      pageOffset = offset + limit;
    };
    fetchProperties(pageOffset);
  };

  useEffect(() => {
    fetchProperties(0);

    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {propertyLoader && <Loading />}
      <div className="product-liating-page">
        <div className="container">
          <div className="list-filter">
            <h3>Properties near you</h3>
          </div>

          <div className="pro-list">
            <InfiniteScroll
              dataLength={properties.length}
              next={fetchMore}
              scrollThreshold={0.8}
              hasMore={hasMoreProperty}
            >
              <div className="row">
                {properties.map((property, i) => (
                  <div className="col-md-4" key={i}>
                    <Link
                      to={`/property/${property.slug}`}
                      state={property}
                    >
                      <div className="inner-propery">
                        <div className="sale-btn">
                          <span>
                            Book Now
                          </span>
                          <img
                            src={property.images && property.images[0]}
                            alt="propertyone"
                          />
                        </div>
                        <h5>{property.name}</h5>
                        <p>{property && property.buildingId && property.buildingId.name},  {property && property.areaId && property.areaId.name}, {property && property.city_id && property.city_id.name}</p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  listProperties: (query) => dispatch(PropertyAction.list(query)),
});

export default connect(null, mapDispatchToProps)(PropertyListing);
