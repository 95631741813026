import React, {useState} from 'react';
import styles from './InfoList.module.css';
import geo_icon from '../../../assets/icons/geo.png';
import {Long} from "../../../components/Long/Long";
import GoogleMap from "../../../components/Map";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {CONSTANTS} from "../../../utils";
import Transmission_icon from './../../../assets/images/Transmission.png';
import Seats_icon from './../../../assets/images/Seats.png';
import Engine_icon from './../../../assets/images/Engine.png';
import Bootspace_icon from './../../../assets/images/Bootspace.png';
import BHP_icon from './../../../assets/images/BHP.png';
import boot_space from './../../../assets/images/download.png';

const descriptionDesktopMax = 1000;
const descriptionDeMobilMax = 200;

export const InfoList = ({state, type}) => {

  const windowWidth = useWindowWidth();
  const [isDescription, setIsDescription] = useState(false);
  const isCAR = type === CONSTANTS.RENTAL_TYPE.CAR;

  const checkIsShowReadMore = () => {
    if(windowWidth > 767 && state.description !== undefined) return state.description.length > descriptionDesktopMax;
    if(windowWidth < 767 && state.description !== undefined) return state.description.length > descriptionDeMobilMax;
  }

  const getParsingDescription = () => {
    return (
      state.description.split('*').map((paragraph, index) => (
        <React.Fragment key={index}>
          {index > 0 && '-'}
          {paragraph}
          {'\n'}
        </React.Fragment>
      ))
    )
  }

  return !state ? <span>error</span> : (
    <div className={styles.container}>
      <h5 className={styles.name}>{state.name}</h5>
      <div className={styles.location}>
        <img src={geo_icon} alt={geo_icon}/>
        <h4>{state.location}</h4>
      </div>
      {!isCAR &&
        <div className={styles['property-info-block']}>
          <p>{state.personCapacity} Guest</p> <p>·</p>
          <p>{state.bedrooms} Bedroom</p> <p>·</p>
          <p>{state.bathrooms} Bathroom</p> <p>·</p>
          <p>{state.beds} Beds</p>
        </div>
      }
      <Long margin={windowWidth > 767 ? 20 : 10} color={'#0095DB'} onSize={'100%'} radius={0}/>
      <div className={styles['description-block']}>
        {state.description !== undefined && <h6>Description</h6>}
        <pre>
          {state.description !== undefined && getParsingDescription()}
          {/*{state.description !== undefined && getParsingDescription().slice(0, windowWidth > 767 ? descriptionDesktopMax : descriptionDeMobilMax)}*/}
          {checkIsShowReadMore() &&
            <button
              className={styles['description-block-read-more']}
              onClick={() => setIsDescription(!isDescription)}
            >
              {isDescription ? '↑ Close' : '...Read More'}
            </button>
          }
        </pre>
      </div>
      {!isCAR &&
        <div className={styles['ammenities-wrapper']}>
          <h6>Ammenities</h6>
          <div className={styles.ammenities}>
            {state.amenities && state.amenities.map((item, index) => (
             <AmmenitieItem index={index} icon={item.icon} title={item.title}/>
            ))}
          </div>
        </div>
      }
      {isCAR &&
        <div className={styles['ammenities-wrapper']}>
          <h6>Main Features</h6>
          <div className={`${styles.ammenities} ${styles.ammenitiesCar}`}>
            <AmmenitieItem index={1} icon={BHP_icon} title={state.features.bhp}/>
            <AmmenitieItem index={1} icon={boot_space} title={state.features.boot_space}/>
            <AmmenitieItem index={1} icon={Engine_icon} title={state.features.engine}/>
            <AmmenitieItem index={1} icon={Bootspace_icon} title={state.features.mileage}/>
            <AmmenitieItem index={1} icon={Seats_icon} title={state.features.seats}/>
            <AmmenitieItem index={1} icon={Transmission_icon} title={state.features.transmission}/>
          </div>
        </div>
      }
      <div className={styles.map}>
        <h6>View on map</h6>
        <div className={styles.mapWrapper}>
          <GoogleMap  positionalLat={state.latitude} positionalLong={state.longitude} />
        </div>
        <div className={styles.location}>
          <img src={geo_icon} alt={geo_icon}/>
          <h4>{state.location}</h4>
        </div>
      </div>
      <div className={styles.bottomLong}>
       <Long color={'#0095DB'} radius={0}/>
      </div>
    </div>
  )
}

const AmmenitieItem = ({index, icon, title}) => {
  return (
    <div key={index} className={styles['ammenities-item']}>
      <img src={icon} alt={title}/>
      <p>{title}</p>
    </div>
  )
}