import React from 'react';
import styles from './FooterBarItem.module.css';

export const FooterBarItem = ({title, icon, active, callback}) => {
  return (
    <button
      onClick={callback}
      className={`${styles.container} ${active && styles['container-active']}`}
    >
      <img src={icon} alt="FooterBarItem"/>
      <p>{title}</p>
    </button>
  )
}