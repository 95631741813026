import {CONSTANTS, IMAGES} from "../../../utils";
import {FormDashboard} from "../../../components/FormDashboard/FormDashboard";
import React, {useEffect, useState} from "react";
import {useAnimationOnLoad} from "../../../hooks/useAnimationOnLoad";
import {useImageQuery} from "../../../hooks/useImageQuery";
import {SelectProperty} from "../../../components/SelectProperty/SelectProperty";
import {useDispatch, useSelector} from "react-redux";
import {setIsRenderProperty} from "../../../redux/reducers/homePage";
import "react-dates/initialize";
import {useWindowWidth} from "../../../hooks/useWindowWidth";
import {modifyUrlParameters} from "../../../components/CardsRenderBlock/CardHome/CarSpecificationsBlock/resizeImages";
import {Img} from "react-image";
import {useProgressiveImageLoader} from "../../../hooks/useProgressiveImageLoader";
import homeBanner from '../../../assets/banners/home.png';
import carBanner from '../../../assets/banners/car.png';
import tourBanner from '../../../assets/banners/tour.png';

export const Banner = ({onSubmit}) => {

  const dispatch = useDispatch();
  const windowWidth = useWindowWidth();

  const isRenderProperty = useSelector(state => state.homePage.isRenderProperty);
  const activeCategoryHomePage = useSelector(state => state.homePage.activeCategoryHomePage);

  const isAnimatedTitle = useAnimationOnLoad(0, isRenderProperty);
  const isAnimatedDashboard = useAnimationOnLoad(200, isRenderProperty);

  useEffect(() => {
    if (isAnimatedTitle && isAnimatedDashboard) {
      dispatch(setIsRenderProperty({ isRenderProperty: true }));
    }
  }, [isAnimatedTitle, isAnimatedDashboard, dispatch]);

  const { data: imageUrl, isLoading, isError } = useImageQuery(activeCategoryHomePage, renderImageBackgroundFonBanner(activeCategoryHomePage), 86400000);

  const qualities = [1,90];

  const getPhotoSize = (windowWidth) => {
    if (windowWidth > 1200) return 1600;
    else if (windowWidth > 992) return 1300;
    else if (windowWidth > 767) return 1000;
    else return 600;
  };

  const currentImageSrc = useProgressiveImageLoader(imageUrl, qualities, getPhotoSize(windowWidth));
  return (

    <div
      className="banner-page"
      style={{
        backgroundImage: currentImageSrc !== null && `url(${currentImageSrc})`,
        backgroundOrigin: 'center',
        backgroundSize: 'cover',
      }}
    >

      <div className="banner-wrapper">
        <div
          style={{ transition: isAnimatedTitle ? '0.4s' : '0s'}}
          className={`banner-data banner-data-additionally ${isAnimatedTitle && 'banner-data-additionally-set-top'}`}
        >
          <h1 className={'banner-data-additionall-h1'}>Experience the<br/>epitome of luxury</h1>
          {/* <p className={'banner-data-additionall-p'}>Contrary to popular belief, of classical Latin literature from 45 BC, making.</p> */}
        </div>
        <div
          style={{transition: isAnimatedDashboard ? '0.4s' : '0s'}}
          className={`select-property select-property-additionally ${isAnimatedDashboard && 'select-property-additionally-set-top'}`}
        >
          <SelectProperty/>
          <FormDashboard
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
};

const renderImageBackgroundFonBanner = (name) => {
  switch (name) {
    case CONSTANTS.RENTAL_TYPE.PROPERTY:
      return homeBanner;//IMAGES.BANNER.PROPERTY;
    case CONSTANTS.RENTAL_TYPE.CAR:
      return carBanner;//IMAGES.BANNER.CAR;
    case CONSTANTS.RENTAL_TYPE.TOUR:
      return tourBanner;//IMAGES.BANNER.TOUR;
    default:
      return homeBanner;//IMAGES.BANNER.PROPERTY;
  }
};


// <img
//   alt={'banner-image'}
//   src={modifyUrlParameters(imageUrl, bannerSize.qualityBack, bannerSize.widthBack)}
//   style={{
//     position: 'absolute',
//     left: '50%',
//     top: '50%',
//     transform: 'translate(-50%, -50%)',
//     height: '100%',
//     width: '1552px',
//     background: 'white'
//   }}
// />
{/*<img*/}
{/*  alt={'banner-image'}*/}
{/*  src={modifyUrlParameters(imageUrl, bannerSize.qualityFront, bannerSize.widthFront)}*/}
{/*  style={{*/}
{/*    position: 'absolute',*/}
{/*    left: '50%',*/}
{/*    top: '50%',*/}
{/*    transform: 'translate(-50%, -50%)',*/}
{/*    height: '100%',*/}
{/*    width: '1552px',*/}
{/*  }}*/}
{/*/>*/}