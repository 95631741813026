import React, { useState } from "react";
import { Field, useFormik, FormikProvider } from "formik";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";

import ModalPopup from "../Common/Fields/ModalPopup/ModalPopup";
import { SignupSchema } from "../../schemas";
import { DEFAULT_ACTIONS, UserAction } from "../../redux/actions";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  parsePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import Multimedia from "../Common/Multimedia";
import { Toaster } from "../Toaster";
import { Constants } from "../Common/Constants";
import Loading from "../Loader";

const SIgnupModel = (props) => {
  const dispatch = useDispatch();

  const { signupModal, phoneNumber } = useSelector((state) => state.auth);

  const [isLoading, setLoading] = useState(false)
  let images = [];

  const setImages = (files) => {
    verifyOTPFormik.setFieldValue("documents", { passport: files });
  };

  const verifyOTPFormik = useFormik({
    initialValues: {
      firstName: "",
      phoneNumber: "",
      email: "",
    },
    onSubmit: async (values) => {

      if (values.firstName.trim() == "") {
        Toaster(Constants.TOAST_TYPE.ERROR, "first name is required");
        return;
      } else if (values.phoneNumber.trim() == "") {
        Toaster(Constants.TOAST_TYPE.ERROR, "Phone number is required");
        return;
      }else if (values.email.trim() == "") {
        Toaster(Constants.TOAST_TYPE.ERROR, "email is required");
        return;
      }else{
        setLoading(true);
        const phoneNumber = parsePhoneNumber(values.phoneNumber);
        const userCountryCode = phoneNumber.countryCallingCode;
        const userPhoneNumber = phoneNumber.nationalNumber;
  
        const signUpData = {
          firstName: values.firstName,
          phoneNumber: userPhoneNumber,
          email: values.email,
          countryCode: userCountryCode,
        };
        const res= await  props.signup(signUpData);
        if(res){
            setLoading(false);
        }
      }
      
    },
  });

  return (
    <ModalPopup
      show={signupModal}
      onHide={() => {
        dispatch({ type: DEFAULT_ACTIONS.SET_SIGNUP_MODAL, status: false });
        verifyOTPFormik.resetForm();
      }}
      dialogClassName="agent-modal sm-modal"
      title="Enter details to continue"
      component={
        <FormikProvider value={verifyOTPFormik}>
          <div className="page-modal">
            <form onSubmit={verifyOTPFormik.handleSubmit}>
              <div className="from-group">
                <label>Full Name</label>
                <Field name="firstName" type="text" id="firstName" />
                {verifyOTPFormik.errors.firstName &&
                verifyOTPFormik.touched.firstName ? (
                  <div className="error">
                    {verifyOTPFormik.errors.firstName}
                  </div>
                ) : null}
              </div>
              <div className="from-group">
                <label>Phone Number</label>
                <PhoneInput
                  value={verifyOTPFormik.values.phoneNumber}
                  international
                  name="phoneNumber"
                  onChange={(value) =>
                    value && verifyOTPFormik.setFieldValue("phoneNumber", value)
                  }
                  defaultCountry="AE"
                  id="phone-input"
                />
                {verifyOTPFormik.errors.phoneNumber &&
                verifyOTPFormik.touched.phoneNumber ? (
                  <div className="error">
                    {verifyOTPFormik.errors.phoneNumber}
                  </div>
                ) : null}
              </div>
              <div className="from-group">
                <label>Email</label>
                <Field name="email" type="email" />
                {verifyOTPFormik.errors.email &&
                verifyOTPFormik.touched.email ? (
                  <div className="error">{verifyOTPFormik.errors.email}</div>
                ) : null}
              </div>
              {/* <div className="from-group">
                                <label>Date of Birth</label>
                                <Field
                                    name="dateOfBirth"
                                    type="date"
                                    // min={moment(new Date()).format("YYYY-MM-DD")}
                                    max={moment(new Date("12-31-2005")).format("YYYY-MM-DD")}
                                />
                                {verifyOTPFormik.errors.dateOfBirth && verifyOTPFormik.touched.dateOfBirth ? (
                                    <div className="error">{verifyOTPFormik.errors.dateOfBirth}</div>
                                ) : null}
                            </div> */}
              {/* <div className="from-group">
                                <label>Upload Document</label>
                                <div className="files">
                                    <Multimedia
                                        name="documents"
                                        onChange={() => {
                                            if (images.length > 0) verifyOTPFormik.setFieldValue("doc", "doc");
                                            else verifyOTPFormik.setFieldValue("doc", "");
                                        }}
                                        label="Click to Upload Pdf"
                                        multiple={false}
                                        minFileSize={0}
                                        checkFiles={(files) => {
                                            if (files.length > 0) {
                                                verifyOTPFormik.setFieldValue("doc", "true");
                                            } else {
                                                verifyOTPFormik.setFieldValue("doc", "");
                                            }
                                        }}
                                        setImagess={setImages}
                                    />
                                    <Field name="doc" type="text" style={{ display: "none" }} />
                                    {verifyOTPFormik.errors.doc && verifyOTPFormik.touched.doc ? (
                                        <div className="error">{verifyOTPFormik.errors.doc}</div>
                                    ) : null}
                                </div>
                            </div> */}
              <div className="from-group">
                <button type="submit">Submit</button>
              </div>
            </form>
          </div>
          {isLoading&&<Loading />}
        </FormikProvider>
      }
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  signup: (payload) => dispatch(UserAction.signup(payload)),
});

export default connect(null, mapDispatchToProps)(SIgnupModel);
