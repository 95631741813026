import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { connect, useSelector } from "react-redux";

//Components
import { CategoryAction, TourAction } from "../../redux/actions";
import Loading from "../../components/Loader";

const Tourlisting = ({ currency, list, popularToursList, listCategories }) => {
  const [filter, setFilter] = useState([]);
  const [subcategory, setSubcatagory] = useState([]);

  const params = useParams();

  const { toursList, tourLoader, popularTours } = useSelector((state) => state.tour);
  const { subCategories } = useSelector((state) => state.category);

  const fetchTours = () => {

    const query = {
      latitude: "30.7046",
      longitude: "76.7179",
      categoryId: params._id,
      subCategories: subcategory,
      currency,
    };

    list(query);
  };

  const fetchPopularTours = () => {
    const query = { currency };

    popularToursList(query);
  };

  const setSubcat = (id) => {
    setFilter((prev) =>
      prev.map((val) => {
        if (val._id === id) {
          if (val.select) val.select = false;
          else val.select = true;
        } else val.select = false;
        return val;
      })
    );

    setSubcatagory((prev) =>
      prev.includes(id) ? prev.filter((val) => val !== id) : [...prev, id]
    );
  };

  useEffect(() => {
    listCategories(params._id)
    fetchPopularTours();
  }, []);

  useEffect(() => {
    fetchTours();
  }, [subcategory]);

  return (
    <Fragment>
      {tourLoader && <Loading />}
      <div className="tours-lising">
        <div className="container">
          <div className="pagination">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
            </ul>
          </div>
          <div className="tours-slide">
            {/* <Slider {...settings}> */}
            {subCategories &&
              subCategories.map((filterList, index) => (
                <span
                  className={subcategory.includes(filterList._id) && "active"}
                  onClick={() => setSubcat(filterList._id)}
                  key={index}
                >
                  {filterList.name}
                </span>
              ))}
            {/* </Slider>  */}
          </div>
          <div className="tours-cards mb-100">
            <h4>Desert Safari</h4>
            <div className="row">
              {toursList.length === 0 ? (
                <p className="no-rest">No result found</p>
              ) : (
                toursList &&
                toursList.map((tourListui, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to={`/tour-detail/${tourListui.slug}`}>
                      <div className="tours-cards-inner">
                        <div className="sale-btn">
                          <span>
                            Book Now
                          </span>
                          <img src={tourListui.images} alt="" />
                        </div>

                        <h5>{tourListui.name}</h5>
                        <span>
                          {tourListui.currencySymbol} {tourListui.price}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))
              )}
            </div>
          </div>

          <div className="tours-cards similar-pro mb-50">
            <h4>Our Popular Tours</h4>
            <div className="row">
              {popularTours &&
                popularTours.map((popularproperty, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to={`/tour-detail/${popularproperty.slug}`}>
                      <div className="tours-cards-inner">
                        <div className="sale-btn">
                          <span>
                            Book Now
                          </span>
                          <img src={popularproperty.images} alt="" />
                        </div>

                        <h5>{popularproperty.name}</h5>
                        <span>
                          {popularproperty.currencySymbol}
                          {popularproperty.price}
                        </span>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  listCategories: (params) => dispatch(CategoryAction.specific(params)),
  list: (params) => dispatch(TourAction.list(params)),
  popularToursList: (params) => dispatch(TourAction.popular(params)),
});


export default connect(null, mapDispatchToProps)(Tourlisting);
