import React from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import { ApiUrl } from "../ApiUrl";

const Smodal = ({ setViewPopup }) => {

  const SignupSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),
    phoneNumber: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Required")
  });
  return (
    <div className="mob-form">
      <div className="container">
        <div className="bnr-form">
          <h6>Sign up for the VIP List</h6>
          {/* <form>
            <div className='form-group'>
              <label>NAME</label>
              <input type='text' placeholder="" />
            </div>
            <div className='form-group'>
              <label>EMAIL</label>
              <input type='text' placeholder="" />
            </div>
            <div className='form-group'>
              <label>PHONE NUMBER</label>
              <input type='number' placeholder="" />
            </div>
            <div className='form-group sign-up mb-0'>
              <i class="fas fa-chevron-right"></i>
              <input type='submit' value="Register Now" />
            </div>


          </form> */}

          <Formik
            initialValues={{
              name: "",
              email: "",
              phoneNumber: ""
            }}
            validationSchema={SignupSchema}
            onSubmit={async (values, { resetForm }) => {
              try {
                const FormData = await axios.post(
                  `${ApiUrl.BASE_URL}${ApiUrl.MARKETING_FORM}`,
                  values
                );

                if (FormData.data.statusCode === 200) {
                  alert("Register Successfully");
                  setViewPopup(false);

                }

              } catch (error) {
                console.error("ERROR-->", error);
              }
              resetForm();
            }}
          >
            {({ errors, touched }) =>
              <Form>
                <div className="form-group">
                  <label>NAME</label>
                  <Field name="name" />
                  {errors.name && touched.name
                    ? <div className="error">
                      {errors.name}
                    </div>
                    : null}
                </div>
                <div className="form-group">
                  <label>EMAIL</label>
                  <Field name="email" />
                  {errors.email && touched.email
                    ? <div className="error">
                      {errors.email}
                    </div>
                    : null}
                </div>
                <div className="form-group">
                  <label>PHONE NUMBER</label>
                  <Field name="phoneNumber" type="number" />
                  {errors.phoneNumber && touched.phoneNumber
                    ? <div className="error">
                      {errors.phoneNumber}
                    </div>
                    : null}
                </div>
                <input type="submit" value="Register Now" />
              </Form>}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default Smodal;
