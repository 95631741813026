import React, { useEffect, useState, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";

//CSS
import "./profile.css";

//Components
import { Errors } from "../../components/Errors/Errors";
import { BookingAction, UserAction, DEFAULT_ACTIONS } from "../../redux/actions";
import Loading from "../../components/Loader";
import withAuth from "../../hoc/withAuth";
import { CONSTANTS, IMAGES } from "../../utils";

const Profile = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { profile, profileLoader, profileUnauthorizedError } = useSelector((state) => state.user);
  const { bookingError, bookings } = useSelector((state) => state.booking);

  const [bookingFilters, setBookingFilters] = useState({ tab: '', type: '' });
  // const { authenticated } = useSelector((state) => state.auth);

  const logoutHandler = () => {
    localStorage.clear();
    setLogin(false);
  };

  const setLogin = (status) => {
    dispatch({
      type: DEFAULT_ACTIONS.SET_AUTHENTICATED_USER,
      authenticated: status,
    });
  };

  const BookingCard = (bookingProps) => {
    const { index, booking, type } = bookingProps;

    return (
      <div key={index} className="col-md-4  col-sm-6" datawow-delay="0.2s">
        <Link
          to={`/booking-detail/${booking._id}/${booking.categoryId}`}
          state={booking}
        >
          <div className="inner-propery">
            <div className="sale-btn">
              <img className="booked-img-height"
                src={
                  booking.images && booking.images[0]
                }
                alt="booking"
              />
            </div>
            <div className="product-text">
              <div className="w-100 l-text">
                <h5>{booking.name}</h5>
                <p>
                  {booking.bookingInDate.split("T")[0]}
                </p>
                {type !== 'TOUR' ? <p>
                  {booking.bookingOutDate.split("T")[0]}
                </p> : null}

              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  };

  useEffect(() => {
    props.profileDetails();
  }, []);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    let tab = query.get('tab');
    let type = query.get('type');

    if (tab && type) {

      if(!CONSTANTS.PROFILE_BOOKING_FILTERS[tab.toUpperCase()]) {
        tab = CONSTANTS.PROFILE_BOOKING_FILTERS.UPCOMING;
      }

      if(!CONSTANTS.PROFILE_BOOKING_FILTERS[type.toUpperCase()]) {
        type = CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY;
      }

      
      props.listBooking({ type: tab.toLocaleUpperCase(), filterType: type.toLocaleUpperCase() });
      setBookingFilters({ tab, type });
    }

  }, [location]);

  // useEffect(() => {
  //   if (bookingError || profileUnauthorizedError) {
  //     props.logOut();
  //   }
  // }, [bookingError, profileUnauthorizedError])

  return (
    <Fragment>
      {profileLoader && <Loading />}
      <div>
        <section className="user-dash">
          <div className="container">
            <div className="row">
              <div className="col-md-3 border-right">
                <div className="user-img">
                  <div className="user-icon">
                    <div>
                      <h3>
                        {profile && profile.firstName}{" "}
                        {profile && profile.lastName}
                      </h3>
                      <p>{profile && profile.email}</p>
                      <p>{profile && profile.phoneNumber}</p>
                    </div>
                    <div>
                      <Link
                        to="/edit-profile"
                        className="edit-btn"
                        state={{ profile }}
                      >
                        <i className="fas fa-edit" />
                      </Link>
                    </div>
                  </div>
                  <div className="img-profile-size">
                    {profile && profile.image ? <img src={profile && profile.image} alt="user-profile" /> : <img src={IMAGES.USER_PROFILE} alt="user-profile" />}
                  </div>
                </div>

                <div className="listing-sidebar">
                  <ul className="nav d-block booking-list">
                    <li className="active">
                      <Link to="#">My Bookings</Link>
                      <i className="fas fa-book book-pos" />
                    </li>
                    {/* <li>
                      <Link to="/notification">Notifications</Link>
                      <i className="fas fa-bell book-pos" />
                    </li>
                    <li>
                      <Link>Chat Support</Link>
                      <i className="fas fa-info book-pos" />
                    </li>
                    <li>
                      <Link to="/my-reviews">My Reviews</Link>
                      <i className="fas fa-save  book-pos" />
                    </li> */}
                    <li>
                      <Link to="/" onClick={logoutHandler}>
                        Log Out
                      </Link>
                      <i className="fas fa-sign-out-alt book-pos" />
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-9">
                <div className="ft-tabs">
                  <ul>
                    <li>
                      <Link
                        className={`${bookingFilters.tab === CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE.toLowerCase() ? "active" : "inactive"} d-block`}
                        to={`/profile?type=${bookingFilters.type}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.ACTIVE}`}
                      >
                        Active
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${bookingFilters.tab === CONSTANTS.PROFILE_BOOKING_FILTERS.UPCOMING.toLowerCase() ? "active " : "inactive"} d-block`}
                        to={`/profile?type=${bookingFilters.type}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.UPCOMING}`}
                      >
                        Upcoming
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`${bookingFilters.tab === CONSTANTS.PROFILE_BOOKING_FILTERS.PAST.toLowerCase() ? "active" : "inactive"} d-block`}
                        to={`/profile?type=${bookingFilters.type}&tab=${CONSTANTS.PROFILE_BOOKING_FILTERS.PAST}`}
                      >
                        <span>Past</span>
                      </Link>

                    </li>
                  </ul>
                </div>
                <div className="filter-tab">
                  <ul>
                    <li className={bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY ? "active" : "inactive"}>
                      <Link
                        className={`${bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY.toLowerCase() ? "active" : "inactive"} d-block`}
                        to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY}&tab=${bookingFilters.tab}`}
                      >
                        <span>Property</span>
                      </Link>
                    </li>
                    <li className={bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.CAR ? "active" : "inactive"}>
                      <Link
                        className={`${bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.CAR.toLowerCase() ? "active" : "inactive"} d-block`}
                        to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.CAR}&tab=${bookingFilters.tab}`}
                      >
                        <span>Car</span>
                      </Link>
                    </li>
                    <li className={bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.TOUR ? "active" : "inactive"}>
                      <Link
                        className={`${bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.TOUR.toLowerCase() ? "active" : "inactive"} d-block`}
                        to={`/profile?type=${CONSTANTS.PROFILE_BOOKING_FILTERS.TOUR}&tab=${bookingFilters.tab}`}
                      >
                        <span>Tours</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                {bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.PROPERTY && (
                  <div className="row">
                    {bookings && bookings.length === 0 ? (
                      <p className="error-msg">{Errors.ERROR_MESSAGE}</p>
                    ) : (
                      bookings && bookings.map((myBookings, index) => <Fragment key={index}><BookingCard index={index} booking={myBookings} type={CONSTANTS.RENTAL_TYPE.PROPERTY} /></Fragment>)
                    )}
                  </div>
                )}

                {bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.CAR && (
                  <div className="row">
                    {bookings && bookings.length === 0 ? (
                      <p className="error-msg">{Errors.ERROR_MESSAGE}</p>
                    ) : (
                      bookings && bookings.map((item, index) => <Fragment key={index}><BookingCard index={index} booking={item} type={CONSTANTS.RENTAL_TYPE.CAR} /></Fragment>)
                    )}
                  </div>
                )}

                {bookingFilters.type === CONSTANTS.PROFILE_BOOKING_FILTERS.TOUR && (
                  <div className="row">
                    {bookings && bookings.length === 0 ? (
                      <p className="error-msg">{Errors.ERROR_MESSAGE}</p>
                    ) : (
                      bookings && bookings.map((item, index) => <Fragment key={index}><BookingCard index={index} booking={item} type={CONSTANTS.RENTAL_TYPE.TOUR} /></Fragment>)
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  profileDetails: () => dispatch(UserAction.profileDetails()),
  listBooking: (params) => dispatch(BookingAction.list(params)),
});

export default withAuth(connect(null, mapDispatchToProps)(Profile));

