import React, {useState, useEffect, useRef} from "react";
import styles from './FormDashboardLocationItem.module.css';
import locationIcon from "../../../assets/icons/location.png";
import './castom-autocomplete.css';
import {useDispatch, useSelector} from "react-redux";
import {setDataHomeDashboardLocation} from "../../../redux/reducers/homePage";

const countries = ['Dubai Marina', 'JBR', 'Bluewaters', 'Palm Jumeirah', 'Downtown Dubai', 'DIFC', 'Business Bay', 'Discovery gardens', 'Al Furjan', 'Jumeirah Islands', 'JLT', 'Deira', 'Bur Dubai', 'Al Karama', 'Al Satwa', 'Al Wasi', 'Al Sufouh', 'Dubailand'];

export const FormDashboardLocationItem = ({ title = ''}) => {

  const dispatch = useDispatch();

  const onSetDataHomeDashboardLocation = location => dispatch(setDataHomeDashboardLocation({location}));

  const maxItem = 100;
  const selectContainerRef = useRef(null);
  const value = useSelector(state => state.homePage.dataHomeDashboard.location);
  const [filteredCountries, setFilteredCountries] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [locationNotFound, setLocationNotFound] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    onSetDataHomeDashboardLocation(inputValue);

    if (inputValue === '') {
      setFilteredCountries(countries);
      setLocationNotFound(false);
    } else {
      const filtered = countries.filter(country => country.toLowerCase().includes(inputValue.toLowerCase()));
      setFilteredCountries(filtered);
      setSelectedIndex(-1);

      if (filtered.length === 0) {
        setLocationNotFound(true);
      } else {
        setLocationNotFound(false);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex < filteredCountries.length - 1 ? prevIndex + 1 : prevIndex));
      scrollSelectedItemIntoView();
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
      scrollSelectedItemIntoView();
    } else if (event.key === 'Enter' && selectedIndex !== -1) {
      onSetDataHomeDashboardLocation(filteredCountries[selectedIndex]);
      setFilteredCountries([]);
      setSelectedIndex(-1);
    }
  };

  const scrollSelectedItemIntoView = () => {
    if (selectContainerRef.current && selectedIndex !== -1) {
      const selectedElement = selectContainerRef.current.querySelector(`.${styles.selected}`);
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: 'auto',
          block: 'nearest',
        });
      }
    }
  };

  const onSetOverflowBody = (isHidden) => document.body.style.overflowY = !isHidden ? 'hidden' : 'scroll';

  const handleInputFocus = () => {
    setIsActive(true);
    setTimeout(() => setFilteredCountries(countries), 300);
  };

  useEffect(() => {
    const handleOutsideClick = () => {
      setFilteredCountries([]);
      setSelectedIndex(-1);
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  return (
    <div className={styles.wrapperFixed}>
      <div className={`${styles.container} ${isActive && styles['container-mobil-active']}`}>
        <p className={styles.title}>{title}</p>
        <div className={styles.wrapper}>
          <img src={locationIcon} alt={locationIcon}/>
          <div className={styles.block}>
            <input
              placeholder={'Enter location'}
              type={'text'}
              value={value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              onFocus={handleInputFocus}
              onBlur={() => {
                setTimeout(() => setIsActive(false), 100);
              }}
            />
            <div className={styles['select-container']} ref={selectContainerRef}>
              {isActive && filteredCountries.map((country, index) => {
                if ((index + 1) <= maxItem) {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        onSetDataHomeDashboardLocation(country);
                        setFilteredCountries([]);
                        setSelectedIndex(-1);
                      }}
                      className={index === selectedIndex ? styles.selected : ''}
                    ><span>{country}</span></div>
                  )
                }
              })}
              {isActive && locationNotFound && <strong>Location not found</strong>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
