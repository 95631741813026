
import React from 'react';
import styles from './MainButton.module.css';



// replace transform(scale) with width
export const MainButton = ({
  title = 'button',
  callback = null,
  background = '#0095DB',
  color = '0095DB',
  styleContainer = {},
  style = {},
  styleTitle = {},
  animated = false,
  hoverSize = true,
  Icon = null,
}) => {
  return (
    <button
      onClick={callback}
      style={styleContainer}
      className={styles.container}
    >
      <div
        className={`
        ${styles.wrapper} 
        ${!animated && styles['wrapper-hover']} 
        ${hoverSize && styles['hover-size']}
      `}
        style={{...style, background, color}}
      >
        {animated && <div className={styles['animated-block']}></div>}
        {Icon !== null && <Icon/>}
        <p
          style={styleTitle}
          className={`
            ${styles.title}
            ${hoverSize && styles['hover-size-title']}
          `}
        >
          {title}
        </p>
      </div>
    </button>
  )
}