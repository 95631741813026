import React, { useState } from "react";
import Files from "react-files";
import { connect } from "react-redux";

import { UserAction } from "../../redux/actions";
import { CONSTANTS } from "../../utils";

const Multimedia = ({ setImagess, checkFiles, uploadFile }) => {
  const [files, setFiles] = useState([]);

  const uploadImage = async (file) => {
    try {
  
      const formData = new FormData();
      formData.append("file", file);
      formData.append('type', CONSTANTS.FILE_UPLOAD_TYPE.USERS_DOCUMENTS);

      const response = await uploadFile(formData);
      return response.data.data.fileUrl;
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = async (newFiles) => {
    checkFiles([...files, ...newFiles]);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setTimeout(async () => {
      setImagess(
        await Promise.all(newFiles.map(async (val) => await uploadImage(val)))
      );
    }, 1000);
  };

  const handleFileRemove = (fileId) => {
    checkFiles(files.filter((prevFile) => prevFile.id !== fileId));
    setFiles((prevFiles) =>
      prevFiles.filter((prevFile) => prevFile.id !== fileId)
    );
  };

  const handleClearFiles = () => {
    checkFiles([]);
    setFiles([]);
  };

  return (
    <div>
      <Files
        name="doc"
        className="files-dropzone-list"
        dragActiveClassName="files-dropzone-active"
        style={{ height: "" }}
        onChange={handleChange}
        multiple
        maxFiles={5}
        maxFileSize={10000000}
        minFileSize={0}
        clickable
      >
        Drop files here or click to upload
      </Files>

      {files.length > 0 && (
        <button onClick={handleClearFiles} className="remove-files">
          Remove All Files
        </button>
      )}

      {files.length > 0 && (
        <div className="files-list">
          <ul>
            {files.map((file) => (
              <li key={file.id} className="files-list-item">
                <div className="files-list-item-preview">
                  {file.preview.type === "image" ? (
                    <img
                      className="files-list-item-preview-image"
                      src={file.preview.url}
                      alt="files-list-item-preview"
                    />
                  ) : (
                    <div className="files-list-item-preview-extension">
                      {file.extension}
                    </div>
                  )}
                </div>
                <div className="files-list-item-content">
                  <div className="files-list-item-content-item files-list-item-content-item-1">
                    {file.name}
                  </div>
                  <div className="files-list-item-content-item files-list-item-content-item-2">
                    {file.sizeReadable}
                  </div>
                </div>
                <div
                  className="files-list-item-remove"
                  onClick={() => handleFileRemove(file.id)}
                />
              </li>
            ))}
          </ul>
        </div>
      )}
      {/* {files.length > 0 && (
        <button className="upoad-btn" onClick={handleUploadFiles}>
          Upload
        </button>
      )} */}
    </div>
  );
}

const mapDispatchToProps = {
  uploadFile: UserAction.upload,
};

export default connect(null, mapDispatchToProps)(Multimedia);
