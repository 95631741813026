import React from "react";
import 'react-dates/initialize';
import styles from './FormDashboardDataItem.module.css';
import bookingIcon from '../../../assets/icons/booking.png';
import {useWindowWidth} from "../../../hooks/useWindowWidth";

export const FormDashboardDataItem = ({
  title = '',
  data = null,
  callback = null,
  isBottomLong = false,
  isIcon = true,
}) => {

  const windowWidth = useWindowWidth();
  return (
    <div
      onClick={callback}
      className={styles.container}
    >
      <div
        style={{
          opacity: isBottomLong ? 0.5 : 0,
          width: `${isBottomLong ? windowWidth > 767 ? 130 : 70 : 0}%`
        }}
        className={styles['bottom-long']}
      ></div>
      <p
        className={`${styles.title} ${isBottomLong && styles.titleActive}`}>{title}</p>
      <div className={styles.wrapper}>
        {isIcon && <img src={bookingIcon} alt={bookingIcon}/>}
        <div className={styles['data-wrapper']}>
          <p className={`${styles['data-show-text']} ${isBottomLong && styles.titleActive}`}>{data === null ? 'Select date' : data}</p>
        </div>
      </div>
    </div>
  );
};