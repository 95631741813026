import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from 'react-redux';

import { CarAction } from "../../../redux/actions";
import Loading from "../../../components/Loader";

const Carlisting = (props) => {

  const { carsList, carLoader } = useSelector((state) => state.car);

  useEffect(() => {
    const params = {
      latitude: 25.0805,
      longitude: 55.1403
    };

    props.listCars(params);
  }, []);

  return (
    <div className="product-liating-page">
      {carLoader && <Loading />}
      <div className="container">
        <div className="pagination">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/properties" className="active">
                Car Listing
              </Link>
            </li>
          </ul>
        </div>
        <div className="list-filter">
          <h3>Cars near you</h3>
        </div>
        <div className="pro-list">
          <div className="row">
            {carsList &&
              carsList.map((cardata, i) => (
                <div
                  className="col-md-4 wow bounceIn"
                  data-wow-delay="0.2s"
                  key={i}
                >
                  <div className="inner-propery">
                    <div className="sale-btn">
                      <Link state={cardata} to={`/cardetail/${cardata.slug}`}>
                        Book Now
                      </Link>
                      <img src={cardata.images[0]} alt="carone" />
                    </div>
                    <h5>{cardata.name}</h5>
                    <div className="modals">
                      <ul>
                        <li>
                          <span>{cardata.model}</span>
                          <b>Modal</b>
                        </li>
                        <li>
                          <span>{cardata.fuel}</span>
                        </li>
                      </ul>
                    </div>
                    <div className="car-details">
                      <div>
                        <span>Mileage (upto)</span>
                        <h6>{cardata.features.mileage}Km</h6>
                      </div>
                      <div>
                        <span>Transmission</span>
                        <h6>{cardata.fuel}</h6>
                      </div>
                      <div>
                        <span>Engine</span>
                        <h6>{cardata.features.engine} cc</h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  listCars: (params) => dispatch(CarAction.list(params)),
});

export default connect(null, mapDispatchToProps)(Carlisting);
