import React from 'react'
import exone from "../assetss/images/exone.png"
import extwo from "../assetss/images/extwo.png"
import exthree from "../assetss/images/exthree.png"

function ExporeDubai() {
  return (
   <div className='eore-dubai'>
    <div className='container'>
    <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Explore Dubai</h2>
    <div className='row'>
        <div className='col-md-3'>
            <div className='inner-dubai'>
                <img src={exone} alt="" />
                <h3>Marina</h3>
                <p>Contrary to popular belief, of classical
Latin liter from </p>
            </div>
        </div>
        <div className='col-md-3'>
            <div className='inner-dubai'>
                <img src={extwo} alt="" />
                <h3>Downtown (Burj Khalifa)</h3>
                <p>Contrary to popular belief, of classical
Latin liter from </p>
            </div>
        </div>
        <div className='col-md-3'>
            <div className='inner-dubai'>
                <img src={exthree} alt="" />
                <h3>JBR</h3>
                <p>Contrary to popular belief, of classical
Latin liter from </p>
            </div>
        </div>
        <div className='col-md-3'>
            <div className='inner-dubai'>
                <img src={exone} alt="" />
                <h3>Palm</h3>
                <p>Contrary to popular belief, of classical
Latin liter from </p>
            </div>
        </div>
    </div>
    </div>

   </div>
  )
}

export default ExporeDubai