import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isModalShow: false,
  data: {
    link: '',
    price: '',
    currency: ''
  }
};

const footerBarListData = createSlice({
  name: 'footerBarListData',
  initialState,
  reducers: {
    setIsModalShow (state, action) {
      const {isModalShow} = action.payload;
      state.isModalShow = isModalShow;
    },
    setData (state, action) {
      const {link, price, currency} = action.payload;
      state.data = {link, price, currency};
    }
  },
});

export const {
  setData,
  setIsModalShow,
} = footerBarListData.actions;

export default footerBarListData.reducer;
