import * as CurrencyAction from './currency';
import * as PropertyAction from './property';
import * as CategoryAction from './category';
import * as UserAction from './user';
import * as BookingAction from './booking';
import * as NotificationAction from './notification';
import * as ReviewAction from './reviews';
import * as CarAction from './cars';
import * as TourAction from './tour';

export const DEFAULT_ACTIONS = {
    SET_AUTHENTICATED_USER: 'SET_AUTHENTICATED_USER',
    SET_LOGIN_MODAL: 'SET_LOGIN_MODAL',
    SET_SIGNUP_MODAL: 'SET_SIGNUP_MODAL',
    SET_CREATE_REVIEW_MODAL: 'SET_CREATE_REVIEW_MODAL',
    SET_UPDATE_REVIEW_MODAL: 'SET_UPDATE_REVIEW_MODAL',
    SET_LOGIN_OTP: 'SET_LOGIN_OTP',
    SET_LOGIN_SUCCESS: 'SET_LOGIN_SUCCESS',
    SET_CURRENCY: 'SET_CURRENCY',
};

export const API_ACTIONS = {
    LIST_ALL_RENTAL_TYPE_SUCCESS: 'LIST_ALL_RENTAL_TYPE_SUCCESS',
    LIST_ALL_RENTAL_TYPE_ERROR: 'LIST_ALL_RENTAL_TYPE_ERROR',
    REQUEST_ALL_PROPERTIES: 'REQUEST_ALL_PROPERTIES',

    SPECIFIC_PROPERTY_SUCCESS: 'SPECIFIC_PROPERTY_SUCCESS',
    REQUEST_SPECIFIC_PROPERTY: 'REQUEST_SPECIFIC_PROPERTY',
    REQUEST_SPECIFIC_PROPERTY_ERROR: 'REQUEST_SPECIFIC_PROPERTY_ERROR',
    LIST_ALL_PROPERTIES_SUCCESS: 'LIST_ALL_PROPERTIES_SUCCESS',
    LIST_ALL_PROPERTIES_ERROR: 'LIST_ALL_PROPERTIES_ERROR',
    LIST_RENTAL_TYPE_REVIEWS_SUCCESS: 'LIST_RENTAL_TYPE_REVIEWS_SUCCESS',
    LIST_RENTAL_TYPE_REVIEWS_ERROR: 'LIST_RENTAL_TYPE_REVIEWS_ERROR',

    LIST_ALL_CARS_SUCCESS: 'LIST_ALL_CARS_SUCCESS',
    LIST_ALL_CARS_ERROR: 'LIST_ALL_CARS_ERROR',
    REQUEST_ALL_CARS: 'REQUEST_ALL_CARS',
    REQUEST_SPECIFIC_CAR: 'REQUEST_SPECIFIC_CAR',
    REQUEST_SPECIFIC_CAR_SUCCESS: 'REQUEST_SPECIFIC_CAR_SUCCESS',
    REQUEST_SPECIFIC_CAR_ERROR: 'REQUEST_SPECIFIC_CAR_ERROR',

    LIST_POPULAR_TOURS_SUCCESS: 'LIST_POPULAR_TOURS_SUCCESS',
    LIST_POPULAR_TOURS_ERROR: 'LIST_POPULAR_TOURS_ERROR',
    REQUEST_ALL_TOURS: 'REQUEST_ALL_TOURS',
    REQUEST_CATEGORIES: 'REQUEST_CATEGORIES',
    LIST_ALL_TOURS_SUCCESS: 'LIST_ALL_TOURS_SUCCESS',
    LIST_ALL_TOURS_ERROR: 'LIST_ALL_TOURS_ERROR',
    REQUEST_SPECIFIC_TOUR: 'REQUEST_SPECIFIC_TOUR',
    SPECIFIC_TOUR_SUCCESS: 'SPECIFIC_TOUR_SUCCESS',
    SPECIFIC_TOUR_ERROR: 'SPECIFIC_TOUR_ERROR',

    LIST_SUB_CATEGORIES_SUCCESS: 'LIST_SUB_CATEGORIES_SUCCESS',
    LIST_TOUR_SUB_CATEGORIES_ERROR: 'LIST_TOUR_SUB_CATEGORIES_ERROR',
    LIST_CATEGORIES_SUCCESS: 'LIST_CATEGORIES_SUCCESS',
    LIST_CATEGORIES_ERROR: 'LIST_CATEGORIES_ERROR',

    SET_CATEGORY_FILTERED_LOADER: 'SET_CATEGORY_FILTERED_LOADER',
    REQUEST_USER_NOTIFICATION: 'REQUEST_USER_NOTIFICATION',

    USER_NOTIFICATION_SUCCESS: 'USER_NOTIFICATION_SUCCESS',
    USER_NOTIFICATION_ERROR: 'USER_NOTIFICATION_ERROR',
    REQUEST_USER_NOTIFICATION_UPDATE: 'REQUEST_USER_NOTIFICATION_UPDATE',
    USER_NOTIFICATION_UPDATE_SUCCESS: 'USER_NOTIFICATION_UPDATE_SUCCESS',

    REQUEST_USER_PROFILE: 'REQUEST_USER_PROFILE',
    USER_PROFILE_SUCCESS: 'USER_PROFILE_SUCCESS',
    USER_PROFILE_ERROR: 'USER_PROFILE_ERROR',
    REQUEST_UPDATE_USER_PROFILE: 'REQUEST_UPDATE_USER_PROFILE',
    UPDATE_USER_PROFILE_SUCCESS: 'UPDATE_USER_PROFILE_SUCCESS',
    REQUEST_BOOKING_LIST: 'REQUEST_BOOKING_LIST',
    BOOKING_LIST_SUCCESS: 'BOOKING_LIST_SUCCESS',
    BOOKING_LIST_ERROR: 'BOOKING_LIST_ERROR',
    SPECIFIC_BOOKING_SUCCESS: 'SPECIFIC_BOOKING_SUCCESS',

    BOOKING_REVIEWS_SUCCESS: 'BOOKING_REVIEWS_SUCCESS',
    REQUEST_USER_REVIEWS_LIST: 'REQUEST_USER_REVIEWS_LIST',
    USER_REVIEWS_LIST_SUCCESS: 'USER_REVIEWS_LIST_SUCCESS',
    UNAUTHORIZED_ERROR: 'UNAUTHORIZED_ERROR',

    CREATE_REVIEW_SUCCESS: 'CREATE_REVIEW_SUCCESS',
    CREATE_BOOKING_SUCCESS: 'CREATE_BOOKING_SUCCESS',
    
};

export {
    CurrencyAction,
    PropertyAction,
    CategoryAction,
    UserAction,
    BookingAction,
    NotificationAction,
    ReviewAction,
    CarAction,
    TourAction
};