import { API_ACTIONS } from "..";
import { apiAxiosInstance } from "../../../config";
import { CONSTANTS } from "../../../utils";

const list = (params) => async(dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/tour/list`, { params });

		dispatch({ type: API_ACTIONS.LIST_ALL_TOURS_SUCCESS, data: response.data.data });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_ALL_TOURS_ERROR });
	}
};

const popular = (params) => async (dispatch) => {
	try {
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/tour/popular`, { params });

		dispatch({ type: API_ACTIONS.LIST_POPULAR_TOURS_SUCCESS, data: response.data.data });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.LIST_POPULAR_TOURS_ERROR });
	}
};

const specific = (id, currency) => async (dispatch) => {
	try {
		dispatch({ type: API_ACTIONS.REQUEST_ALL_TOURS, loader: true });
		const response = await apiAxiosInstance.get(`${CONSTANTS.API_VERSION.V1}/tour/${id}/${currency}`);

		dispatch({ type: API_ACTIONS.SPECIFIC_TOUR_SUCCESS, data: response.data.data });
	}
	catch(err) {
		dispatch({ type: API_ACTIONS.SPECIFIC_TOUR_ERROR });
	}
};

export { list, popular, specific };