import React from 'react';
import styles from './Pagination.module.css';
import {useNavigate} from "react-router-dom";

export const Pagination = ({typeListing, thisPage, totalItems, cardPage }) => {

  const navigate = useNavigate();
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalItems / cardPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <nav className={styles.container}>
      <ul className={styles.pagination}>
        {totalItems !== 0 &&
          <button
            disabled={thisPage <= 1}
            className={styles.prev}
            onClick={() => navigate(`/listings?type=${typeListing.toLowerCase()}&page=${thisPage > 1 ? thisPage - 1 : 1}`)}
          >{'<'}</button>
        }
        {pageNumbers.length !== 0 && pageNumbers.map((pageNumber) => (
          <li
            key={pageNumber}
            className={`${styles['page-item']} ${pageNumber === thisPage ? styles['page-item-active'] : ''}`}
          >
            <button
              disabled={pageNumber === thisPage}
              onClick={() => navigate(`/listings?type=${typeListing.toLowerCase()}&page=${pageNumber}`)}
              className={styles['page-link']}
            >
              {pageNumber}
            </button>
          </li>
        ))}
        {totalItems !== 0 &&
          <button
            disabled={thisPage >= pageNumbers.length}
            onClick={() => navigate(`/listings?type=${typeListing.toLowerCase()}&page=${thisPage < pageNumbers.length ? thisPage + 1 : thisPage}`)}
            className={styles.next}
          >{'>'}</button>
        }
      </ul>
    </nav>
  )
}