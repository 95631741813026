import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// Components
import { Errors } from '../../../components/Errors/Errors';
import { CategoryAction } from '../../../redux/actions';
import Loading from '../../../components/Loader';

const Tourmobile = (props) => {
  const [userdata, setUser] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (filter = { type: "TOUR" }) => {
    setLoading(true);
    const params = { ...filter, categoryId: [] };

    props.list(params).then(res => {
      setUser(res.data.categories);
      setLoading(false)
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="tour-services my-tours">
        <div className="container">
          <div className="list-filter">
            <h4>Tours Near You</h4>
            {/* <div className={filter ? "filter active" : filter}>
            <button onClick={openFilter}>
              <span>
                <img src={filterimg} alt="" />
              </span>
              Filter
            </button>
            {filter && (
              <Filter type={type} getIds={getId} setFilterIds={setIdpro} />
            )}
          </div> */}
          </div>
          <div className="row">
            {userdata.length === 0 ? (
              <p className="error-msg">{Errors.ERROR_MESSAGE}</p>
            ) : (
              userdata.map((toursList, index) => (
                <div key={index} className="col-md-4 wow bounceIn" data-wow-delay="0.2s">
                  <Link to={`/tour-listing/${toursList._id}`}>
                    <div className="inner-propery">
                      <div className="sale-btn">
                        <span>
                          Book Now
                        </span>{" "}
                        <img src={toursList.image} alt="carone" />
                      </div>
                      <h5>{toursList.name}</h5>
                      <p>{toursList.locationName}</p>
                    </div>
                  </Link>
                </div>
              ))
            )}
          </div>
          {/* {userdata.length === 0 ? (
          false
        ) : (
          <div className="view-all-btn">
            <Link className="common-btn" to="/tour-listing">
              <span>View All</span>
            </Link>
          </div>
        )} */}
        </div>
      </div>
    </>
  )
}

const mapDispatchToProps = {
  list: CategoryAction.list,
};

export default connect(null, mapDispatchToProps)(Tourmobile);