import React from "react";
import aboutone from "../assetss/images/aboutone.png";
import crstar from "../assetss/images/crstar.png";

function Experience({setViewPopup}) {
  return (
    <div className="about-main">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-md-6">
            <div className="left-col-about">
              <img src={crstar} alt="" />
              <h2>Rent a Luxury Apartment In Dubai</h2>
              <p>
              CBRent offers exceptional rental luxurious properties in Duba for you. Whether you're looking for apartments for rent, vacation homes, holiday homes, resorts, penthouses, staycations, or villas for short or long terms, choose from a  wide range of indulgent properties, we got you covered. 

              </p>
              <p>
                Hotel ut nisl quam nestibulum ac quam nec odio elementum sceisue
                the aucan ligula. Orci varius natoque penatibus et magnis dis
                parturient monte nascete ridiculus mus nellentesque habitant
                morbine.
              </p>
              <button className="ct-btn" onClick={() => setViewPopup(true)}>Book Now <i className="fas fa-chevron-right"></i></button>
            </div>
          </div>
          <div className="col-md-6">
           
              <div className="right-col-about wow fadeInUp animate" style={{animationDuration: "1s"}}>
                <img src={aboutone} alt="" />
              </div>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default Experience;
