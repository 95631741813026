import React, { useEffect, useState } from 'react';
import styles from './Listings.module.css';
import {useLocation} from 'react-router-dom';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { useQuery } from '@tanstack/react-query';
import { apiAxiosInstance } from '../../config';
import { CONSTANTS } from '../../utils';
import { CardHome } from '../../components/CardsRenderBlock/CardHome/CardHome';
import { LoaderRelative } from '../../components/CardsRenderBlock/CardsRenderBlock';
import { Pagination } from '../../Pagination/Pagination';
import {FooterBarList} from "../../components/FooterBarList/FooterBarList";

const LIMIT_CARDS = 18;

export const Listings = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const page = searchParams.get('page') || 1;
  const paramType = searchParams.get('type') || 'PROPERTY';
  const type = String(paramType.toUpperCase());

  const renderDescription = (item) => {
    if (type === CONSTANTS.RENTAL_TYPE.CAR) return item.description;
    if (type === CONSTANTS.RENTAL_TYPE.PROPERTY) return item.location;
    if (type === CONSTANTS.RENTAL_TYPE.TOUR) return item.description;
  };

  const [cards, setCards] = useState([]);
  const [totalItems, setTotalItems] = useState(null);

  const breadcrumbsData = [
    { label: 'Home', url: '/' },
    { label: `${type}s listing` },
  ];

  const { data, isLoading, error } = useQuery(
    ['listings', page],
    () =>
      apiAxiosInstance.get(
        `${CONSTANTS.API_VERSION.V1}/user/home?type=${type}&limit=${LIMIT_CARDS}&page=${page - 1}`
      ),
    {
      cacheTime: 0, // caching time in milliseconds (in this case, 60 seconds)
    }
  );

  useEffect(() => {
    data && setCards(data.data.data.data);
    data && setTotalItems(data.data.data.count);
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.breadcrumbs}>
          <Breadcrumbs breadcrumbsData={breadcrumbsData} />
        </div>
        <h3>{type}s near you</h3>
        {isLoading ?
          <div className={styles['loading-block']}><LoaderRelative /></div>
          :
          (
            <div className={styles.block}>
              {cards.map((item, index) => (
                <CardHome
                  key={index}
                  image={
                    item.images && item.images.length > 0 && item.images[0]
                  }
                  title={item.name}
                  link={`/list?type=${type.toLowerCase()}&slug=${item.slug}`}
                  description={renderDescription(item)}
                  contextType={type}
                  indexAnimate={index * 0.5}
                  carSpecifications={{
                    mileage: item.features ? item.features.engine : '',
                    transmission: item.fuel ? item.fuel : '',
                    engine: item.features ? item.features.mileage : '',
                  }}
                />
              ))}
            </div>
          )
        }
        <Pagination
          typeListing={type}
          thisPage={Number(page)}
          totalItems={Number(totalItems)}
          cardPage={LIMIT_CARDS}
        />
      </div>
    </div>
  );
};
