import React, {useEffect, useState} from "react";
import { FormDashboardLocationItem } from "./FormDashboardLocationItem/FormDashboardLocationItem";
import {FormDashboardDataItem} from "./FormDashboardItem/FormDashboardDataItem";
import {MainButton} from "../Common/Buttons/MainButton/MainButton";
import {Long} from "../Long/Long";
import {parseDate} from "./parseData";
import {DateRangePicker, DayPickerRangeController} from "react-dates/esm";
import styles from "./FormDashboard.module.css";
import "./_datepicker.css";
import "react-dates/initialize";
import {useWindowWidth} from "../../hooks/useWindowWidth";
import {useDispatch, useSelector} from "react-redux";
import {setDataHomeDashboardEndDate, setDataHomeDashboardStartDate} from "../../redux/reducers/homePage";
import {useScrollPosition} from "../../hooks/useScrollPosition";
import moment from "moment";
import { Img } from "react-image";

export const FormDashboard = ({
                                onSubmit,
}) => {

  const dispatch = useDispatch();
  const scrollY = useScrollPosition();

  const {startDate, endDate} = useSelector(state => state.homePage.dataHomeDashboard);

  const [focusedInput, setFocusedInput] = useState(null);

  const handleStartDateButtonClick = () => setFocusedInput("startDate");
  const handleEndDateButtonClick = () => setFocusedInput("endDate");

  const handleDatesChange = ({ startDate, endDate }) => {
    dispatch(setDataHomeDashboardStartDate({startDate: startDate}));
    dispatch(setDataHomeDashboardEndDate({endDate: endDate}));
  };

  useEffect(() => {
    const currentMoment = moment();
    dispatch(setDataHomeDashboardStartDate({startDate: currentMoment}));
  }, [])

  const windowWidth = useWindowWidth();

  return (
    <div className={styles.container}>
      <div className={styles.fon}></div>
      <div className={styles.wrapper}>
        {windowWidth > 767 ?
          <>
            <FormDashboardLocationItem
              title={"Location"}
            />
            <Long vertical={true} onSize={"60%"} />
            <FormDashboardDataItem
              title={'Check-in date & Time'}
              data={startDate ? parseDate(startDate._d) : null}
              callback={handleStartDateButtonClick}
              isBottomLong={focusedInput === 'startDate'}
            />
            <Long
              vertical={true}
              onSize={"60%"}
              Component={
                <DateRangePicker
                  openDirection={scrollY <= 250 ? 'up' : 'down'}
                  startDate={startDate}
                  startDateId="start_date"
                  endDate={endDate}
                  endDateId="end_date"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  isOutsideRange={() => false}
                  customInputIcon={null}
                />
              }
            />
            <FormDashboardDataItem
              title={'Check-out date & Time'}
              data={endDate ? parseDate(endDate._d) : null}
              callback={handleEndDateButtonClick}
              isBottomLong={focusedInput === 'endDate'}
            />
          </>
          :
          <>
            <FormDashboardLocationItem
              title={"Location"}
            />
            <div className={styles['wrapper-mobil-data']}>
              <FormDashboardDataItem
                title={'Check-in date'}
                isIcon={false}
                data={startDate ? parseDate(startDate._d) : null}
                callback={handleStartDateButtonClick}
                isBottomLong={focusedInput === 'startDate'}
              />
              <Long vertical={false} size={1} onSize={'30px'}/>
              <FormDashboardDataItem
                isIcon={false}
                title={'Check-out date'}
                data={endDate ? parseDate(endDate._d) : null}
                callback={handleEndDateButtonClick}
                isBottomLong={focusedInput === 'endDate'}
              />
              <div className={`${styles['wrapper-mobil-data-absolute']} ${focusedInput !== null && styles['wrapper-mobil-data-absolute-show']}`}>
                <DayPickerRangeController
                  startDate={startDate}
                  endDate={endDate}
                  startDateId="start_date_mobil"
                  onDatesChange={handleDatesChange}
                  focusedInput={focusedInput}
                  onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
                  isOutsideRange={() => false}
                  customInputIcon={null}
                />
              </div>
            </div>
          </>
        }
      </div>
      <div className={styles.submitBlock}>
        <MainButton
          Icon={() => (
            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
            <path d="M24.5768 24.3269L19.2252 18.9753M3.88391 12.5534C3.88391 7.62737 7.87724 3.63403 12.8033 3.63403C17.7293 3.63403 21.7226 7.62737 21.7226 12.5534C21.7226 17.4794 17.7293 21.4728 12.8033 21.4728C7.87724 21.4728 3.88391 17.4794 3.88391 12.5534Z" stroke="white" strokeWidth="2.01104" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            // <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            //   <circle cx="6.35116" cy="6.17775" r="4.77822" transform="rotate(7.203 6.35116 6.17775)" fill="transparent" stroke={windowWidth > 767 ? 'white' : '#0095DB'} strokeWidth="2"/>
            //   <path d="M9.43143 10.1491L13.1238 14" stroke={windowWidth > 767 ? 'white' : '#0095DB'} strokeWidth="1.5"/>
            // </svg>
          )}
          callback={onSubmit}
          hoverSize={windowWidth > 767}
          style={{
            // width: '100%',
            // border: windowWidth > 767 ? 'none' : '1px solid #0095DB',
            // padding: windowWidth > 767 ? '7px 35px' : '10px',

            border: 'none',
            padding: '0',
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'scale(1)',
            gridGap: '0',

          }}
          background={windowWidth > 767 ? "#0095DB" : 'white'}
          styleTitle={{
            fontSize: windowWidth > 767 ? '90%' : '100%',
            fontWeight: windowWidth > 767 ? '500' : '500',
            color: windowWidth > 767 ? 'white' : '#0095DB',
          }}
          animated={false}
          title={""}
        />
      </div>
    </div>
  );
};