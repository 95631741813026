import {useEffect, useState} from "react";

export const useSequentialOutput = (index) => {

  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsShow(true), index);
  }, []);

  return isShow;
};