import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { DEFAULT_ACTIONS } from '../redux/actions';

const withAuth = (WrappedComponent) => {

    return (props) => {
        const navigate = useNavigate();
        const dispatch = useDispatch();

        const [verified, setVerified] = useState(false);

        const setLogin = (status) => {
            dispatch({
                type: DEFAULT_ACTIONS.SET_AUTHENTICATED_USER,
                authenticated: status,
            });
        };

        const logOut = () => {
            setLogin(false);
            localStorage.clear();
            navigate('/');
            setVerified(false);
        };

        useEffect(() => {
            const verifyUser = async () => {
                const accessToken = localStorage.getItem('accessToken');

                if (!accessToken) {
                    logOut();
                }
                else {
                    setVerified(true);
                    setLogin(true);
                };
            }

            verifyUser();
        }, []);

        if (verified) {
            return <WrappedComponent {...props} logOut={logOut} />;
        } else {
            return null;
        }
    };
};

export default withAuth;