import React from 'react';
import styles from './FooterBar.module.css';
import { CONSTANTS } from '../../utils';
import cars_icon from './../../assets/icons/Footbar/Cars.png';
import stay_icon from './../../assets/icons/Footbar/Stay.png'
import tours_icon from './../../assets/icons/Footbar/Tours.png';
import account_icon from './../../assets/icons/Footbar/Account.png'
import { FooterBarItem } from './FooterBarItem/FooterBarItem';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveCategoryHomePage } from '../../redux/reducers/homePage';
import { useNavigate } from 'react-router-dom';

const buttons = [
  {
    title: 'Stay',
    icon: stay_icon,
    RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.PROPERTY,
  },
  {
    title: 'Cars',
    icon: cars_icon,
    RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.CAR,
  },
  {
    title: 'Tours',
    icon: tours_icon,
    RENTAL_TYPE: CONSTANTS.RENTAL_TYPE.TOUR,
  },
];

export const FooterBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const activeCategoryHomePage = useSelector(
    (state) => state.homePage.activeCategoryHomePage
  );

  const handleAccountClick = () => navigate('/profile');

  return (
    <div className={styles.container}>
      {buttons.map((item, index) => (
        <FooterBarItem
          key={index}
          callback={() =>
            dispatch(
              setActiveCategoryHomePage({ activeCategoryHomePage: item.RENTAL_TYPE })
            )
          }
          active={activeCategoryHomePage === item.RENTAL_TYPE}
          title={item.title}
          icon={item.icon}
        />
      ))}
      <FooterBarItem
        callback={handleAccountClick}
        active={false}
        title={'Account'}
        icon={account_icon}
      />
    </div>
  );
};
