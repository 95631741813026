import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import Datetime from "react-datetime";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";

// CSS
import "./Pricetable.css";
import "react-datetime/css/react-datetime.css";
import "react-phone-number-input/style.css";

// Components
import { ApiUrl } from "../../ApiUrl";
import { Constants } from "../Common/Constants";
import AlertMessage from "../Common/AlertMessage";
import Switch from "react-switch";
import { CONSTANTS } from "../../utils/constants";
import { DEFAULT_ACTIONS } from "../../redux/actions";

const ProductPrice = (props) => {
  const dispatch = useDispatch()

  const [invalidCheckInDate, setInvalidCheckInDate] = useState(false);
  const [invalidCheckOutDate, setInvalidCheckOutDate] = useState(false);
  const [guestsList, setGuestsList] = useState(Constants.guestOptions);
  const [paymenttype, setPaymenttype] = useState("CARD");
  
  const [validCheckinDates, setValidCheckinDates] = useState([]);
  const [validCheckoutDates, setValidCheckoutDates] = useState([]);
  const [addGuests, setAddGuests] = useState(false);
  const [mySelf, setMySelf] = useState(false);
  const [inDate, setInDate] = useState();
  const [outDate, setOutDate] = useState();
  const [bookingType, setBookingType] = useState("MYSELF");
  const bookingForName = useRef(false);
  const [rating, setRating] = useState(props.state);

  const [serviceFee, setServiceFee] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [avgPrice, setAvgPrice] = useState(0);
  const [propertyAvailableDates, setPropertyAvailableDates] = useState([]);


  const setSomeoneName = (e) => {
    bookingForName.current = e.target.value;
  };

  // setRating(props.state);
  const navigate = useNavigate();

  let images = [];

  useEffect(() => {
    if (props && props.state && props.state.personCapacity) {
      let copiedData = JSON.parse(JSON.stringify(guestsList));
      copiedData[0].max = props.state.personCapacity;
      setGuestsList(copiedData);
    }
  }, [props]);

  const fetchAvailability = async (id) => {
    try {
      const checkAvailiablity = await axios.get(
        `${ApiUrl.BASE_URL}${ApiUrl.CHECK_PROPERTY_AVAILABILITY}?propertyId=${props._id}&currency=${props.currency}`
      );

      setPropertyAvailableDates(checkAvailiablity.data.data);


      if (checkAvailiablity.data.statusCode === 200) {
        let checkInArr = [],
          checkOutArr = [];
        if (checkAvailiablity.data.data.length) {
          checkAvailiablity.data.data.forEach((dates) => {
            if (dates.days.length) {
              dates.days.forEach((date) => {
                if (date.availableForCheckIn) {
                  checkInArr.push(date.date.split("T")[0]);
                  setValidCheckinDates(checkInArr);
                }
                if (date.availableForCheckOut) {
                  checkOutArr.push(date.date.split("T")[0]);
                  setValidCheckoutDates(checkOutArr);
                }
              });
            }
          });
        }
      } else {
        AlertMessage(
          Constants.ERROR_ALERT_VISIBLE,
          checkAvailiablity.data.message
        );
      }
    } catch (error) {
      console.error("API ERROR", error);
    }
  };

  useEffect(() => {
    if (props.data === "property") fetchAvailability(props._id);
  }, []);

  // const carDisablePastDates = (current) => {
  //   const yester = moment().subtract(1, "day");
  //   return current.isAfter(yester);
  // };

  const carDisablePastDates = (current) => {
    const today = Datetime.moment(inDate).add(1, "day").startOf("day"); // Get the current date without the time

    // Disable the dates before today
    return current.isSameOrAfter(today);
  };

  const tourDisableDates = (current) => {
    const yester = moment().subtract(1, "day");
    return (
      moment(current).format("YYYY-MM-DD")
      // current.isAfter(yester) &&
      // !props.disabledDates.includes(moment(current).format("YYYY-MM-DD"))
    );
  };

  const decrement = (index) => {
    let copiedList = JSON.parse(JSON.stringify(guestsList));
    if (copiedList[index].count > copiedList[index].min)
      copiedList[index].count--;
    setGuestsList(copiedList);
  };

  const increment = (index) => {
    let capacity = 12;
    if (props.data === "car") {
      capacity = props.seats;
    } else if (props.data === "property") {
      capacity = props.state.personCapacity;
    }
    let copiedList = JSON.parse(JSON.stringify(guestsList));
    let value = copiedList.reduce((acc, elt) => {
      return +acc + +elt.count;
    }, 0);
    if (copiedList[index].count < copiedList[index].max && value < capacity) {
      copiedList[index].count++;
    }
    setGuestsList(copiedList);
  };

  const AddGuestsDropdown = () => (
    <>
      {guestsList.map((guest, index) => (
        <div key={guest.name} className="mb-2 guest-dropdown">
          <div>
            <div>{guest.name}</div>
            <div>({guest.age})</div>
          </div>
          <div className="outer-plus">
            <button onClick={() => decrement(index)} type="button">
              -
            </button>
            <span>{` ${guest.count} `}</span>
            <button onClick={() => increment(index)} type="button">
              +
            </button>
          </div>
        </div>
      ))}
      <div className="witch-user">
        <span>My Self</span>
        <div className="swich-btn">
          <Switch
            onColor="#e89532"
            onChange={() =>
              setMySelf((prev) => {
                setBookingType(prev ? "MYSELF" : "SOMEONE");
                return !prev;
              })
            }
            checked={mySelf}
          />
        </div>
        <span>Someone</span>
      </div>
      {mySelf && (
        <>
          {/* <Multimedia
            setImagess={setImagess}
            checkFiles={(files) => {
              if (files.length > 0) setInvalidDocument(false);
              else setInvalidDocument(true);
            }}
          />
          {invalidDocument && (
            <div className="error">Please upload document</div>
          )} */}

          <div className="from-group w-100">
            <label>Booking For</label>
            <Field
              name="someone"
              type="text"
              id="field-name"
              onChange={setSomeoneName}
            />
            {/* {errors.someone && touched.someone ? (
                  <div className="error">{errors.someone}</div>
                ) : null} */}
          </div>
        </>
      )}
    </>
  );

  const checkInDates = (current) => {
    return validCheckinDates.includes(current.format("YYYY-MM-DD"));
  };

  // const checkOutDates = (current) => {
  //   return validCheckoutDates.includes(current.format("YYYY-MM-DD"));
  // };

  const checkOutDates = (current) => {
    const today = Datetime.moment(inDate).add(1, "day").startOf("day"); // Get the current date without the time

    // Disable the dates before today
    return current.isSameOrAfter(today);
  };

  const onBookNow = () => {
    let val = "";
    if (props.data !== CONSTANTS.BOOKING_TYPE.TOUR) val = outDate.diff(inDate, "day");

    const dateFormat = {
      checkIn: moment(inDate).format(),
      checkOut: moment(outDate).format(),
    };

    let data = {
      dateTime: dateFormat,
      data: props.state,
      guestData: guestsList,
      days: val,
      price: props.state.price,
      id: props.subCategoryId || "",
      discount: props.state.discount || 0,
      // documents: {
      //   passport: images,
      // },
      bookingFor: bookingType,
      currencySymbol: props.currencySymbol,
      avgPrice,
      totalPrice,
      serviceFee,
    };

    if (bookingForName.current) data.bookingForName = bookingForName.current;
    if(paymenttype) data.modeOfPayment = CONSTANTS.PAYMENT_MODE[paymenttype];

    localStorage.setItem(CONSTANTS.CHECK_IN_DATA, JSON.stringify(data));

    if (localStorage.getItem(CONSTANTS.ACCESS_TOKEN)) {
      navigate("/reviewbooking");
    }
    else dispatch({ type: DEFAULT_ACTIONS.SET_LOGIN_MODAL, status: true })
    // else setFormStep(1);
  };

  const isValidDate = (current) => {
    const today = new Date().setHours(0, 0, 0, 0); // Get the current date without the time

    // Disable the dates before the current date
    return current.isAfter(today);
  };

  const handleAveragePriceChange = (startDate, endDate) => {
    let price = [];

    if(props.state && props.state.category_id && props.state.category_id.type === 'PROPERTY') {
      for(const item of propertyAvailableDates) {
        // if(typeof startDate.month === 'function'){
          if(item.month >= startDate.month() && item.month <= endDate.month() && item.year >= startDate.year() && item.year <= endDate.year()) {
            for(const it of item.days) {
              if(moment(it.date).diff(startDate) >= 0 && moment(it.date).diff(endDate) < 0) {
                price.push(it.price);  
              }
            }
          }
        // }
        
      }
  
      let totalPrice = price.reduce((a, b) => a + b, 0)
      setTotalPrice(totalPrice);
      
      setAvgPrice(totalPrice / price.length);
    }
    else {
      if(startDate){
        const diff = endDate.diff(startDate, 'days');
        setTotalPrice(avgPrice * diff);
      }
      
    }
    
    // setTotalPrice(totalPrice / price.length);
  };

  useEffect(() => {
    if(props.state) {
      if(props.state.category_id.type === 'PROPERTY') {
        const checkInDate = moment(props.state.checkInDate);
        const checkOutDate = moment(props.state.checkOutDate);
  
        setInDate(moment(checkInDate));
        setOutDate(moment(checkOutDate));
        setAvgPrice(props.state.price);
  
        const totalPrice = props.state.price * checkOutDate.diff(checkInDate, 'days');
        setTotalPrice(totalPrice);
      }
      else {
        setAvgPrice(props.state.price);
      }
    }
    
  }, [props.state]);

  useEffect(() => {
    if(props.state && props.state.price) {
      if(props.state.category_id.vat) {
        const fee = (avgPrice * props.state.category_id.vat) / 100;
        setServiceFee(fee);
      }
      else {
        setServiceFee(0);
      };

      if(props.state.category_id.type === 'CAR') {
        const diff = moment(outDate).diff(moment(inDate), 'days');
        setTotalPrice(avgPrice * diff);
      }
    }
  }, [avgPrice]);

  return (
    <div>
      {props.data !== "login" && (
        <div className="price-table">
          <p>{props.state.currencySymbol} {avgPrice.toFixed(2)}</p>
          <div className="price-head">
            <h6>Check Availability:</h6>
            <div className="check-avail">
              {rating.ratingQuantity && rating.ratingQuantity ? (
                <h4>
                  <i className="fas fa-star"></i> {rating.ratingAverage} .{" "}
                  {rating.ratingQuantity} reviews
                </h4>
              ) : (
                <h4>No Review Yet</h4>
              )}
            </div>
          </div>
          <Formik
            initialValues={{
              startdate: "",
              enddate: "",
            }}
            onSubmit={async () => {
              // if (mySelf && images) {
              //   if (images.length > 0) setInvalidDocument(false);
              //   else setInvalidDocument(true);
              // }
              if (props.data !== "tour") {
                if (!outDate) setInvalidCheckOutDate(true);
                if (!inDate) setInvalidCheckInDate(true);
                // if (!outDate || !inDate || (mySelf && !images.length > 0))
                //   return;
              } else {
                if (!inDate || (mySelf && !images.length > 0)) {
                  setInvalidCheckOutDate(true);
                  return;
                }
              }
              if (props.data === "car") {
                try {
                  const checkCarAvailiablity = await axios.get(
                    `${ApiUrl.BASE_URL}${ApiUrl.CHECK_CAR_AVAILABILITY}?carId=${props.state._id
                    }&pickupDate=${moment(inDate)
                      .utcOffset(0, true)
                      .format()}&dropDate=${moment(outDate)
                        .utcOffset(0, true)
                        .format()}`
                  );
                  if (checkCarAvailiablity.data.statusCode === 200) {
                    onBookNow();
                  } else {
                    AlertMessage(
                      Constants.ERROR_ALERT_VISIBLE,
                      checkCarAvailiablity.data.message
                    );
                  }
                } catch (error) {
                  console.error("API ERROR", error);
                }
              } else {
                onBookNow();
              }
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="form-group">
                  <label>
                    Check-in Date <span>& time</span>
                  </label>
                  <Datetime
                    isValidDate={
                      props.data === "property"
                        ? checkInDates
                        : props.data === "car"
                          ? carDisablePastDates
                          : tourDisableDates
                    }
                    value={inDate}
                    inputProps={{ readOnly: true }}
                    timeFormat={props.data === "property" ? false : true}
                    onChange={(e) => {
                      const endDate = moment(e).add(1, "day");
                      setInDate(e);
                      setOutDate(endDate);
                      setInvalidCheckInDate(false);
                      // handle
                      handleAveragePriceChange(e, endDate);
                    }}
                  />
                  {invalidCheckInDate && (
                    <div className="error">
                      Please select Check-in Date & time
                    </div>
                  )}
                </div>
                {props.data !== "tour" && (
                  <div className="form-group">
                    <label>
                      Check-out Date <span>& time</span>
                    </label>
                    <Datetime
                      isValidDate={
                        props.data === "property"
                          ? checkOutDates
                          : props.data === "car"
                            ? carDisablePastDates
                            : () => true
                      }
                      value={outDate}
                      inputProps={{ readOnly: true }}
                      timeFormat={props.data === "property" ? false : true}
                      onChange={(e) => {
                        setOutDate(e);
                        setInvalidCheckOutDate(false);

                        handleAveragePriceChange(inDate, e);
                      }}
                    />
                    {invalidCheckOutDate && (
                      <div className="error">
                        Please select Check-out Date & time
                      </div>
                    )}
                  </div>
                )}
                <div className="form-group">
                {props.state.category_id.type === 'PROPERTY' ?
                  <label
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setAddGuests((prevState) => !prevState);
                    }}
                  >
                    Add/Edit Guests
                  </label>: 
                  <label></label>
                    }
                  {addGuests && <AddGuestsDropdown />}
                  <div className="price-total">
                  {props.state.category_id.type === 'PROPERTY' ? <div className="d-flex justify-content-between">
                    <p>{avgPrice.toFixed(2)} X {moment(outDate).diff(moment(inDate), 'days')} night{moment(outDate).diff(moment(inDate), 'days') > 1 ? 's': ''}</p>
                    <p>{props.state.currencySymbol} {(avgPrice * moment(outDate).diff(moment(inDate), 'days')).toFixed(2)}</p>
                  </div>:
                    <div className="d-flex justify-content-between">
                      <p>{avgPrice} X {moment(outDate).diff(moment(inDate), 'days')}</p>
                      <p>{(avgPrice * moment(outDate).diff(moment(inDate), 'days')).toFixed(2)}</p>
                    </div>}
                  
                  <div className="d-flex justify-content-between">
                    <p>Service Fees</p>
                    <p>{props.state.currencySymbol} {serviceFee.toFixed(2)}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p>Total</p>
                    <p>{props.state.currencySymbol} {(totalPrice + serviceFee).toFixed(2)}</p>
                  </div>
                </div>
                </div>
                <div className="form-group submit">
                  <button type="submit">Book Now</button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default ProductPrice;
