import React from 'react';
import styles from './FooterBarList.module.css';
import {useSelector} from "react-redux";

export const FooterBarList = ({callback}) => {


  const isModalShow = useSelector(state => state.footerBarListData.isModalShow);
  const {link, price, currency} = useSelector(state => state.footerBarListData.data);

  const onBookNow = () => callback();

  return !isModalShow && (
    <div className={styles.container}>
      <div className={styles['right-block']}>
        <p>{`${currency.toUpperCase()} ${price}`}</p>
        <span>/night</span>
      </div>
      <button
        onClick={() => onBookNow()}
      >Book Now ></button>
    </div>
  )
}