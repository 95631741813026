import React from 'react'
import carone from "../assetss/images/carone.png"
import cartwo from "../assetss/images/cartwo.png"
import carthree from "../assetss/images/carthree.png"
import carfour from "../assetss/images/carfour.png"
import carfive from "../assetss/images/carfive.png"
import egone from "../assetss/images/egone.png"
import egtwo from "../assetss/images/egtwo.png"
import egthree from "../assetss/images/egthree.png"

function Cars({setViewPopup}) {
  return (
    <div className="rooms cars">
    <div className='container'>
        <h2 className='wow fadeInUp animate' style={{animationDuration: "1.3s"}}>Luxury Cars</h2>
        <div className='row'>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={carone} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>

            </div>
        </div>
        </div>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={cartwo} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
            </div>
        </div>
        </div>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={carthree} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
            </div>
        </div>
        </div>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={carfour} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
            </div>
        </div>
        </div>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={carfive} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
            </div>
        </div>
        </div>
        <div className='col-md-4'>
        <div className='rom-inner'>
            <img src={carthree} alt="" />
            <div className='ovr-lay-rom'>
                <h5>Maserati</h5>
                <h4>Contrary to popular belief of classical Latin</h4>
                <span></span>
                <div className='new-ic'>
                        <div className='left-ic-icons'>
                            <ul>
                                <li><img src={egone} alt="" />
                                <span>18.97 kmpl</span></li>
                                <li><img src={egtwo} alt="" />
                                <span>1500 cc</span></li>
                                <li><img src={egthree} alt="" />
                                <span>Auto</span></li>
                            </ul>
                            <a href='#'>Details <i className="fas fa-chevron-right"></i></a>
                        </div>
                        </div>
            </div>
        </div>
        </div>
    </div>  
    <button onClick={() => setViewPopup(true)} className="ct-btn">Book Now <i className="fas fa-chevron-right"></i></button>

    </div>
   
</div>
  )
}

export default Cars